// reactstrap components
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Table,
} from "reactstrap";
import AdminHeader from "components/Headers/AdminHeader";
import { useStallsList } from "shared/utility/services/hooks/stalls";
import classNames from "classnames";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import NoData from "shared/common/NoData";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { sortCarets } from "../../../shared/utility/helpers";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm d-inline w-auto mx-1"
            defaultValue={"25"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const Stalls = () => {
  const selectedExhibition = useSelector(selectExhibition);
  const navigate = useNavigate();

  const [query, setQuery] = useState({
    limit: 10,
    skip: 0,
    exhibition_id: selectedExhibition?.id,
    status: ["new"],
    state: "filled",
  });
  const [stallToVerify, setStallToVerify] = useState(null);
  // list stalls
  const {
    data: stallsListData,
    isLoading: stallsListLoading,
    isError: stallsListIsError,
    error: stallsListError,
    mutate: stallsList,
  } = useStallsList();

  useEffect(() => {
    try {
      if (stallsListData && !stallsListLoading) {
        stallsListData.results.map((stall) => {
          stall.viewBtn = (
            <Button
              color="primary"
              onClick={(e) => openDocuments(stall)}
              size="sm"
            >
              Verify Documents
            </Button>
          );
        });
        setStallToVerify(
          stallsListData.results
            .filter((stall) => stall.state !== "on_hold")
            .filter((stall) => stall.state !== "empty"),
        );
      }
      if (stallsListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stallsListData, stallsListLoading, stallsListIsError, stallsListError]);

  useEffect(() => {
    if (!stallsListLoading && selectedExhibition?.id) {
      stallsList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!stallsListLoading) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  const openDocuments = (obj) => {
    navigate(`/back-office/stall/${obj.id}`);

    // documentsList({ stall_id: obj?.id })
    // setSelectedStall(obj)
  };

  const clickPrev = () => {
    if (query.skip !== 0) {
      setQuery((prev) => ({ ...prev, skip: prev.skip - prev.limit }));
    }
  };

  const clickNext = () => {
    if (query.skip + query.limit < stallsListData.total_count) {
      setQuery((prev) => ({ ...prev, skip: prev.skip + prev.limit }));
    }
  };
  const componentRef = useRef(null);
  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        {!selectedExhibition && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <NoData title="For stalls, Please set up an exhibition." />
              </Card>
            </div>
          </Row>
        )}
        {selectedExhibition && (
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Stalls</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {stallToVerify?.length === 0 ? (
                    <NoData title="No stalls found" />
                  ) : (
                    <>
                      <ToolkitProvider
                        data={stallToVerify || []}
                        keyField="id"
                        columns={[
                          {
                            dataField: "name",
                            text: "Name",
                            sort: true,
                            sortCaret: (order, column) =>
                              sortCarets(order, column),
                          },
                          {
                            dataField: "price_per_sq_unit",
                            text: "Price",
                            sort: true,
                            sortCaret: (order, column) =>
                              sortCarets(order, column),
                          },
                          {
                            dataField: "state",
                            text: "State",
                            sort: true,
                            sortCaret: (order, column) =>
                              sortCarets(order, column),
                          },
                          {
                            dataField: "stall_status",
                            text: "Status",
                            sort: true,
                            sortCaret: (order, column) =>
                              sortCarets(order, column),
                          },

                          {
                            dataField: "viewBtn",
                            text: "Verify Documents",
                            sort: false,
                            headerStyle: { textAlign: "center" },
                            style: {
                              textAlign: "center",
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 px-4 table-responsive w-100 ">
                            <Container fluid>
                              <Row>
                                <Col xs={12} sm={6}>
                                  <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb-1 float-left"
                                  >
                                    <label>
                                      <span>Search:</span>
                                      <SearchBar
                                        className="form-control-sm ml-2"
                                        placeholder=""
                                        {...props.searchProps}
                                      />
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                            <BootstrapTable
                              ref={componentRef}
                              {...props.baseProps}
                              bootstrap4={true}
                              classes={"mb-3"}
                              pagination={pagination}
                              bordered={true}
                              hover
                              condensed
                              rowStyle={{ textAlign: "center" }}
                              id="react-bs-table"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Stalls;
