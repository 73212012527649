import { baseURL } from "../../../../../config";

export const exhibitionRoutes = {
  exhibition: {
    post: {
      query: "CREATE_EXHIBITION",
      method: "POST",
      url: `${baseURL}/api/create-exhibition`,
    },
    put: {
      query: "EDIT_EXHIBITION",
      method: "PATCH",
      url: `${baseURL}/api/update-exhibition`,
    },
    get: {
      query: "GET_EXHIBITION",
      method: "GET",
      url: `${baseURL}/api/exhibitions`,
    },
    delete: {
      query: "DELETE_EXHIBITION",
      method: "DELETE",
      url: `${baseURL}/api/exhibitions`,
    },
  },
  services: {
    post: {
      query: "CREATE_SERVICES",
      method: "POST",
      url: `${baseURL}/api/exhibitions/services`,
    },
    get: {
      query: "GET_SERVICES",
      method: "GET",
      url: `${baseURL}/api/exhibitions/services`,
    },
    put: {
      query: "PUT_SERVICES",
      method: "PATCH",
      url: `${baseURL}/api/exhibitions/services`,
    },
  },
  exhib_logo: {
    post: {
      query: "ADD_LOGO",
      method: "POST",
      url: `${baseURL}/api/exhibitions/logo`,
    },
  },
  nofitication: {
    getAll: {
      query: "GET_NOTIFICATIONS",
      method: "GET",
      url: `${baseURL}/api/notifications`,
    },
    getOne: {
      query: "GET_NOTIFICATION",
      method: "GET",
      url: `${baseURL}/api/notification/`,
    },
    read: {
      query: "READ_NOTIFICATION",
      method: "PATCH",
      url: `${baseURL}/api/notification/`,
    },
  },
  exhibition_link: {
    get: {
      query: "GET_LINK",
      method: "GET",
      url: `${baseURL}/api/exhibition/visitorlink`,
    },
  },
  visitor: {
    get: {
      query: "GET_VISITOR",
      method: "GET",
      url: `${baseURL}/api/visit`,
    },
  },
};
