import React, { useState } from 'react';
import { Row, Col, Table, Alert, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const formatIndianCurrency = (amount) => {
    const crore = 10000000;
    const lakh = 100000;

    if (amount >= crore) {
        return `₹${(amount / crore).toFixed(2)} Cr`;
    } else if (amount >= lakh) {
        return `₹${(amount / lakh).toFixed(2)} L`;
    } else {
        return `₹${amount.toFixed(2)}`;
    }
};

const ElegantSalesmanPerformance = ({ salesmanTableData }) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    // Calculate summary data
    const totalBookedStalls = salesmanTableData.reduce((sum, salesman) => sum + salesman.bookedStallsQty, 0);
    const totalHoldStalls = salesmanTableData.reduce((sum, salesman) => sum + salesman.holdStallsQty, 0);
    const totalBookedAmount = salesmanTableData.reduce((sum, salesman) => sum + salesman.bookedAmount, 0);
    const totalBookedArea = salesmanTableData.reduce((sum, salesman) => sum + salesman.bookedArea, 0);

    // Sort salesmen by booked amount (descending)
    const topSalesmen = [...salesmanTableData].sort((a, b) => b.bookedAmount - a.bookedAmount).slice(0, 5);

    return (
        <div className="p-3">
            <h6 className="text-uppercase text-muted mb-3">Salesman</h6>
            <Row className="mb-4 text-center">
                <Col xs="3">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{totalBookedStalls}</span>
                    </div>
                    <span className="text-muted text-sm">Total Booked</span>
                </Col>
                <Col xs="3">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{totalHoldStalls}</span>
                    </div>
                    <span className="text-muted text-sm">On Hold</span>
                </Col>
                <Col xs="3">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{formatIndianCurrency(totalBookedAmount)}</span>
                    </div>
                    <span className="text-muted text-sm">Total Booked</span>
                </Col>
                <Col xs="3">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{totalBookedArea.toFixed(0)}</span>
                    </div>
                    <span className="text-muted text-sm">Total Area</span>
                </Col>
            </Row>

            {salesmanTableData.length === 0 ? (
                <Alert color="info" className="text-center mb-0">
                    <i className="fas fa-info-circle mr-2"></i>
                    No salesman data available at the moment. Check back later for performance metrics.
                </Alert>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h6 className="text-uppercase text-muted mb-0">Top {Math.min(5, salesmanTableData.length)} Performing Salesmen</h6>
                        <Button color="primary" size="sm" onClick={toggle}>
                            View All
                        </Button>
                    </div>

                    <Table responsive borderless size="sm" className="align-items-center mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Salesman</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Booked Stalls</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Amount</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Performance</th>
                        </tr>
                        </thead>
                        <tbody>
                        {topSalesmen.map((salesman, index) => (
                            <tr key={salesman.id}>
                                <td>
                                    <span className="text-sm font-weight-bold">{salesman.name}</span>
                                </td>
                                <td className="text-sm">{salesman.bookedStallsQty}</td>
                                <td className="text-sm">{formatIndianCurrency(salesman.bookedAmount)}</td>
                                <td>
                                        <span className={`text-xs font-weight-bold ${index < 3 ? "text-success" : "text-info"}`}>
                                            {index < 3 ? "Top Performer" : "Good"}
                                        </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    <Modal isOpen={modal} toggle={toggle} size="lg">
                        <ModalHeader toggle={toggle}>
                            All Salesmen Performance
                        </ModalHeader>
                        <ModalBody>
                            <Table responsive className="align-items-center">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Salesman</th>
                                    <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Booked Stalls</th>
                                    <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Hold Stalls</th>
                                    <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Amount</th>
                                    <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Area</th>
                                </tr>
                                </thead>
                                <tbody>
                                {salesmanTableData.map((salesman) => (
                                    <tr key={salesman.id}>
                                        <td>
                                            <span className="text-sm font-weight-bold">{salesman.name}</span>
                                        </td>
                                        <td className="text-sm">{salesman.bookedStallsQty}</td>
                                        <td className="text-sm">{salesman.holdStallsQty}</td>
                                        <td className="text-sm">{formatIndianCurrency(salesman.bookedAmount)}</td>
                                        <td className="text-sm">{salesman.bookedArea.toFixed(2)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </ModalBody>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default ElegantSalesmanPerformance;