// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useCreateEXhibition,
  useExhibitionList,
  useEditVisitors,
  useAddImageVisitors,
  useExhibitionVisitorLink,
} from "shared/utility/services/hooks/exhibition";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Table,
  ButtonGroup,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  recallExhibition,
  selectedLogo,
  selectExhibition,
  selectUser,
} from "redux/reducers/authSlice";
import { useLocation } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useLogin } from "../../shared/utility/services/hooks/signin";

import {
  create_exhibition_popovers,
  sortCarets,
} from "../../shared/utility/helpers";
import {
  useCreateTicketTemplate,
  useDeleteTicketTemplate,
  useListTicketTemplate,
} from "../../shared/utility/services/hooks/visit";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Select from "react-select";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm d-inline w-auto mx-1"
            defaultValue={"25"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Tickets = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [openCreateVisitorsModal, setOpenCreateVisitorsModal] = useState(false);
  const [deleteTicketModal, setDeleteTicketModal] = useState(false);
  const [deleteTicketId, setDeleteTicketId] = useState(null);
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const { state } = useLocation();

  const selectedExhibition = useSelector(selectExhibition);
  const {
    data: ticketListData,
    isLoading: ticketListLoading,
    isError: ticketListIsError,
    error: ticketListError,
    mutate: ticketList,
  } = useListTicketTemplate();
  const {
    data: deleteTicketData,
    isLoading: deleteTicketLoading,
    isError: deleteTicketIsError,
    error: deleteTicketError,
    mutate: deleteTicket,
  } = useDeleteTicketTemplate();

  useEffect(() => {
    if (deleteTicketData) {
      toast.success("Ticket Deleted Successfully");
      setDeleteTicketModal(false);
      setDeleteTicketId(null);
      ticketList({ id: selectedExhibition?.id });
    }
    if (deleteTicketIsError) {
      toast.error("Failed to delete Ticket " + deleteTicketError);
    }
  }, [
    deleteTicketData,
    deleteTicketLoading,
    deleteTicketIsError,
    deleteTicketError,
  ]);

  useEffect(() => {
    ticketList({ id: selectedExhibition?.id });
  }, [selectedExhibition]);
  const {
    data: createTicketData,
    isLoading: createTicketLoading,
    isError: createTicketIsError,
    error: createTicketError,
    mutate: createTicket,
  } = useCreateTicketTemplate();
  useEffect(() => {
    if (createTicketData) {
      toast.success("Ticket Created Successfully");
      setOpenCreateVisitorsModal(false);
      formik.handleReset();
      ticketList({ id: selectedExhibition?.id });
    }
  }, [
    createTicketData,
    createTicketLoading,
    createTicketIsError,
    createTicketError,
  ]);
  const exhibLogo = useSelector(selectedLogo);
  const initialValues = {
    exhibition_id: selectedExhibition?.id,
    ticket_name: "",
    price: 0,
    times: null,
    hall_or_dome: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      ticket_name: Yup.string().required("Required"),
      // exhibition_id: Yup.number().required("Required"),
      price: Yup.number()
        .min(0, "The number must be zero or positive")
        .required("Required"),
      times: Yup.number()
        .min(0, "The number must be zero or positive")
        .required("Required"),
      hall_or_dome: Yup.array().required("Required"),
      // .test("is-greater-1", function (value) {
      //   const allowed_hall_or_dome = this.resolve(Yup.ref("hall_or_dome"));
      //   if (allowed_hall_or_dome.length < 1) {
      //     return this.createError({
      //       message: "At least 1 Location required",
      //       path: this.path,
      //     });
      //   } else {
      //     return true;
      //   }
      // }),
    }),
    onSubmit: (values) => {
      console.log(values, "submittedValues for ticket");
      let constraints = {
        times: values.times,
        location: values.hall_or_dome,
      };
      createTicket({ ...values, constraints: constraints });
    },
  });
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Text copied to clipboard successfully!");
        toast.success("Link Copied to Clipboard");
      },
      (err) => {
        console.error("Failed to copy text to clipboard: ", err);
        toast.error("Failed to copy text to clipboard");
      },
    );
  };

  return (
    <>
      <AdminHeader />
      <Container className="mt--7 w-100" fluid>
        <Row className={"w-100"}>
          <Card className="shadows w-100">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="3">
                  <h3 className="mb-0">Tickets</h3>
                </Col>
                <Col
                  className="text-right d-flex justify-content-end align-items-center"
                  xs="9"
                >
                  {selectedExhibition?.visitor_link && (
                    <>
                      <h4 className={"mr-2"}>
                        Visitor Link:{" "}
                        <a
                          href={`https://hypx.in/signup/?exhib_id=${selectedExhibition?.visitor_link}`}
                          target="_blank"
                        >
                          {`https://hypx.in/signup/?exhib_id=${selectedExhibition?.visitor_link}`}
                        </a>
                      </h4>
                      <ButtonGroup className={"mr-3"}>
                        <Button
                          color="primary"
                          onClick={() => {
                            copyToClipboard(
                              `https://hypx.in/signup/?exhib_id=${selectedExhibition?.visitor_link}`,
                            );
                          }}
                          size="lg"
                        >
                          Copy Link
                        </Button>
                        <a
                          className={"btn btn-success"}
                          color="success"
                          target={"_blank"}
                          href={`https://api.whatsapp.com/send?text=https://hypx.in/signup/?exhib_id=${selectedExhibition?.visitor_link}`}
                          rel="noreferrer"
                        >
                          Whatsapp Link
                        </a>
                      </ButtonGroup>
                    </>
                  )}
                  {ticketListData?.length !== 0 && (
                    <Button
                      color="primary"
                      onClick={() => {
                        setOpenCreateVisitorsModal(true);
                      }}
                      size="lg"
                    >
                      Create Ticket
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>

            {ticketListData?.length !== 0 ? (
              <>
                <Row
                  className={
                    "d-flex p-2 align-items-center justify-content-center my-5"
                  }
                >
                  {" "}
                  {ticketListData &&
                    ticketListData.map((item, index) => (
                      <Col xs={12} md={6} key={index}>
                        <Card>
                          <CardHeader>
                            <h5>{item.ticket_name}</h5>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col xs={4} className={"text-left"}>
                                <img
                                  alt={"exhibition logo"}
                                  src={exhibLogo}
                                  style={{ maxHeight: "100px" }}
                                />
                              </Col>
                              <Col
                                xs={8}
                                className={
                                  "d-flex flex-column justify-content-center text-right "
                                }
                              >
                                <h3>{selectedExhibition?.name}</h3>
                                <h4>
                                  {selectedExhibition?.start_date} -{" "}
                                  {selectedExhibition?.end_date}
                                </h4>
                                <h5>
                                  {selectedExhibition?.start_time} -{" "}
                                  {selectedExhibition?.end_time}
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <h5>
                                  Entry Limit:{" "}
                                  <h4> {item.constraints.times}</h4>
                                </h5>
                              </Col>
                              <Col xs={8} className={"text-right"}>
                                <h5>
                                  Locations:{" "}
                                  <h4>
                                    {item.constraints.location.map((item) => {
                                      return item + " , ";
                                    })}
                                  </h4>
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <h5>Price:</h5>
                                <h4>Rs. {item.price} /-</h4>
                              </Col>
                              <Col xs={8} className={"text-right"}>
                                <h5>
                                  {selectedExhibition?.venue}
                                  <br />
                                  {selectedExhibition?.address}
                                </h5>
                              </Col>
                            </Row>
                          </CardBody>
                          {/*<CardFooter className={""}>
                            <FormGroup className={"w-100 d-flex"}>
                              <Button
                                color={"danger"}
                                type={"button"}
                                className={"mx-2"}
                              >
                                -
                              </Button>
                              <Input placeholder={"qty"} type={"number"} />
                              <Button
                                color={"success"}
                                type={"button"}
                                className={"mx-2"}
                              >
                                +
                              </Button>
                            </FormGroup>
                            <FormGroup className={"w-100"}>
                              <Button color={"primary"} className={"w-100"}>
                                Buy
                              </Button>
                            </FormGroup>
                          </CardFooter>*/}
                          <CardFooter>
                            <ButtonGroup className={"w-100"}>
                              <Button color="primary" disabled={true}>
                                Edit
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => {
                                  setDeleteTicketId(item.id);
                                  setDeleteTicketModal(true);
                                }}
                              >
                                Delete
                              </Button>
                            </ButtonGroup>
                          </CardFooter>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </>
            ) : (
              <div
                className={
                  "container-fluid d-flex flex-column justify-content-center align-items-center p-8  "
                }
              >
                <span className={"p-3 text-dark font-weight-bold"}>
                  You Have Not Created Any Tickets Yet
                </span>
                <Button
                  color="primary"
                  onClick={() => {
                    setOpenCreateVisitorsModal(true);
                  }}
                  size="lg"
                >
                  Create Ticket
                </Button>
              </div>
            )}
          </Card>
        </Row>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={deleteTicketModal}
          onExit={() => {
            // modalToogle();
          }}
        >
          <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Card className="bg-secondary shadow border-0">
                <div className="modal-header">
                  <h3 className="modal-title" id="modal-title-default">
                    Delete Ticket
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      setDeleteTicketModal(false);
                    }}
                  >
                    <span aria-hidden={true} className="close-button-icon">
                      ×
                    </span>
                  </button>
                </div>
                <CardBody className="px-lg-5 py-lg-5 text-center">
                  <Row>
                    <Col xs={12} lg={12}>
                      <h3 className={"bold mb-5"}>
                        Are you sure you want to delete this ticket?
                      </h3>
                    </Col>
                    <Col lg={6}>
                      <Button
                        color="danger"
                        type="submit"
                        size="lg"
                        onClick={() => {
                          deleteTicket({ id: deleteTicketId });
                          setDeleteTicketModal(false);
                        }}
                      >
                        Delete
                      </Button>
                    </Col>

                    <Col lg={6}>
                      <Button
                        color="primary"
                        outline={true}
                        type="submit"
                        size="lg"
                        onClick={() => {
                          setDeleteTicketModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </LocalizationProvider>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={openCreateVisitorsModal}
          onExit={() => {
            // modalToogle();
          }}
        >
          <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Card className="bg-secondary shadow border-0">
                <div className="modal-header">
                  <h3 className="modal-title" id="modal-title-default">
                    Create Ticket
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      setOpenCreateVisitorsModal(false);
                    }}
                  >
                    <span aria-hidden={true} className="close-button-icon">
                      ×
                    </span>
                  </button>
                </div>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={12} lg={8}>
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-ticket-name"
                          >
                            Ticket Name
                            <i
                              className={"fa-solid fa-info-circle ml-3"}
                              data-placement="top"
                              id="create-ticket-name"
                            ></i>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="create-ticket-name"
                            >
                              Enter the name of the ticket
                            </UncontrolledTooltip>
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Ticket Name *"
                              type="text"
                              name="ticket_name"
                              value={formik.values.ticket_name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                formik.touched.ticket_name &&
                                formik.errors.ticket_name
                              }
                            />
                            <FormFeedback>
                              {formik.errors.ticket_name}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs={12} lg={4}>
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-ticket-price"
                          >
                            Ticket Price
                            <i
                              className={"fa-solid fa-info-circle ml-3"}
                              data-placement="top"
                              id="create-ticket-price"
                            ></i>
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="create-ticket-price"
                            >
                              Enter the price of the ticket
                            </UncontrolledTooltip>
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Ticket Price *"
                              type="number"
                              name="price"
                              value={formik.values.price}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                formik.touched.price && formik.errors.price
                              }
                            />
                            <FormFeedback>{formik.errors.price}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg={12} className={""}>
                        <h4 className={"bold"}>
                          Constraints
                          <i
                            className={"fa-solid fa-info-circle ml-3"}
                            data-placement="top"
                            id="create-ticket-constraints"
                          ></i>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="create-ticket-constraints"
                          >
                            Enter the constraints of the ticket
                          </UncontrolledTooltip>
                        </h4>

                        <div className={"row"}>
                          <Col xs={12} lg={6}>
                            <FormGroup className="mb-3">
                              <label
                                className="form-control-label"
                                htmlFor="input-ticket-price"
                              >
                                Entry Time Limit
                                <i
                                  className={"fa-solid fa-info-circle ml-3"}
                                  data-placement="top"
                                  id="create-ticket-price"
                                ></i>
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="top"
                                  target="create-ticket-price"
                                >
                                  No of times a person can enter
                                </UncontrolledTooltip>
                              </label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="Ticket Time *"
                                  type="number"
                                  name="times"
                                  value={formik.values.times}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    formik.touched.times && formik.errors.times
                                  }
                                />
                                <FormFeedback>
                                  {formik.errors.times}
                                </FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormGroup className="mb-3">
                              <label
                                className="form-control-label"
                                htmlFor="input-tags"
                              >
                                Select Hall/Dome
                              </label>
                              <Select
                                isMulti
                                options={
                                  selectedExhibition?.allowed_hall_or_dome.map(
                                    (item) => ({
                                      value: item,
                                      label: item,
                                    }),
                                  ) ?? []
                                }
                                name={"hall_or_dome"}
                                id={"hall_or_dome"}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      formik.touched.hall_or_dome &&
                                      Boolean(formik.errors.hall_or_dome)
                                        ? "red"
                                        : "transparent",
                                    height: "100%",
                                    minHeight: "40px",
                                  }),
                                }}
                                className={"shadow "}
                                onChange={(value) => {
                                  console.log(value);
                                  const arrValue = value.map(
                                    (item) => item.value,
                                  );
                                  formik.setFieldValue(
                                    "hall_or_dome",
                                    arrValue,
                                    true,
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                aria-errormessage={
                                  formik.touched.hall_or_dome &&
                                  formik.errors.hall_or_dome
                                }
                                value={formik.values.hall_or_dome.map(
                                  (item) => ({
                                    value: item,
                                    label: item,
                                  }),
                                )}
                                error={
                                  formik.touched.hall_or_dome &&
                                  Boolean(formik.errors.hall_or_dome)
                                }
                                helperText={
                                  formik.touched.hall_or_dome &&
                                  formik.errors.hall_or_dome
                                }
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <Button color="success" type="submit" size="lg">
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </LocalizationProvider>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Tickets;
