// reactstrap components
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import { useLocation } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import {
  useUsersList,
  useCreateUsers,
  useEditUsers,
} from "shared/utility/services/hooks/users";
import classNames from "classnames";
import { useBusinessUsersList } from "shared/utility/services/hooks/users";
import { useAssignUsers } from "shared/utility/services/hooks/users";
import { useSelector } from "react-redux";
import { selectExhibition } from "redux/reducers/authSlice";
import { selectUser } from "redux/reducers/authSlice";
import { useRevokeUsers } from "shared/utility/services/hooks/users";
import NoData from "shared/common/NoData";

const Users = () => {
  const selectedExhibition = useSelector(selectExhibition);
  const loggedInUser = useSelector(selectUser);
  const { state } = useLocation();

  const isSalesloggedInUser = loggedInUser.role === "sales";
  const isAdminloggedInUser = loggedInUser.role === "admin";
  const [modal, setModal] = useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState("add");
  const [query, setQuery] = useState({
    limit: 10,
    skip: 0,
    search: "",
    roles: isSalesloggedInUser
      ? ["exhibitor"]
      : [
          "admin",
          "sales",
          "marketing",
          "production",
          "vendor",
          "backoffice",
          "bouncer",
        ],
    current_exhibition: selectedExhibition?.id,
  });
  const [businessUserQuery, setBusiessUsersQuery] = useState({
    limit: 10,
    skip: 0,
    search: "",
    exhibition_id: selectedExhibition?.id,
    roles: isSalesloggedInUser
      ? ["exhibitor"]
      : [
          "admin",
          "sales",
          "marketing",
          "production",
          "vendor",
          "backoffice",
          "bouncer",
        ],
  });
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    if (state?.add) {
      addUsersModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.add]);

  const modalclose = () => {
    setModal(false);
    formik.setValues(initialValues);
  };

  const addUsersModalOpen = () => {
    formik.resetForm();
    setActiveModalStatus("add");
    setModal(true);
  };

  const assignUserModalOpen = () => {
    setActiveModalStatus("assignUser");
    setModal(true);
    businessUsersList(businessUserQuery);
  };

  const editUsersModalOpen = (obj) => {
    setActiveModalStatus("add");
    setModal(true);
    formik.setValues(obj);
  };

  const revokeUserModalOpen = (obj) => {
    setModal(true);
    revokeUsers({ exhibition_id: selectedExhibition?.id, userIds: [obj?.id] });
  };

  const handleChange = (obj) => {
    if (selectedUser.some((obj1) => obj1?.id === obj?.id)) {
      let su = selectedUser.filter((obj1) => obj1?.id !== obj?.id);
      setSelectedUser(su);
    } else {
      setSelectedUser([...selectedUser, obj]);
    }
  };

  const clickPrev = () => {
    if (query.skip !== 0) {
      setQuery((prev) => ({ ...prev, skip: prev.skip - prev.limit }));
    }
  };

  const clickNext = () => {
    if (query.skip + query.limit < usersListData.total_count) {
      setQuery((prev) => ({ ...prev, skip: prev.skip + prev.limit }));
    }
  };

  const clickBusinessPrev = () => {
    if (businessUserQuery.skip !== 0) {
      setBusiessUsersQuery((prev) => ({
        ...prev,
        skip: prev.skip - prev.limit,
      }));
    }
  };

  const clickBusinessNext = () => {
    if (
      businessUserQuery.skip + businessUserQuery.limit <
      businessUsersList.total_count
    ) {
      setBusiessUsersQuery((prev) => ({
        ...prev,
        skip: prev.skip + prev.limit,
      }));
    }
  };

  const assignUserSubmit = () => {
    if (selectedUser.length > 0) {
      assignUsers({
        exhibition_id: selectedExhibition?.id,
        userIds: selectedUser.map((obj) => obj?.id),
      });
    }
  };

  const debouncedFetchUsers = useDebouncedCallback(
    (value) => setQuery({ ...query, search: value }),
    500,
  );

  const debouncedFetchBusinessUsers = useDebouncedCallback(
    (value) => setBusiessUsersQuery({ ...businessUserQuery, search: value }),
    500,
  );

  // list users
  const {
    data: usersListData,
    isLoading: usersListLoading,
    isError: usersListIsError,
    error: usersListError,
    mutate: usersList,
  } = useUsersList();

  useEffect(() => {
    try {
      if (usersListData && !usersListLoading) {
      }
      if (usersListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersListData, usersListLoading, usersListIsError, usersListError]);

  useEffect(() => {
    if (!usersListLoading && selectedExhibition) {
      usersList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!businessUsersListLoading) {
      businessUsersList(businessUserQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUserQuery]);

  useEffect(() => {
    if (!usersListLoading) {
      setQuery({ ...query, current_exhibition: selectedExhibition?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  const {
    data: businessUsersListData,
    isLoading: businessUsersListLoading,
    isError: businessUsersListIsError,
    error: businessUsersListError,
    mutate: businessUsersList,
  } = useBusinessUsersList();

  useEffect(() => {
    try {
      if (businessUsersListData && !businessUsersListLoading) {
      }
      if (businessUsersListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessUsersListData,
    businessUsersListLoading,
    businessUsersListIsError,
    businessUsersListError,
  ]);

  // assign users
  const {
    data: assignUsersData,
    isLoading: assignUsersLoading,
    isError: assignUsersIsError,
    error: assignUsersError,
    mutate: assignUsers,
  } = useAssignUsers();

  useEffect(() => {
    try {
      if (assignUsersData && !assignUsersLoading) {
        toast.success("User assigned successfully");
        setModal(false);
        usersList(query);
        setSelectedUser([]);
      }
      if (assignUsersIsError) {
        toast.error(assignUsersError?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignUsersData, assignUsersLoading, assignUsersIsError]);

  // create users
  const {
    data: createUsersData,
    isLoading: createUsersLoading,
    isError: createUsersIsError,
    error: createUsersError,
    mutate: createUsers,
  } = useCreateUsers();

  useEffect(() => {
    try {
      if (createUsersData && !createUsersLoading) {
        toast.success(
          "User created successfully and will receive an email soon.",
        );
        setModal(false);
        usersList(query);

        /*assignUsers({
          exhibition_id: selectedExhibition?.id,
          userIds: [createUsersData?.[0].id],
        });*/
      }
      if (createUsersIsError) {
        toast.error(createUsersError?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUsersData, createUsersLoading, createUsersIsError]);

  // edit users
  const {
    data: editUsersData,
    isLoading: editUsersLoading,
    isError: editUsersIsError,
    error: editUsersError,
    mutate: editUsers,
  } = useEditUsers();

  useEffect(() => {
    try {
      if (editUsersData && !editUsersLoading) {
        toast.success("Users updated successfully");
        usersList(query);
        setModal(false);
        formik.resetForm();
      }
      if (editUsersIsError) {
        toast.error(editUsersError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUsersData, editUsersLoading, editUsersIsError]);

  const {
    data: revokeUsersData,
    isLoading: revokeUsersLoading,
    isError: revokeUsersIsError,
    error: revokeUsersError,
    mutate: revokeUsers,
  } = useRevokeUsers();

  useEffect(() => {
    try {
      if (revokeUsersData && !revokeUsersLoading) {
        toast.success("Users revoked successfully");
        setModal(false);
        usersList(query);
      }
      if (revokeUsersIsError) {
        toast.error(revokeUsersError?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeUsersData, revokeUsersLoading, revokeUsersIsError]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    role: isSalesloggedInUser ? "exhibitor" : "",
    password: "",
    business_address: "",
    business_name: "",
    business_phone: "",
    brand_name: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Email invalid").required("Required"),
      phone: Yup.string().required("Required"),
      role: Yup.string().required("Required"),
      business_address: Yup.string(),
      business_name: Yup.string(),
      business_phone: Yup.string(),
      brand_name: Yup.string(),
    }),
    onSubmit: (values) => {
      const newValues = {
        ...values,
        phone: `${values?.phone}`,
        business_phone: `${values?.business_phone}`,
      };

      if (!formik.values.id) {
        createUsers({
          data: [newValues],
          exhibition_id: selectedExhibition?.id,
        });
      } else {
        editUsers(newValues);
      }
    },
  });

  let modalStatus = {
    add: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              {formik.values.id ? "Edit Users" : "Add Users"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      User name
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="User name *"
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.name && formik.errors.name}
                      />
                      <FormFeedback>{formik.errors.name}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-email">
                      User email
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="User email *"
                        type="text"
                        name="email"
                        disabled={formik.values.id ? true : false}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.email && formik.errors.email}
                      />
                      <FormFeedback>{formik.errors.email}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-phone">
                      User phone
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="User phone *"
                        type="number"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.phone && formik.errors.phone}
                      />
                      <FormFeedback>{formik.errors.phone}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-role">
                      User role
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="User Role *"
                        type="select"
                        name="role"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.role && formik.errors.role}
                      >
                        {isSalesloggedInUser && (
                          <option value="exhibitor">Exhibitor</option>
                        )}
                        {isAdminloggedInUser && (
                          <>
                            <option value="select">Select</option>
                            <option value="sales">Sales</option>
                            <option value="marketing">Marketing</option>
                            <option value="production">Production</option>
                            <option value="vendor">Vendor</option>
                            <option value="backoffice">Back Office</option>
                            <option value="exhibitor">Exhibitor</option>
                            <option value="bouncer">Bouncer</option>
                          </>
                        )}
                      </Input>
                      <FormFeedback>{formik.errors.role}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {(isSalesloggedInUser || formik.values.role === "exhibitor") && (
                <Row>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-password"
                      >
                        Brand Name
                      </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Brand Name"
                          type="text"
                          name="brand_name"
                          required={formik.values.role === "exhibitor"}
                          value={formik.values.brand_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.brand_name &&
                            formik.errors.brand_name
                          }
                        />
                        <FormFeedback>{formik.errors.brand_name}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-password"
                      >
                        Business Name
                      </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Business Name"
                          type="text"
                          name="business_name"
                          value={formik.values.business_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required={formik.values.role === "exhibitor"}
                          invalid={
                            formik.touched.business_name &&
                            formik.errors.business_name
                          }
                        />
                        <FormFeedback>
                          {formik.errors.business_name}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-password"
                      >
                        Business Phone
                      </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Business Phone"
                          type="number"
                          name="business_phone"
                          value={formik.values.business_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required={formik.values.role === "exhibitor"}
                          invalid={
                            formik.touched.business_phone &&
                            formik.errors.business_phone
                          }
                        />
                        <FormFeedback>
                          {formik.errors.business_phone}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-password"
                      >
                        Business Address
                      </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Business Address"
                          type="text"
                          name="business_address"
                          value={formik.values.business_address}
                          required={formik.values.role === "exhibitor"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.business_address &&
                            formik.errors.business_address
                          }
                        />
                        <FormFeedback>
                          {formik.errors.business_address}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <div className="text-center">
                <Button
                  className="my-4"
                  color={"primary"}
                  disabled={createUsersLoading}
                  type="submit"
                >
                  {!createUsersLoading ? "Submit" : "Adding User"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
    assignUser: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0 model-card-header">
            <Row className="align-items-center">
              <Col lg="6">
                <h3 className="modal-title" id="modal-title-default">
                  Assign Users
                </h3>
              </Col>
              <Col lg="6" className="text-right d-flex justify-content-end">
                <InputGroup className="input-group-alternative mr-3 w-25">
                  <Input
                    placeholder="search..."
                    type="text"
                    onChange={(e) => {
                      debouncedFetchBusinessUsers(e.target.value);
                    }}
                  />
                </InputGroup>
                <Button color="primary" onClick={addUsersModalOpen} size="sm">
                  Create new user
                </Button>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={modalclose}
                >
                  <span aria-hidden={true} className="close-button-icon">
                    ×
                  </span>
                </button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr className="model-tr">
                  <th scope="col">Select</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                </tr>
              </thead>
              <tbody>
                {businessUsersListData?.results?.map?.((obj, index) => (
                  <tr key={index}>
                    <td>
                      <Button
                        size={"sm"}
                        color={"primary"}
                        onClick={() => {
                          handleChange(obj);
                          assignUserSubmit();
                        }}
                      >
                        Assign
                      </Button>
                    </td>
                    <td>{obj?.name}</td>
                    <td>{obj?.phone}</td>
                    <td>{obj?.email}</td>
                    <td>{obj?.role}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter className="py-4 model-card-footer">
            <div className="d-flex justify-content-between">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem
                    className={classNames({
                      disabled: query.skip === 0,
                    })}
                  >
                    <PaginationLink onClick={clickBusinessPrev} tabIndex="-1">
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className="active">
                    <PaginationLink>
                      {query.skip / query.limit + 1}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={classNames({
                      disabled:
                        query.skip + query.limit >=
                        businessUsersListData?.total_count,
                    })}
                  >
                    <PaginationLink onClick={clickBusinessNext}>
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </div>
          </CardFooter>
        </Card>
      </div>
    ),
  };

  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col lg="6">
                    <h3 className="modal-title" id="modal-title-default">
                      Users
                    </h3>
                  </Col>
                  <Col lg="6" className="text-right d-flex justify-content-end">
                    <InputGroup className="input-group-alternative mr-3 w-25">
                      <Input
                        placeholder="search..."
                        type="text"
                        onChange={(e) => {
                          debouncedFetchUsers(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <Button
                      color="primary"
                      onClick={addUsersModalOpen}
                      size="sm"
                    >
                      Create new user
                    </Button>
                    <Button
                      color="primary"
                      onClick={assignUserModalOpen}
                      size="sm"
                    >
                      Assign user to exhibition
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {!selectedExhibition && (
                <Row>
                  <div className="col">
                    <Card className="shadow">
                      <NoData title="For users, Please set up an exhibition." />
                    </Card>
                  </div>
                </Row>
              )}
              {usersListData?.results?.length === 0 ? (
                <NoData title="No data found" />
              ) : (
                <div>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {usersListData?.results?.map?.((obj, index) => (
                        <tr key={index}>
                          <td className="mb-0 text-sm text-capitalize">
                            {obj?.name}
                          </td>
                          <td>{obj.phone}</td>
                          <td>{obj.email}</td>
                          <td>{obj.role}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => editUsersModalOpen(obj)}
                                >
                                  View / Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => revokeUserModalOpen(obj)}
                                >
                                  Revoke user
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem
                          className={classNames({
                            disabled: query.skip === 0,
                          })}
                        >
                          <PaginationLink onClick={clickPrev} tabIndex="-1">
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                          <PaginationLink>
                            {query.skip / query.limit + 1}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem
                          className={classNames({
                            disabled:
                              query.skip + query.limit >=
                              usersListData?.total_count,
                          })}
                        >
                          <PaginationLink onClick={clickNext}>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </div>
              )}
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={modal}
          onExit={() => {
            setActiveModalStatus(false);
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default Users;
