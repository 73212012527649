import { useMutation } from "react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuthToken, setUser } from "redux/reducers/authSlice";

import useAxios from "../useAxios";
import { loginRoutes } from "./route";

const { signin, register, user, reset } = loginRoutes;

// signin api call
export const useLogin = () => {
  const dispatch = useDispatch();
  const { url } = signin.post;
  return useMutation((data) =>
    axios.post(url, data).then((response) => {
      dispatch(setAuthToken(response.data));
      return response.data;
    }),
  );
};
export const useResetPassword = () => {
  const callApi = useAxios();
  const dispatch = useDispatch();
  const { url, method } = reset.post;
  return useMutation((data) => {
    return callApi({
      method: method,
      url: url,
      data: data,
    }).then((data) => {
      dispatch(setUser(data));
      return data;
    });
  });
};

// get all user
export const useGetUser = () => {
  const callApi = useAxios();
  const dispatch = useDispatch();
  const { url, method } = user.get;

  return useMutation(() => {
    return callApi({
      method: method,
      url: url,
    }).then((data) => {
      dispatch(setUser(data));
      return data;
    });
  });
};

export const useRegisterVisitor = () => {
  const callApi = useAxios();
  const { url, method } = register.post;

  return useMutation((data) => {
    const exhib_id = data.exhibition_uuid;
    const newUrl = `${url}?exhibition_uuid=${exhib_id}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data,
    }).then((data) => {
      return data;
    });
  });
};
