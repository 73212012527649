// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  Row,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetUser } from "shared/utility/services/hooks/signin";
import { useEditUsers } from "shared/utility/services/hooks/users";
import * as Yup from "yup";
const Profile = () => {
  const {
    data: usersMeData,
    isLoading: usersMeLoading,
    isError: usersMeIsError,
    error: usersMeError,
    mutate: usersMe,
  } = useGetUser();

  const isExhibitorloggedInUser = usersMeData?.role === "exhibitor";
  const [activeModalStatus, setActiveModalStatus] = useState("add");
  const [openEditPasswordModal, setOpenEditPasswordModal] = useState(false);
  const [isEventFromPasswordModal, setIsEventFromPasswordModal] =
    useState(false);

  useEffect(() => {
    usersMe();
  }, [usersMe]);

  useEffect(() => {
    try {
      if (usersMeData && !usersMeLoading) {
        formik.setValues(usersMeData);
      }

      if (usersMeIsError) {
        toast.error(usersMeError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersMeData, usersMeLoading, usersMeIsError]);

  // edit users
  const {
    data: editUsersData,
    isLoading: editUsersLoading,
    isError: editUsersIsError,
    error: editUsersError,
    mutate: editUsers,
  } = useEditUsers();

  useEffect(() => {
    try {
      if (editUsersData && !editUsersLoading) {
        if (isEventFromPasswordModal) {
          modalToogle();
          toast.success("Password updated successfully");
        } else {
          toast.success("Users updated successfully");
        }
      }
      if (editUsersIsError) {
        toast.error(editUsersError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUsersData, editUsersLoading, editUsersIsError]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    business_address: "",
    business_name: "",
    business_phone: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Email invalid").required("Required"),
      phone: Yup.string().required("Required"),
      // business_address: Yup.string().nullable(),
      // business_name: Yup.string().nullable(),
      // business_phone: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      if (formik.values.id) {
        editUsers(values);
        setIsEventFromPasswordModal(false);
      }
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
    },
    values: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (formik.values.id) {
        editUsers({
          ...usersMeData,
          password: values.password,
        });
        setIsEventFromPasswordModal(true);
      }
    },
  });

  const modalToogle = (status = "add") => {
    setOpenEditPasswordModal((prev) => !prev);
    setActiveModalStatus(status);
  };

  const modalClose = () => {
    modalToogle();
    formik.setFieldValue("password", "");
  };

  let modalStatus = {
    add: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Edit Password
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-2">
            <Form role="form" onSubmit={passwordFormik.handleSubmit}>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="input-password"
                    >
                      User password
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="User password *"
                        type="password"
                        name="password"
                        value={passwordFormik.values.password}
                        onChange={passwordFormik.handleChange}
                        onBlur={passwordFormik.handleBlur}
                        invalid={
                          passwordFormik.touched.password &&
                          passwordFormik.errors.password
                        }
                        autocomplete={"new-password"}
                      />
                      <FormFeedback>
                        {passwordFormik.errors.password}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
  };
  const addEditPasswordModalOpen = () => {
    modalToogle("add");
  };

  return (
    <>
      <AdminHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      onClick={() => addEditPasswordModalOpen()}
                      size="sm"
                    >
                      Edit Password
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  User information
                </h6>
                <div>
                  <Row>
                    <Col lg="12">
                      <Form role="form" onSubmit={formik.handleSubmit}>
                        <Row className="pl-lg-4">
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                User name
                              </label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="User name *"
                                  type="text"
                                  name="name"
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    formik.touched.name && formik.errors.name
                                  }
                                />
                                <FormFeedback>
                                  {formik.errors.name}
                                </FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup className="mb-3">
                              <label
                                className="form-control-label"
                                htmlFor="input-phone"
                              >
                                User phone
                              </label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="User phone *"
                                  type="number"
                                  name="phone"
                                  value={formik.values.phone}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    formik.touched.phone && formik.errors.phone
                                  }
                                />
                                <FormFeedback>
                                  {formik.errors.phone}
                                </FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {isExhibitorloggedInUser && (
                          <div>
                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">
                              Business information
                            </h6>
                          </div>
                        )}
                        {isExhibitorloggedInUser && (
                          <Row className="pl-lg-4">
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-password"
                                >
                                  Business Name
                                </label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    placeholder="Business Name"
                                    type="text"
                                    name="business_name"
                                    value={formik.values.business_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                      formik.touched.business_name &&
                                      formik.errors.business_name
                                    }
                                  />
                                  <FormFeedback>
                                    {formik.errors.business_name}
                                  </FormFeedback>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-password"
                                >
                                  Business Phone
                                </label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    placeholder="Business Phone"
                                    type="number"
                                    name="business_phone"
                                    value={formik.values.business_phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                      formik.touched.business_phone &&
                                      formik.errors.business_phone
                                    }
                                  />
                                  <FormFeedback>
                                    {formik.errors.business_phone}
                                  </FormFeedback>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        <Row className="pl-lg-4">
                          {isExhibitorloggedInUser && (
                            <Col lg="6">
                              <FormGroup className="mb-3">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-password"
                                >
                                  Business Address
                                </label>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    placeholder="Business Address"
                                    type="text"
                                    name="business_address"
                                    value={formik.values.business_address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={
                                      formik.touched.business_address &&
                                      formik.errors.business_address
                                    }
                                  />
                                  <FormFeedback>
                                    {formik.errors.business_address}
                                  </FormFeedback>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={openEditPasswordModal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default Profile;
