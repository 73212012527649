// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useCreateEXhibition,
  useExhibitionList,
  useEditVisitors,
  useAddImageVisitors,
  useExhibitionVisitorLink,
} from "shared/utility/services/hooks/exhibition";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Table,
  ButtonGroup,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  recallExhibition,
  selectedLogo,
  selectExhibition,
  selectUser,
} from "redux/reducers/authSlice";
import { useLocation } from "react-router-dom";
// react component for creating dynamic tables
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import {
  useCreateTicket,
  useListTicketTemplate,
} from "../../../shared/utility/services/hooks/visit";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Select from "react-select";

const Tickets = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [openCreateVisitorsModal, setOpenCreateVisitorsModal] = useState(false);
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const { state } = useLocation();
  const selectedUser = useSelector(selectUser);
  const selectedExhibition = useSelector(selectExhibition);
  const {
    data: ticketListData,
    isLoading: ticketListLoading,
    isError: ticketListIsError,
    error: ticketListError,
    mutate: ticketList,
  } = useListTicketTemplate();
  useEffect(() => {
    ticketList({ id: selectedExhibition?.id });
  }, [selectedExhibition]);

  const exhibLogo = useSelector(selectedLogo);
  const {
    data: createTicketData,
    isLoading: createTicketLoading,
    isError: createTicketIsError,
    error: createTicketError,
    mutate: createTicket,
  } = useCreateTicket();
  function handleCreateTicket(values) {
    createTicket({
      ...values,
      exhibition_id: selectedExhibition?.id,
      owner_id: selectedUser?.id,
      is_valid: true,
      transaction_id: 0,
      transaction_status: "string",
      times_scanned: 0,
    });
  }

  useEffect(() => {
    if (createTicketData && !createTicketLoading) {
      toast.success("Ticket Created Successfully");
      setOpenCreateVisitorsModal(false);
    }
    if (createTicketError) {
      // toast.error(createTicketError.response.data.message);
      toast.error("Error Purchasing Ticket. Try Again");
    }
  }, [createTicketData, createTicketLoading]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  return (
    <>
      <AdminHeader />
      <Container className="mt--7 w-100" fluid>
        <Row className={"w-100"}>
          <Card className="shadows w-100">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Tickets</h3>
                </Col>
              </Row>
            </CardHeader>

            {ticketListData?.length !== 0 ? (
              <>
                <Row
                  className={
                    "d-flex p-2 align-items-center justify-content-center my-5"
                  }
                >
                  {" "}
                  {ticketListData &&
                    ticketListData.map((item, index) => (
                      <Col xs={12} md={6} key={index}>
                        <Card>
                          <CardHeader>
                            <h4 className={"text-center"}>
                              {item?.ticket_name}
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col xs={4} className={"text-left"}>
                                <img
                                  alt={"exhibition logo"}
                                  src={exhibLogo}
                                  style={{ maxHeight: "100px" }}
                                />
                              </Col>
                              <Col
                                xs={8}
                                className={
                                  "d-flex flex-column justify-content-center text-right "
                                }
                              >
                                <h3>{selectedExhibition.name}</h3>
                                <h4>
                                  {selectedExhibition.start_date} -{" "}
                                  {selectedExhibition.end_date}
                                </h4>
                                <h5>
                                  {selectedExhibition.start_time} -{" "}
                                  {selectedExhibition.end_time}
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <h5>
                                  Entry Limit:{" "}
                                  <h4> {item.constraints.times}</h4>
                                </h5>
                              </Col>
                              <Col xs={8} className={"text-right"}>
                                <h5>
                                  Locations:{" "}
                                  <h4>
                                    {item.constraints.location.map((item) => {
                                      return item + " , ";
                                    })}
                                  </h4>
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <h5>Price:</h5>
                                <h4>Rs. {item.price} /-</h4>
                              </Col>
                              <Col xs={8} className={"text-right"}>
                                <h5>
                                  {selectedExhibition.venue}
                                  <br />
                                  {selectedExhibition.address}
                                </h5>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter className={""}>
                            <FormGroup className={"w-100"}>
                              <Button
                                color={"primary"}
                                className={"w-100"}
                                onClick={() => {
                                  setOpenCreateVisitorsModal(true);
                                  setSelectedTicket(item);
                                }}
                              >
                                Buy
                              </Button>
                            </FormGroup>
                          </CardFooter>
                          {/* <CardFooter>
                            <ButtonGroup className={"w-100"}>
                              <Button color="primary">Edit</Button>
                              <Button color="danger">Delete</Button>
                            </ButtonGroup>
                          </CardFooter>*/}
                        </Card>
                      </Col>
                    ))}
                </Row>
              </>
            ) : (
              <div
                className={
                  "container-fluid d-flex flex-column justify-content-center align-items-center p-8  "
                }
              >
                <span className={"p-3 text-dark font-weight-bold"}>
                  No Tickets Available
                </span>
              </div>
            )}
          </Card>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={openCreateVisitorsModal}
          onExit={() => {
            // modalToogle();
          }}
        >
          <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Card className="bg-secondary shadow border-0">
                <div className="modal-header">
                  <h3 className="modal-title" id="modal-title-default">
                    Purchase Ticket
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {
                      setOpenCreateVisitorsModal(false);
                    }}
                  >
                    <span aria-hidden={true} className="close-button-icon">
                      ×
                    </span>
                  </button>
                </div>
                <CardBody className="px-lg-5 py-lg-5">
                  <Row>
                    <Col lg={12} className={"pb-4 text-center"}>
                      <h4 className={"text-center"}>
                        Are You Sure You Want To Purchase This Ticket?
                      </h4>
                    </Col>
                    <Col lg={6} className={"text-center"}>
                      <h5>
                        <strong>Exhibition:</strong> {selectedExhibition?.name}
                      </h5>
                      <h5>
                        <strong>Ticket:</strong> {selectedTicket?.ticket_name}
                      </h5>
                    </Col>
                    <Col lg={6} className={"text-center"}>
                      <h5>
                        <strong>Price:</strong> {selectedTicket?.price}
                      </h5>
                      <h5>
                        {selectedTicket?.constraints && (
                          <span>
                            <strong>Days:</strong>{" "}
                            {selectedTicket?.constraints?.days} "For"{" "}
                          </span>
                        )}{" "}
                        Halls : {selectedTicket?.constraints?.location}
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="bg-secondary">
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="success"
                      type="button"
                      onClick={() => {
                        handleCreateTicket({
                          ticket_template_id: selectedTicket?.id,
                        });
                      }}
                    >
                      Purchase
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </LocalizationProvider>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Tickets;
