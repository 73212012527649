import { useMutation } from "react-query";
import useAxios from "../useAxios";
import { exhibitionRoutes } from "./route";
import { objectToQueryString } from "shared/utility/helpers";
import { store } from "../../../../../redux/store";

const { exhibition, services, exhib_logo } = exhibitionRoutes;

export const useCreateEXhibition = () => {
  const callApi = useAxios();
  const { url, method } = exhibition.post;
  const idToken = store.getState();
  const logoUrl = exhib_logo.post.url;
  const logoMethod = exhib_logo.post.method;

  return useMutation(async (data) => {
    const formData = new FormData();
    if (data?.image) {
      formData.append("file", data?.image);
    }
    let response;
    return fetch(url, {
      method: method,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${idToken?.user?.authToken?.access_token}`,
      },
      body: JSON.stringify(data?.jsonData),
    }).then((res) => {
      return res.json();
    });
    /*.then((data) => {
        console.log("getting inside the function ");
        if (data?.image) {
          if (data?.id) {
            let finalLogoUrl = `${logoUrl}?exhibition_id=${data.id}`;
            fetch(finalLogoUrl, {
              method: logoMethod,
              headers: {
                "Access-Control-Allow-Origin": "*",
                authorization: `Bearer ${idToken?.user?.authToken?.access_token}`,
              },
              body: formData,
            });
          }
        }
        return response;
      })*/
  });
};

export const useAddImageExhibition = () => {
  const callApi = useAxios();
  const { url, method } = exhib_logo.post;

  return useMutation((data) => {
    const queryString = objectToQueryString({
      exhibition_id: data?.exhibition_id,
    });
    let newUrl = `${url}?${queryString}`;
    const formData = new FormData();
    formData.append("file", data?.image);
    return callApi({
      method: method,
      url: newUrl,
      data: formData,
    }).then((data) => {
      return data;
    });
  });
};

export const useEditExhibition = () => {
  const callApi = useAxios();
  const { url, method } = exhibition.put;

  return useMutation((data) => {
    return callApi({
      method: method,
      url: url + `/${data.id}`,
      data,
    }).then((data) => data);
  });
};

export const useDeleteExhibition = () => {
  const callApi = useAxios();
  const { url, method } = exhibition.delete;

  return useMutation((data) => {
    return callApi({
      method: method,
      url: url + `/${data.id}`,
      data,
    }).then((data) => data);
  });
};

export const useExhibitionList = () => {
  const callApi = useAxios();
  const { url, method } = exhibition.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);

    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useServicesList = () => {
  const callApi = useAxios();
  const { url, method } = services.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);

    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useCreateServices = () => {
  const callApi = useAxios();
  const { url, method } = services.post;

  const idToken = store.getState();

  return useMutation((data) => {
    const queryString = objectToQueryString({
      exhibition_id: data?.exhibition_id,
      name: data?.data[0]?.name,
      price: data?.data[0]?.price,
      description: data?.data[0]?.description,
    });
    let newUrl = `${url}?${queryString}`;
    console.log(data);
    const formData = new FormData();
    formData.append("image", data?.service, data?.service.name);
    console.log(formData);
    return fetch(newUrl, {
      method: method,
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${idToken?.user?.authToken?.access_token}`,
      },
      body: formData,
    }).then((data) => data);
  });
};

export const useEditServices = () => {
  const callApi = useAxios();
  const { url, method } = services.put;

  return useMutation((data) => {
    const queryString = objectToQueryString({
      exhibition_id: data.exhibition_id,
      service_id: data.service_id,
      name: data.name,
      price: data.price,
      description: data.description,
      disabled: data.disabled,
    });
    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useGetAllNotifications = () => {
  const callApi = useAxios();
  const { url, method } = exhibitionRoutes.nofitication.getAll;

  return useMutation((data) => {
    const newUrl = `${url}/?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method: method,
      url: url,
    }).then((data) => data);
  });
};
export const useGetOneNotification = () => {
  const callApi = useAxios();
  const { url, method } = exhibitionRoutes.nofitication.getOne;

  return useMutation((data) => {
    const newUrl = `${url}${data.id}`;
    return callApi({
      method: method,
      url: url + data.id,
    }).then((data) => data);
  });
};

export const useReadNotification = () => {
  const callApi = useAxios();
  const { url, method } = exhibitionRoutes.nofitication.read;

  return useMutation((data) => {
    const newUrl = `${url}${data.id}/read`;
    return callApi({
      method: method,
      url: url,
    }).then((data) => data);
  });
};

export const useExhibitionVisitorLink = () => {
  const callApi = useAxios();
  const { url, method } = exhibitionRoutes.exhibition_link.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useVisitorList = () => {
  const callApi = useAxios();
  const { url, method } = exhibitionRoutes.visitor.get;
  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};
