import { store } from "../../../../redux/store";
import { setAuthToken } from "../../../../redux/reducers/authSlice";
import axios from "axios";
import { baseURL } from "../../../../config";

export const refreshTokenRotate = async () => {
  try {
    console.log("refreshTokenRotate");
    const idToken = store.getState();
    const refreshToken = idToken?.user?.authToken?.refresh_token;
    const refreshTokenUrl =
      baseURL + "/api/refresh-token?refresh_token=" + refreshToken;
    // Call the API endpoint for refreshing tokens
    console.log("refreshTokenUrl", refreshTokenUrl);
    let token_resp;
    await axios
      .post(
        refreshTokenUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // Required for CORS support to work
            authorization: `Bearer ${idToken?.user?.authToken?.access_token}`,
          },
        },
      )
      .then((res) => {
        if (res.status === 401) {
          store.dispatch({ type: "USER_LOGOUT" });
        }

        token_resp = res.data;
      });
    console.log("token_resp", token_resp.access_token);
    // If the API call is successful, replace  the existing tokens with the new ones
    if (token_resp && token_resp.access_token && token_resp.refresh_token) {
      store.dispatch(
        setAuthToken({
          token_type: "bearer",
          access_token: token_resp.access_token,
          refresh_token: token_resp.refresh_token,
        }),
      );
    }
    if (token_resp.error) {
      store.dispatch({ type: "USER_LOGOUT" });
    }
    return token_resp;
  } catch (error) {
    console.error("Failed to refresh token:", error);
  }
};
