import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'user',
  initialState: {
    authToken: undefined,
    userData: undefined,
    selectedExhibition: null,
    recallExhibition: false,
    theme : ""
  },
  reducers: {
    setUser(state, action) {
      state.userData = action.payload;
    },
    removeUser(state) {
      state.userData = null;
    },
    setAuthToken(state, action) {
      state.authToken = action.payload;
    },
    removeAuthToken(state) {
      state.authToken = null;
    },
    setSelectedExhibition(state, action) {
      state.selectedExhibition = action.payload;
    },
    removeSelectedExhibition(state) {
      state.selectedExhibition = null;
    },
    recallExhibition(state) {
      state.recallExhibition = !state.recallExhibition;
    },
    setTheme(state,action) {
      state.theme = action.payload
    },
    setSelectedLogo(state,action) {
      state.selectedLogo = action.payload
    }
  },
});

export const {
  setUser,
  removeUser,
  setAuthToken,
  removeAuthToken,
  setSelectedExhibition,
  setSelectedLogo,
  removeSelectedExhibition,
  recallExhibition,
  setTheme
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthToken = (state) => state.user.authToken;
export const selectUser = (state) => state.user.userData;
export const selectExhibition = (state) => state.user.selectedExhibition;
export const selectRecallExhibition = (state) => state.user.recallExhibition;
export const selectTheme = (state) => state.user.theme;
export const selectedLogo = (state)=> state.user.selectedLogo;
