import { baseURL } from "../../../../../config";

export const visitRoutes = {
  ticket_template: {
    create: {
      query: "CREATE_TICKET_TEMPLATE",
      method: "POST",
      url: `${baseURL}/api/exhibition/ticket_template`,
    },
    update: {
      query: "UPDATE_TICKET_TEMPLATE",
      method: "PUT",
      url: `${baseURL}/api/exhibition/ticket_template/`,
    },
    delete: {
      query: "DELETE_TICKET_TEMPLATE",
      method: "DELETE",
      url: `${baseURL}/api/exhibition/ticket_template/`,
    },
    list: {
      query: "LIST_TICKET_TEMPLATE",
      method: "GET",
      url: `${baseURL}/api/exhibition/`,
    },
  },
  ticket: {
    create: {
      query: "CREATE_TICKET",
      method: "POST",
      url: `${baseURL}/api/exhibition/ticket`,
    },
    update: {
      query: "UPDATE_TICKET",
      method: "PUT",
      url: `${baseURL}/api/exhibition/ticket/`,
    },
    delete: {
      query: "DELETE_TICKET",
      method: "DELETE",
      url: `${baseURL}/api/exhibition/ticket/`,
    },
    list: {
      query: "LIST_TICKET",
      method: "GET",
      url: `${baseURL}/api/exhibition/`,
    },
    scan: {
      query: "SCAN_TICKET",
      method: "POST",
      url: `${baseURL}/api/exhibition/ticket/scan/`,
    },
    verify: {
      query: "VERIFY_TICKET",
      method: "POST",
      url: `${baseURL}/api/exhibition/ticket/verify/`,
    },
  },
};
