
import React from "react";
import ReactDOM from "react-dom";
import Main from "Main";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "styles/common.css";

import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);