import React from 'react';
import { Row, Col, Table, Badge, Alert } from 'reactstrap';

const CompactStallsOnHold = ({ onHoldStalls }) => {
    // Calculate summary data
    const totalOnHold = onHoldStalls.length;
    const uniqueHalls = [...new Set(onHoldStalls.map(stall => stall.hall_or_dome))];
    const recentStalls = onHoldStalls.sort((a, b) => new Date(b.on_hold_date) - new Date(a.on_hold_date)).slice(0, 5);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="p-3">
            <h6 className="text-uppercase text-muted mb-3">Stalls on Hold</h6>
            <Row className="mb-4 text-center">
                <Col xs="6">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{totalOnHold}</span>
                    </div>
                    <span className="text-muted text-sm">Total On Hold</span>
                </Col>
                <Col xs="6">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{uniqueHalls.length}</span>
                    </div>
                    <span className="text-muted text-sm">Halls Affected</span>
                </Col>
            </Row>

            {totalOnHold === 0 ? (
                <Alert color="success" className="text-center mb-0">
                    <i className="fas fa-check-circle mr-2"></i>
                    No stalls are currently on hold. All stalls are either available or booked.
                </Alert>
            ) : (
                <>
                    <h6 className="text-uppercase text-muted mb-3">Recent On Hold Stalls</h6>
                    <Table responsive borderless size="sm" className="align-items-center mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Name</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Hall</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Date</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {recentStalls.map((stall) => (
                            <tr key={stall.id}>
                                <td>
                                    <span className="text-sm font-weight-bold">{stall.name}</span>
                                </td>
                                <td className="text-sm">{stall.hall_or_dome}</td>
                                <td className="text-sm">{formatDate(stall.on_hold_date)}</td>
                                <td>
                                    <Badge color="warning" pill>
                                        On Hold
                                    </Badge>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </>
            )}
        </div>
    );
};

export default CompactStallsOnHold;