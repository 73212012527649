export const objectToQueryString = (obj) => {
  const queryString = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        // If the value is an array, create multiple key-value pairs for it
        return value
          .map(
            (element) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(element)}`,
          )
          .join("&");
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join("&");

  return queryString;
};

export const exhibition_allowed_tags = [
  {
    value: "1 side open",
    label: "1 side open",
  },
  {
    value: "2 side open",
    label: "2 side open",
  },
  {
    value: "3 side open",
    label: "3 side open",
  },
  {
    value: "4 side open",
    label: "4 side open",
  },
];

export const exhibition_locations = [
  {
    value: "Hall 1",
    label: "Hall 1",
  },
  {
    value: "Hall 2",
    label: "Hall 2",
  },
  {
    value: "Hall 3",
    label: "Hall 3",
  },
  {
    value: "Hall 4",
    label: "Hall 4",
  },
  {
    value: "Dome 1 ",
    label: "Dome 1 ",
  },
  {
    value: "Dome 2",
    label: "Dome 2",
  },
  {
    value: "Dome 3",
    label: "Dome 3",
  },
  {
    value: "Dome 4",
    label: "Dome 4",
  },
];
export const stallStates_bg = {
  empty: "bg-info",
  on_hold: "bg-warning",
  filled: "bg-success",
};

export const stallStates = {
  empty: "Available",
  on_hold: "On Hold",
  filled: "Booked",
};
export const stallStatues = {
  new: "Available Stall",
  doc_sub: "Document Submission",
  doc_approve: "Document Approved",
  doc_reject: "Document Rejected",
  payment_pending: "Payment Pending",
  approved: "Approved",
  all_ok: "All Ok",
};
export const stallStatues_color = {
  new: "bg-info",
  doc_sub: "bg-warning",
  doc_approve: "bg-warning",
  doc_reject: "bg-danger",
  payment_pending: "bg-danger",
  approved: "bg-info",
  all_ok: "bg-success",
};
export const stallStatus = [
  { no: 0, title: "doc_sub", message: "Submit Documents" },
  { no: 1, title: "payment_pending", message: "Pay to confirm booking" },
  { no: 2, title: "approved", message: "Stall Allotted" },
  { no: 3, title: "all_ok", message: "Approved" },
];
export const stallSteps = [
  { no: 0, title: "new", label: "View Info" },
  { no: 1, title: "doc_sub", label: "Submit Documents" },
  { no: 2, title: "payment_pending", label: "Pay to confirm booking" },
  { no: 3, title: "approved", label: "Stall Allotted" },
  { no: 4, title: "all_ok", label: "Approved" },
];

export const fileNames = {
  kyc_document: "kyc_document",
  stall_design_document: "stall_design_document",
  approval_form: "approval_form",
  contract_form: "contract_form",
};

export const required_documents = [
  { value: "KYC Document", label: "KYC Document" },
  { value: "GST Document", label: "GST Document" },
];

export const getEntriesByFileType = (jsonArray, fileType) => {
  // Use the filter method to find entries where file_type is equal to the specified value
  const filteredEntries = jsonArray.filter(
    (entry) => entry.file_type === fileType,
  );

  // Return the filtered array
  return filteredEntries[0];
};
export const downloadFileLocal = (presigneUrl, fileName) => {
  const link = document.createElement("a");
  link.href = presigneUrl;
  link.setAttribute("download", fileName);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const categories_options = [
  { value: "Trade Shows", label: "Trade Shows" },
  { value: "Science and Technology", label: "Science and Technology" },
  { value: "Fashion and Apparel", label: "Fashion and Apparel" },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Travel and Tourism", label: "Travel and Tourism" },
  { value: "Healthcare and Medical", label: "Healthcare and Medical" },
  { value: "Education and Career", label: "Education and Career" },
  { value: "Agriculture and Farming", label: "Agriculture and Farming" },
  { value: "Automotive", label: "Automotive" },
  {
    value: "Interior Design and Home Improvement",
    label: "Interior Design and Home Improvement",
  },
  {
    value: "Environmental and Sustainability",
    label: "Environmental and Sustainability",
  },
  { value: "Wedding and Bridal", label: "Wedding and Bridal" },
  { value: "Music and Entertainment", label: "Music and Entertainment" },
  { value: "Sports and Fitness", label: "Sports and Fitness" },
  { value: "Real Estate and Property", label: "Real Estate and Property" },
  { value: "Jewelry and Gemstones", label: "Jewelry and Gemstones" },
  { value: "Gaming and Technology", label: "Gaming and Technology" },
  { value: "Crafts and Handmade Goods", label: "Crafts and Handmade Goods" },
  { value: "Antiques and Collectibles", label: "Antiques and Collectibles" },
  { value: "Pet and Animal Shows", label: "Pet and Animal Shows" },
  { value: "Cultural Festivals", label: "Cultural Festivals" },
  { value: "Renewable Energy", label: "Renewable Energy" },
  { value: "Wearable Technology", label: "Wearable Technology" },
  { value: "Aerospace and Aviation", label: "Aerospace and Aviation" },
  {
    value: "Pharmaceutical and Healthcare",
    label: "Pharmaceutical and Healthcare",
  },
  { value: "Wine and Spirits", label: "Wine and Spirits" },
];

export const paymentStatus = [
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
];

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const create_exhibition_popovers = {
  name: "Name of the Exhibition",
  logo: "Logo",
  venue: "Venue of the Exhibition",
  address: "Location of the Exhibition",
  timeout_days: "Timeout Days",
  required_documents: "Required Documents",
  exhibitor_reg_start_date: "Exhibitor Registration Start Date",
  exhibitor_reg_end_date: "Exhibitor Registration End Date",
  visitor_reg_end_date: "Visitor Registration End Date",
  visitor_reg_start_date: "Visitor Registration Start Date",
  start_date: "Start Date",
  end_date: "End Date",
  start_time: "Start Time",
  end_time: "End Time",
  measurement_unit: "Measurement Unit",
  category: "Category",
  allowed_tags: "Allowed Tags",
  allowed_services: "Allowed Services",
  allowed_hall_or_dome: "Allowed Hall or Dome",
  terms_and_conditions: "Terms and Conditions of the Exhibition",
};

export const create_stall_popovers = {
  stall_no: "Stall Number",
  stall_area: "Stall Area",
  stall_price: "Stall Price",
  stall_state: "Stall State",
  stall_status: "Stall Status",
  stall_status_color: "Stall Status Color",
  stall_status_message: "Stall Status Message",
  stall_status_no: "Stall Status Number",
  stall_status_label: "Stall Status Label",
};
export const sortCarets = (order, column) => {
  if (!order)
    return (
      <span>
        &nbsp;&nbsp;<i className="fa-solid fa-arrow-up"></i>/
        <i className="fa-solid fa-arrow-down"></i>
      </span>
    );
  else if (order === "asc")
    return (
      <span>
        &nbsp;&nbsp;<i className="fa-solid fa-arrow-up"></i>/
        <font color="red">
          <i className="fa-solid fa-arrow-down"></i>
        </font>
      </span>
    );
  else if (order === "desc")
    return (
      <span>
        &nbsp;&nbsp;
        <font color="red">
          <i className="fa-solid fa-arrow-up"></i>
        </font>
        /<i className="fa-solid fa-arrow-down"></i>
      </span>
    );
  return null;
};
