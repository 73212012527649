import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ToastContainr() {
  return (
    <ToastContainer
      position={'top-center'}
      hideProgressBar
      pauseOnHover
      autoClose={5000}
      theme="colored"
      transition={Zoom}
    />
  );
}
