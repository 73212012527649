import { baseURL } from "../../../../../config";

export const loginRoutes = {
  signin: {
    post: {
      query: "SIGN_UP",
      method: "POST",
      url: `${baseURL}/api/token`,
    },
  },
  user: {
    get: {
      query: "USER",
      method: "GET",
      url: `${baseURL}/api/me`,
    },
  },
  reset: {
    post: {
      query: "RESET",
      method: "POST",
      url: `${baseURL}/api/user/reset-password`,
    },
  },
  register: {
    post: {
      query: "REGISTER",
      method: "POST",
      url: `${baseURL}/api/user/visitor`,
    },
    get: {
      query: "GET_USER",
      method: "GET",
      url: `${baseURL}/api/user/exhibitions/`,
    },
  },
};
