// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import AdminHeader from "components/Headers/AdminHeader";
import {
  useCreatePayment,
  useGetPaymentList,
  useUpdatePayment,
} from "shared/utility/services/hooks/stalls";
import { selectExhibition, selectUser } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import NoData from "shared/common/NoData";
import { useNavigate } from "react-router-dom";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { sortCarets } from "../../shared/utility/helpers";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show{" "}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm d-inline w-auto mx-1"
          defaultValue={"25"}
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Notifications = () => {
  const navigate = useNavigate();
  const selectedExhibition = useSelector(selectExhibition);
  const user = useSelector(selectUser);
  const isAdminloggedInUser = user.role === "admin";
  const isSalesloggedInUser = user.role === "sales";
  const isEditStallModeOn = user.role === "admin" || user.role === "sales";
  const componentRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [query, setQuery] = useState({
    skip: 0,
    exhibition_id: selectedExhibition?.id,
  });
  const [bulkUploadDocument, setBulkUploadDocument] = useState(null);

  const [unAssignedUserQuery, setUnAssignedUserQuery] = useState({
    skip: 0,
    search: "",
    roles: ["exhibitor"],
    current_exhibition: selectedExhibition?.id,
  });
  const [selectedStallPayments, setSelectedStallPayments] = useState();

  const modalToogle = (status) => {
    setModal((prev) => !prev);
    setActiveModalStatus(status);
  };
  const justChangeModal = (status) => {
    setActiveModalStatus(status);
  };

  // list stalls
  const {
    data: paymentsListData,
    isLoading: paymentsListLoading,
    isError: paymentsListIsError,
    error: paymentsListError,
    mutate: paymentsList,
  } = useGetPaymentList();

  useEffect(() => {
    try {
      if (paymentsListData && !paymentsListLoading) {
      }
      if (paymentsListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentsListData,
    paymentsListLoading,
    paymentsListIsError,
    paymentsListError,
  ]);

  useEffect(() => {
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
    );
    console.log(selectedExhibition);
  }, [selectedExhibition]);

  const {
    data: createPaymentData,
    isLoading: createPaymentLoading,
    isError: createPaymentIsError,
    error: createPaymentError,
    mutate: createPayment,
  } = useCreatePayment();
  useEffect(() => {
    if (createPaymentData && !createPaymentLoading) {
      toast.success("Payment Added successfully");
      modalToogle();
      paymentsList(query);
    }
  }, [createPaymentData]);

  useEffect(() => {
    if (!paymentsListLoading && selectedExhibition?.id) {
      paymentsList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!paymentsListLoading) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  const initialValues = {
    stall_id: "",
    amount_paid: 0,
    payment_type: "",
    amount: 0,
  };
  const [paymentProof, setPaymentProof] = useState(null);
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      stall_id: Yup.string().required("Required"),
      amount_paid: Yup.string().required("Required"),
      payment_type: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // participationDownload(values);
      console.log({ values, paymentProof }, "values");
      createPayment({ values, paymentProof });
      modalClose();
    },
  });

  const modalClose = () => {
    modalToogle();
    formik.setValues(initialValues);
  };

  const {
    data: updatePaymentData,
    isLoading: updatePaymentLoading,
    isError: updatePaymentIsError,
    error: updatePaymentError,
    mutate: updatePayment,
  } = useUpdatePayment();

  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        {!selectedExhibition && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <NoData title="For Notifications, Please set up an exhibition." />
              </Card>
            </div>
          </Row>
        )}
        {selectedExhibition && (
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Notifications</h3>
                    </Col>
                  </Row>
                </CardHeader>
                {!paymentsListData?.results ? (
                  <NoData title="No data found" />
                ) : (
                  <ToolkitProvider
                    data={paymentsListData?.results}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Stall Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "price",
                        text: "Price",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "status_pill",
                        text: "Status",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "bookBtn",
                        text: "Payment",
                        sort: false,
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "viewBtn",
                        text: "View",
                        sort: false,
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Notifications;
