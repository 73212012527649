// reactstrap components
import AdminLayout from "layouts/Admin.js";
import SalesLayout from "layouts/Sales.js";
import ExhibitorLayout from "layouts/Exhibitor.js";
import BackOfficeLayout from "layouts/BackOffice.js";
import PublicLayout from "layouts/Public.js";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ToastContainr from "components/toastr/ToastContainr";
import { useSelector } from "react-redux";
import { selectUser } from "redux/reducers/authSlice";
import VisitorLayout from "./layouts/Visitor";
import BouncerLayout from "./layouts/BouncerLayout";

const Main = () => {
  const user = useSelector(selectUser);

  return (
    <BrowserRouter>
      <ToastContainr />
      <Routes>
        {/* <Route path="/admin/*" element={<AdminLayout />} /> */}
        {/* <Route path="/auth/*" element={<AuthLayout />} /> */}
        {user?.role === "admin" && (
          <Route path="/admin/*" element={<AdminLayout />} />
        )}
        {user?.role === "sales" && (
          <Route path="/sales/*" element={<SalesLayout />} />
        )}
        {user?.role === "exhibitor" && (
          <Route path="/exhibitor/*" element={<ExhibitorLayout />} />
        )}
        {user?.role === "backoffice" && (
          <Route path="/back-office/*" element={<BackOfficeLayout />} />
        )}
        {user?.role === "visitor" && (
          <Route path="/visitor/*" element={<VisitorLayout />} />
        )}
        {user?.role === "bouncer" && (
          <Route path="/bouncer/*" element={<BouncerLayout />} />
        )}
        <Route path="/pb/*" element={<PublicLayout />} />
        <Route path="*" element={<Navigate to="/pb/login" replace />} />
        {/* <Route path="*" element={<Navigate to="/admin/index" replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Main;
