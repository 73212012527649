// reactstrap components
import { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useCreateEXhibition,
  useExhibitionList,
  useEditVisitors,
  useAddImageVisitors,
  useExhibitionVisitorLink,
} from "shared/utility/services/hooks/exhibition";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Table,
  ButtonGroup,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  recallExhibition,
  selectedLogo,
  selectExhibition,
  selectUser,
} from "redux/reducers/authSlice";
import { useLocation } from "react-router-dom";
// react component for creating dynamic tables
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import {
  useCreateTicket,
  useListTicket,
  useListTicketTemplate,
} from "../../../shared/utility/services/hooks/visit";
import QRCode from "react-qr-code";
import { toJpeg, toPng } from "html-to-image";
import ExhibitionTicket from "../../../components/Ticket/Ticket";
const TicketsPurchased = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [openCreateVisitorsModal, setOpenCreateVisitorsModal] = useState(false);
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const { state } = useLocation();
  const selectedUser = useSelector(selectUser);
  const selectedExhibition = useSelector(selectExhibition);
  const {
    data: ticketListData,
    isLoading: ticketListLoading,
    isError: ticketListIsError,
    error: ticketListError,
    mutate: ticketList,
  } = useListTicket();
  useEffect(() => {
    ticketList({ id: selectedExhibition?.id });
  }, [selectedExhibition]);

  const exhibLogo = useSelector(selectedLogo);

  return (
    <>
      <AdminHeader />
      <Container
        className="mt--7 w-100  d-flex justify-content-center align-items-center"
        fluid
      >
        <Row
          className={"w-100 d-flex justify-content-center align-items-center"}
        >
          <Card className="shadows w-100">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Purchased Tickets</h3>
                </Col>
              </Row>
            </CardHeader>

            {ticketListData?.length !== 0 ? (
              <>
                <Row
                  className={
                    "d-flex p-2 align-items-center justify-content-center my-5"
                  }
                >
                  {" "}
                  {ticketListData &&
                    ticketListData.map((item, index) => (
                      <>
                        {/*<Col xs={12} sm={12} md={6} key={index}>
                        <Card>
                          <CardHeader>
                            <h4 className={"text-center"}>
                              {item.ticket_name}
                            </h4>
                          </CardHeader>
                          <div
                            className={"card-body rounded"}
                            ref={ticketRef}
                            style={{
                              background: "white",
                              border: "2px solid black",
                            }}
                          >
                            <Row className={"p-2"}>
                              <Col sm={3} md={6} lg={3} className={"text-right"}>
                                <img
                                  alt={"exhibition logo"}
                                  src={exhibLogo}
                                  style={{ maxHeight: "100px" , maxWidth: "100px" }}
                                />
                              </Col>
                              <Col
                                  sm={6}
                                md={12} lg={6}
                                className={
                                  "d-flex flex-column justify-content-center text-center "
                                }
                              >
                                <h3>{selectedExhibition.name}</h3>
                                <h5>
                                  {selectedExhibition.start_date} -{" "}
                                  {selectedExhibition.end_date}
                                </h5>
                              </Col>
                              <Col sm={3} md={6} lg={3} className={"text-right"}>
                                <QRCode value={item.uuid} size={100} />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4} sm={4}>
                                <h5>
                                  Entry Limit:{" "}
                                  <h4> {item.constraints.times} Days</h4>
                                </h5>
                              </Col>
                              <Col xs={8} sm={8} className={"text-right"}>
                                <h5>
                                  Locations:{" "}
                                  <h4>
                                    {item.constraints.location.map((item) => {
                                      return item + " , ";
                                    })}
                                  </h4>
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4} sm={4}>
                                {item.price ||
                                  item.price === 0 ||
                                  (item.price !== "" && (
                                    <>
                                      <h5>Price:</h5>
                                      <h4>Rs. {item.price} /-</h4>
                                    </>
                                  ))}
                              </Col>
                              <Col xs={8} sm={8} className={"text-right"}>
                                <h5>
                                  {selectedExhibition.venue}
                                  <br />
                                  {selectedExhibition.address}
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <CardFooter className={""}>
                            <Button
                              color={"primary"}
                              className={"w-100"}
                              onClick={() => {
                                // setOpenCreateVisitorsModal(true);
                                setSelectedTicket(item.ticket_name);
                                onButtonClick();
                              }}
                            >
                              Download
                            </Button>
                          </CardFooter>
                           <CardFooter>
                            <ButtonGroup className={"w-100"}>
                              <Button color="primary">Edit</Button>
                              <Button color="danger">Delete</Button>
                            </ButtonGroup>
                          </CardFooter>
                        </Card>
                      </Col>*/}
                        <ExhibitionTicket
                          selectedExhibition={selectedExhibition}
                          item={item}
                          exhibLogo={exhibLogo}
                        />
                      </>
                    ))}
                </Row>
              </>
            ) : (
              <div
                className={
                  "container-fluid d-flex flex-column justify-content-center align-items-center p-8  "
                }
              >
                <span className={"p-3 text-dark font-weight-bold"}>
                  No Tickets Available
                </span>
              </div>
            )}
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default TicketsPurchased;
