import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetUser,
  useResetPassword,
  useRegisterVisitor,
} from "shared/utility/services/hooks/signin";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const exhib = queryParams.get("exhib");
  useEffect(() => {
    if (exhib) {
      console.log(exhib, "exhib");
      formik.setFieldValue("exhibition_uuid", exhib);
    } else {
      navigate("/pb/login");
    }
  }, []);

  const {
    data: registerData,
    isLoading: registerLoading,
    isError: registerIsError,
    error: registerError,
    mutate: register,
  } = useRegisterVisitor();

  useEffect(() => {
    try {
      if (registerData && !registerLoading) {
        console.log(registerData, "registerData");
        navigate("/pb/login");
      }
      if (registerIsError) {
        toast.error(registerError?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerData, registerLoading, registerIsError]);
  const initialValues = {
    exhibition_uuid: exhib,
    name: "string",
    email: "string",
    phone: "string",
    role: "visitor",
    brand_name: "string",
    business_name: "string",
    business_address: "string",
    business_phone: "string",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      name: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      brand_name: Yup.string().required("Required"),
      business_name: Yup.string().required("Required"),
      business_address: Yup.string().required("Required"),
      business_phone: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      register(values);
    },
  });
  const handleFormikSubmit = () => {
    console.log("formik.handleSubmit()");
    formik.handleSubmit();
  };
  return (
    <Col lg="12" md="12">
      <Row>
        <Col lg={"12"}></Col>
        <Col lg="12" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" className={"row"}>
                <FormGroup className="mb-3 col-6">
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Name
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Name *"
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={formik.touched.name && formik.errors.name}
                    />
                    <FormFeedback>{formik.errors.name}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3 col-6">
                  <label className="form-control-label" htmlFor="input-email">
                    Email
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email *"
                      type="email"
                      className={"form-control border-light"}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={formik.touched.email && formik.errors.email}
                    />
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3 col-6">
                  <label className="form-control-label" htmlFor="input-phone">
                    Phone
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone *"
                      type="tel"
                      name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={formik.touched.phone && formik.errors.phone}
                    />
                    <FormFeedback>{formik.errors.phone}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3 col-6">
                  <label
                    className="form-control-label"
                    htmlFor="input-business_name"
                  >
                    Brand Name
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Brand Name *"
                      type="text"
                      name="brand_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.brand_name && formik.errors.brand_name
                      }
                    />
                    <FormFeedback>{formik.errors.brand_name}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3 col-6">
                  <label
                    className="form-control-label"
                    htmlFor="input-business_name"
                  >
                    Business Name
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Business Name *"
                      type="text"
                      name="business_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.business_name &&
                        formik.errors.business_name
                      }
                    />
                    <FormFeedback>{formik.errors.business_name}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3 col-6">
                  <label
                    className="form-control-label"
                    htmlFor="input-business_phone"
                  >
                    Business Phone
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Business Phone *"
                      type="tel"
                      name="business_phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.business_phone &&
                        formik.errors.business_phone
                      }
                    />
                    <FormFeedback>{formik.errors.business_phone}</FormFeedback>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3 col-12">
                  <label
                    className="form-control-label"
                    htmlFor="input-business_address"
                  >
                    Business Address
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Business Address *"
                      type="text"
                      name="business_address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.business_address &&
                        formik.errors.business_address
                      }
                    />
                    <FormFeedback>
                      {formik.errors.business_address}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <div className="text-center col-12">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFormikSubmit();
                      console.log("clicked");
                    }}
                  >
                    Register
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default Register;
