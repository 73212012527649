// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Badge,
  ButtonGroup,
  UncontrolledPopover,
  PopoverBody,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import AdminHeader from "components/Headers/AdminHeader";
import {
  useStallsList,
  useCreateStalls,
  useEditStalls,
  useAssignStallToUsers,
  useBulkAddStalls,
  useMergeStalls,
  useResetStall,
  useHoldStalls,
  useConvertStall,
  useUnHoldStalls,
} from "shared/utility/services/hooks/stalls";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import {
  useBusinessUsersList,
  useUnassignedUsersList,
} from "shared/utility/services/hooks/users";
import { selectUser } from "redux/reducers/authSlice";
import NoData from "shared/common/NoData";
import moment from "moment";
import {
  stallStates,
  stallStates_bg,
  stallStatues,
  stallStatues_color,
} from "shared/utility/helpers";
import { useNavigate } from "react-router-dom";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { store } from "../../redux/store";
import Createable from "react-select/creatable";
import Select from "react-select";
import { useLogin } from "../../shared/utility/services/hooks/signin";

import { sortCarets } from "../../shared/utility/helpers";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show{" "}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm d-inline w-auto mx-1"
          defaultValue={"25"}
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Stalls = () => {
  const navigate = useNavigate();
  const selectedExhibition = useSelector(selectExhibition);
  const user = useSelector(selectUser);
  const isAdminloggedInUser = user.role === "admin";
  const isSalesloggedInUser = user.role === "sales";
  const isEditStallModeOn = user.role === "admin" || user.role === "sales";
  const componentRef = useRef(null);
  const componentRef1 = useRef(null);
  const [modal, setModal] = useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const [query, setQuery] = useState({
    skip: 0,
    exhibition_id: selectedExhibition?.id,
  });
  const [bulkUploadDocument, setBulkUploadDocument] = useState(null);
  const [modalIsNewAdd, setModalIsNewAdd] = useState(false);
  const [modalIsEdit, setModalIsEdit] = useState(false);
  const [unAssignedUserQuery, setUnAssignedUserQuery] = useState({
    skip: 0,
    search: "",
    roles: ["exhibitor"],
    current_exhibition: selectedExhibition?.id,
  });

  const modalToogle = (status = "add") => {
    setModal((prev) => !prev);
    setActiveModalStatus(status);
  };
  const [mergeStall1, setMergeStall1] = useState(null);
  const [mergeStall2, setMergeStall2] = useState(null);
  const [mergeStallOptions, setMergeStallOptions] = useState([]);
  // list stalls
  const {
    data: stallsListData,
    isLoading: stallsListLoading,
    isError: stallsListIsError,
    error: stallsListError,
    mutate: stallsList,
  } = useStallsList();

  useEffect(() => {
    try {
      let mergeStallData = [];
      if (stallsListData && !stallsListLoading) {
        stallsListData.results.map((item) => {
          if (item.state === "empty") {
            mergeStallData?.push({ value: item, label: item.name });
          }
        });
        console.log("mergeStallData", mergeStallData);
        setMergeStallOptions(mergeStallData);
      }

      if (stallsListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stallsListData, stallsListLoading, stallsListIsError, stallsListError]);

  useEffect(() => {
    if (!stallsListLoading && selectedExhibition?.id) {
      stallsList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!stallsListLoading) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  // create stalls
  const {
    data: createStallsData,
    isLoading: createStallsLoading,
    isError: createStallsIsError,
    error: createStallsError,
    mutate: createStalls,
  } = useCreateStalls();

  useEffect(() => {
    try {
      if (createStallsData && !createStallsLoading) {
        toast.success("Stalls created successfully");
        modalclose();
        stallsList(query);
        formik.handleReset();
      }
      if (createStallsIsError) {
        stallsList(query);
        toast.error(
          createStallsError?.response?.data?.error ?? "something went wrong",
        );
        formik.handleReset();
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStallsData, createStallsLoading, createStallsIsError]);

  // edit stalls
  const {
    data: editStallsData,
    isLoading: editStallsLoading,
    isError: editStallsIsError,
    error: editStallsError,
    mutate: editStalls,
  } = useEditStalls();

  useEffect(() => {
    try {
      if (editStallsData && !editStallsLoading) {
        toast.success("Stalls updated successfully");
        stallsList(query);
        modalclose();
        formik.handleReset();
      }
      if (editStallsIsError) {
        stallsList(query);
        toast.error(
          editStallsError?.response?.data?.detail ?? "Something went wrong",
        );
        formik.handleReset();
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStallsData, editStallsLoading, editStallsIsError]);

  // bulkAdd stalls
  const {
    data: bulkAddStallsData,
    isLoading: bulkAddStallsLoading,
    isError: bulkAddStallsIsError,
    error: bulkAddStallsError,
    mutate: bulkAddStalls,
  } = useBulkAddStalls();

  useEffect(() => {
    try {
      if (bulkAddStallsData && !bulkAddStallsLoading) {
        toast.success("Stalls added successfully");
        stallsList(query);
        setBulkUploadDocument(null);
        modalToogle();
        formik.handleReset();
      }
      if (bulkAddStallsIsError) {
        stallsList(query);
        let err = bulkAddStallsError?.response?.data?.error;

        // Now parse the JSON string into an object

        if (err.error === "Invalid tags or hall_or_dome") {
          if (err.error_tags.length > 0) {
            toast.error(`Invalid tags: ${err.error_tags.join(", ")}`);
          }
          if (err.error_hall.length > 0) {
            toast.error(`Invalid hall: ${err.error_hall.join(", ")}`);
          }
        } else {
          toast.error(err.error);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkAddStallsData, bulkAddStallsLoading, bulkAddStallsIsError]);

  const uploadDocumnet = () => {
    if (!bulkUploadDocument) {
      toast.error("Document missing");
    } else if (
      bulkUploadDocument &&
      bulkUploadDocument.size > 5 * 1024 * 1024
    ) {
      // 5MB in bytes
      toast.error("File size should be less than 5MB");
    } else {
      const formData = new FormData();
      formData.append("exhibition_id", selectedExhibition?.id);
      formData.append("file", bulkUploadDocument);
      console.log(formData);
      bulkAddStalls(formData);
    }
  };

  const {
    data: unAssignedUsersListData,
    isLoading: unAssignedUsersListLoading,
    isError: unAssignedUsersListIsError,
    error: unAssignedUsersListError,
    mutate: unAssignedUsersList,
  } = useUnassignedUsersList();

  useEffect(() => {
    try {
      if (unAssignedUsersListData && !unAssignedUsersListLoading) {
      }
      if (unAssignedUsersListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unAssignedUsersListData,
    unAssignedUsersListLoading,
    unAssignedUsersListIsError,
    unAssignedUsersListError,
  ]);

  useEffect(() => {
    if (!unAssignedUsersListLoading) {
      unAssignedUsersList(unAssignedUserQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unAssignedUserQuery]);

  const debouncedFetchBusinessUsers = useDebouncedCallback(
    (value) =>
      setUnAssignedUserQuery({ ...unAssignedUserQuery, search: value }),
    500,
  );

  // assign users
  const {
    data: assignUsersData,
    isLoading: assignUsersLoading,
    isError: assignUsersIsError,
    error: assignUsersError,
    mutate: assignStallToUsers,
  } = useAssignStallToUsers();

  useEffect(() => {
    try {
      if (assignUsersData && !assignUsersLoading) {
        toast.success("Stall assigned successfully");
        setModal(false);
        formik.handleReset();
        setSelectedUser();
        stallsList(query);
        setSearchedEmail();
        setSearchedUser();
      }
      if (assignUsersIsError) {
        toast.error(assignUsersError?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignUsersData, assignUsersLoading, assignUsersIsError]);

  const {
    data: mergeStallsData,
    isLoading: mergeStallsLoading,
    isError: mergeStallsIsError,
    error: mergeStallsError,
    mutate: mergeStalls,
  } = useMergeStalls();

  useEffect(() => {
    if (mergeStallsData && !mergeStallsLoading) {
      toast.success("Stalls merged successfully");
      stallsList(query);
      modalclose();
      setMergeStall1(null);
      setMergeStall2(null);
      mergeFormik.handleReset();
    }
    if (mergeStallsError) {
      console.log(mergeStallsError);
    }
  }, [mergeStallsData, mergeStallsLoading, mergeStallsError]);

  const {
    data: holdStallData,
    isLoading: holdStallLoading,
    isError: holdStallIsError,
    error: holdStallError,
    mutate: holdStall,
  } = useHoldStalls();
  const {
    data: unholdStallData,
    isLoading: unholdStallLoading,
    isError: unholdStallIsError,
    error: unholdStallError,
    mutate: unholdStall,
  } = useUnHoldStalls();
  useEffect(() => {
    if (unholdStallData && !unholdStallLoading) {
      toast.success("Stall Un-Hold successfully");
      stallsList(query);
      modalclose();
    }
  }, [unholdStallData]);
  const {
    data: convertStallData,
    isLoading: convertStallLoading,
    isError: convertStallIsError,
    error: convertStallError,
    mutate: convertStall,
  } = useConvertStall();
  useEffect(() => {
    if (convertStallData && !holdStallLoading) {
      toast.success("Stall hold successfully");
      stallsList(query);
      modalclose();
    }
  }, [convertStallData]);
  const [convertHoldID, setConvertHoldID] = useState(null);
  const [unholdStallID, setUnholdStallID] = useState(null);
  function unholdStallStallSubmit(id) {
    console.log(id, "unholdStallID");
    unholdStall({
      hold_id: id,
    });
  }

  useEffect(() => {
    if (holdStallData && !holdStallLoading) {
      toast.success("Stall hold successfully");
      holdFormik.handleReset();
      setSearchedEmail(null);
      setSearchedUser(null);
      stallsList(query);
      modalclose();
    }
  }, [holdStallData, holdStallLoading, holdStallIsError]);

  const holdInitialValues = {
    stall_id: 0,
    sales_rep_id: user?.id,
    exhibition_id: selectedExhibition?.id,
    for_name: "",
    for_email: "",
    for_phone: "",
    for_business_name: "",
    for_business_address: "",
    for_business_phone: "",
    for_brand_name: "",
    is_registered: false,
  };

  const holdFormik = useFormik({
    initialValues: holdInitialValues,
    values: holdInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      stall_id: Yup.number().required("Required"),
      sales_rep_id: Yup.number().required("Required"),
      exhibition_id: Yup.number().required("Required"),
      for_name: Yup.string().required("Required"),
      for_email: Yup.string().email("Invalid email").required("Required"),
      for_phone: Yup.number().required("Required"),
      for_business_name: Yup.string().required("Required"),
      for_business_address: Yup.string().required("Required"),
      for_business_phone: Yup.number().required("Required"),
      is_registered: Yup.boolean().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values, "submittedValues for hold");
      holdStall(values);
    },
  });

  const mergeInitialValues = {
    name: "",
    price_per_sq_unit: null,
    price_per_sq_m: null,
    state: "empty",
    stall_status: "new",
    type: "bare",
    tags: [],
    length: null,
    width: null,
    // force_approve_comment: null,
    services: [],
    hall_or_dome: "",
  };
  const mergeFormik = useFormik({
    initialValues: mergeInitialValues,
    values: mergeInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      price_per_sq_unit: Yup.number().required("Required"),

      length: Yup.number()
        .min(0, "Length should be positive")
        .required("Required"),
      width: Yup.number()
        .min(0, "Width should be positive")
        .required("Required"),
      state: Yup.string().required("Required").default("empty"),
      stall_status: Yup.string().required("Required").default("doc_sub"),
      type: Yup.string().required("Required").default("bare"),
      tags: Yup.array()
        .required("Required")
        .test("is-greater-1", function (value) {
          const tags = this.resolve(Yup.ref("tags"));
          if (tags?.length < 1) {
            return this.createError({
              message: "At least 1 tag required",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      hall_or_dome: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      mergeStalls({
        exhibition_id: selectedExhibition?.id,
        mergeStall1: mergeStall1.id,
        mergeStall2: mergeStall2.id,
        mergeStallData: values,
      });
    },
  });

  const initialValues = {
    name: "",
    price_per_sq_unit: null,
    price_per_sq_m: null,
    state: "empty",
    stall_status: "new",
    type: "bare",
    tags: [],
    length: null,
    width: null,
    // force_approve_comment: null,
    services: [],
    hall_or_dome: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      price_per_sq_unit: Yup.number().required("Required"),
      length: Yup.number().required("Required"),
      width: Yup.number().required("Required"),
      state: Yup.string().required("Required").default("empty"),
      stall_status: Yup.string().required("Required").default("doc_sub"),
      type: Yup.string().required("Required").default("bare"),
      tags: Yup.array()
        .required("Required")
        .test("is-greater-1", function (value) {
          const tags = this.resolve(Yup.ref("tags"));
          if (tags?.length < 1) {
            return this.createError({
              message: "At least 1 tag required",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      hall_or_dome: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      delete values.viewBtn;
      delete values.state_pill;
      delete values.status_pill;
      delete values.bookBtn;
      delete values.price;
      delete values.dimensions;
      delete values.user_dets;
      delete values.payments;
      delete values.tag_pills;
      delete values.user;
      delete values.holds;
      delete values.service_payments;
      delete values.services;

      if (activeModalStatus === "forceApprove" && formik.values.id) {
        values.stall_status = "approved";
        editStalls(values);
      } else if (!formik.values.id) {
        console.log(values);
        console.log("vals");
        values.booking_date = moment();
        createStalls({
          exhibition_id: selectedExhibition?.id,
          stalls: [values],
        });
      } else {
        console.log("edittinggg");
        editStalls(values);
      }
      setModal(false);
      modalclose();
    },
  });

  const modalclose = () => {
    setActiveModalStatus(null);
    setModal(false);
    setModalIsEdit(false);
    setConvertHoldID(null);
    formik.setValues(initialValues);
  };

  const handleChange = (obj) => {
    setSelectedUser(obj);
  };

  const addStallsModalOpen = () => {
    setModalIsEdit(true);
    setModalIsNewAdd(true);
    modalToogle("add");
  };

  const bulkAddStallsModalOpen = () => {
    modalToogle("bulk");
  };
  const mergeStallModalOpen = () => {
    modalToogle("merge");
  };
  const forceApproveModalOpen = (obj) => {
    modalToogle("forceApprove");
    formik.setValues(obj);
  };
  const [verifyPassword, setVerifyPassword] = useState("");

  const {
    data: loginData,
    isLoading: loginLoading,
    isError: loginIsError,
    error: loginError,
    mutate: login,
  } = useLogin();
  const verifyUser = () => {
    console.log(user.email);
    console.log(verifyPassword);
    const verificationData = new FormData();
    verificationData.append("username", user.email);
    verificationData.append("password", verifyPassword);
    login(verificationData);
  };
  useEffect(() => {
    if (loginError) {
      toast.error("Incorrect Password");
      modalclose();
    }
    if (loginData && !loginLoading) {
      if (convertHoldID) {
        convertStall({
          hold_id: convertHoldID,
          exhibition_id: selectedExhibition.id,
        });
      } else {
        resetStallModalOpen(resetStallId);
      }
      setVerifyPassword("");
    }
  }, [loginData, loginLoading, loginIsError]);

  const assignStallToModalOpen = (e, obj) => {
    e.stopPropagation();
    formik.setValues(obj);
    setModal(true);
    setActiveModalStatus("assignToExhibitor");
    unAssignedUsersList({ current_exhibition: selectedExhibition?.id });
    setSearchedUser();
    setSelectedUser();
  };

  const assignUserSubmit = (obj) => {
    assignStallToUsers({
      user: obj?.id,
      stall_id: formik.values.id,
      exhibition_id: selectedExhibition?.id,
      booking_date: moment(),
    });
  };

  const createUser = () => {
    navigate(`/${user.role}/users`, { state: { add: true } });
  };

  const bulkcsvRef = useRef(null);

  const handleBulkUploadBtn = () => {
    bulkcsvRef.current.click();
  };

  useEffect(() => {
    console.log("formik.values", formik.values);
  }, [formik.values]);

  const [resetStallId, setResetStallId] = useState(null);
  const [searchedUser, setSearchedUser] = useState(null);
  const [searchedEmail, setSearchedEmail] = useState(null);
  const [mergeStall1Options, setMergeStall1Options] = useState([]);
  const [mergeStall2Options, setMergeStall2Options] = useState([]);
  const filterUnSelectedStalls = (stall, mergeNo) => {
    if (mergeNo === 1) {
      setMergeStall2Options(
        mergeStallOptions.filter((item) => item?.value?.id !== stall.id),
      );
    } else {
      setMergeStall1Options(
        mergeStallOptions.filter((item) => item?.value?.id !== stall.id),
      );
    }
  };
  function searchUserForAssign() {
    if (searchedEmail) {
      let foundUser = unAssignedUsersListData.results.filter(
        (user) => user.email === searchedEmail,
      );

      if (foundUser.length !== 0) {
        setSearchedUser(foundUser[0]);
        toast.success("User Found", {
          autoClose: 200,
        });
      } else {
        toast.warning("User not found, Please Create A User First");
      }
    } else {
      toast.warning("Please enter email to search");
    }
  }
  function searchUserForHold() {
    if (searchedEmail) {
      let foundUser = unAssignedUsersListData.results.filter(
        (user) => user.email === searchedEmail,
      );
      if (foundUser.length !== 0) {
        setSearchedUser(foundUser[0]);
        console.log(foundUser);
        holdFormik.setFieldValue("for_name", foundUser[0]?.name);
        holdFormik.setFieldValue("for_email", foundUser[0]?.email);
        holdFormik.setFieldValue("for_phone", foundUser[0]?.phone);
        holdFormik.setFieldValue(
          "for_business_name",
          foundUser[0]?.business_name,
        );
        holdFormik.setFieldValue(
          "for_business_address",
          foundUser[0]?.business_address,
        );
        holdFormik.setFieldValue(
          "for_business_phone",
          foundUser[0]?.business_phone,
        );
        holdFormik.setFieldValue("for_brand_name", foundUser[0]?.brand_name);
        holdFormik.setFieldValue("is_registered", true);
        toast.success("User Found : " + foundUser[0].name, {
          autoClose: 200,
        });
      } else {
        toast.error("User not found");
      }
    } else {
      toast.error("Please enter email to search");
    }
  }
  let modalStatus = {
    convert_reset_stall_confirmation: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              {convertHoldID ? "Convert Stall" : "Reset Stall"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col lg={12} className={'text-center'}>
                <h2>Are you sure you want to {convertHoldID ? "book" : "Reset"}{" "} the Stall?</h2>

              </Col>
              <Col lg={12}>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="success"
                    type="button"
                    onClick={()=>{setActiveModalStatus("password_verify");}}
                  >
                    Yes
                  </Button>
                  <Button
                    className="my-4"
                    color="danger"
                    type="button"
                    onClick={modalclose}
                    >
                    No
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    ),
    password_verify: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              {convertHoldID ? "Convert Stall" : "Reset Stall"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col lg={12}>
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-name">
                    Enter Your Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-text-width"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password *"
                      type="password"
                      name="password"
                      value={verifyPassword}
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg={12}>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={verifyUser}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    ),
    add: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              {formik.values.id
                ? modalIsEdit
                  ? "Edit Stall"
                  : "View Stall"
                : "Add Stall"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Stall Name
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Name *"
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.name && formik.errors.name}
                        disabled={
                          !isEditStallModeOn ||
                          isSalesloggedInUser ||
                          !modalIsEdit
                        }
                      />
                      <FormFeedback>{formik.errors.name}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="input-price_per_sq_unit"
                    >
                      Stall Price sq/
                      {selectedExhibition?.measurement_unit === "sqm"
                        ? "m"
                        : "ft"}
                    </label>
                    <InputGroup className="input-group-alternative">
                      {/*//TODO : Add currency symbol and set it to right side*/}
                      <Input
                        placeholder="price per sq/unit*"
                        type="number"
                        name="price_per_sq_unit"
                        value={formik.values.price_per_sq_unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.price_per_sq_unit &&
                          formik.errors.price_per_sq_unit
                        }
                        disabled={!isEditStallModeOn || !modalIsEdit}
                      />
                      <FormFeedback>
                        {formik.errors.price_per_sq_unit}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3 container-fluid">
                    <Row>
                      <label
                        className="form-control-label"
                        htmlFor="input-dimensions"
                      >
                        Stall Dimensions
                      </label>
                    </Row>
                    <Row className={"w-100 d-inline-flex"}>
                      <Col lg={"4"}>
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="length *"
                            type="number"
                            name="length"
                            value={formik.values?.length}
                            onChange={(e) =>
                              formik.setFieldValue("length", e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched?.length && formik.errors?.length
                            }
                            disabled={
                              !isEditStallModeOn ||
                              isSalesloggedInUser ||
                              !modalIsEdit
                            }
                          />
                          <FormFeedback>{formik.errors?.length}</FormFeedback>
                        </InputGroup>
                      </Col>
                      <Col
                        lg={"1"}
                        className={
                          "d-inline-flex p-2 justify-content-center align-items-center"
                        }
                      >
                        X
                      </Col>
                      <Col lg={"4"}>
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="width *"
                            type="number"
                            name="width"
                            value={formik.values?.width}
                            onChange={(e) => {
                              formik.setFieldValue("width", e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.width && formik.errors.width
                            }
                            disabled={
                              !isEditStallModeOn ||
                              isSalesloggedInUser ||
                              !modalIsEdit
                            }
                          />
                          <FormFeedback>{formik.errors.width}</FormFeedback>
                        </InputGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-state">
                      Stall State
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Stall State *"
                        type="select"
                        name="state"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.state && formik.errors.state}
                        disabled={
                          !isEditStallModeOn ||
                          isSalesloggedInUser ||
                          !modalIsEdit ||
                          modalIsNewAdd
                        }
                      >
                        <option value="empty" selected>
                          Available
                        </option>
                        <option value="on_hold">On Hold</option>
                        <option value="filled">Booked</option>
                      </Input>
                      <FormFeedback>{formik.errors.state}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      Stall Type
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Stall Type *"
                        type="select"
                        name="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.type && formik.errors.type}
                        disabled={
                          !isEditStallModeOn ||
                          isSalesloggedInUser ||
                          !modalIsEdit
                        }
                      >
                        <option value="bare" selected>
                          Bare
                        </option>
                        <option value="shell">Shell</option>
                      </Input>
                      <FormFeedback>{formik.errors.stall_status}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                {/* <Col lg="6">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="input-status"
                  >
                    Status
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="User Role *"
                      type="select"
                      name="stall_status"
                      value={formik.values.stall_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={formik.touched.stall_status && formik.errors.stall_status}
                      disabled={!isEditStallModeOn || true}
                    >
                      <option value="new" selected >New</option>
                      <option value="doc_sub">Document Submisson</option>
                      <option value="doc_approve">Document Approved</option>
                      <option value="doc_reject">Documnet Reject</option>
                      <option value="payment_pending">Payment Pending</option>
                      <option value="approved">Approved</option>
                      <option value="all_ok">All OK</option>
                    </Input>
                    <FormFeedback>
                      {formik.errors.stall_status}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col> */}
              </Row>

              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-tags">
                      Tags
                    </label>
                    <Select
                      isMulti
                      // components={{ DropdownIndicator: null }}
                      options={
                        selectedExhibition?.allowed_tags.map((item) => ({
                          value: item,
                          label: item,
                        })) ?? []
                      }
                      name={"tags"}
                      id={"tags"}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor:
                            formik.touched.tags && Boolean(formik.errors.tags)
                              ? "red"
                              : "grey",
                          height: "100%",
                          minHeight: "40px",
                        }),
                      }}
                      onChange={(value) => {
                        console.log(value);
                        const arrValue = value.map((item) => item.value);
                        formik.setFieldValue("tags", arrValue, true);
                      }}
                      onBlur={formik.handleBlur}
                      aria-errormessage={
                        formik.touched.tags && formik.errors.tags
                      }
                      value={formik.values.tags.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      error={formik.touched.tags && Boolean(formik.errors.tags)}
                      helperText={formik.touched.tags && formik.errors.tags}
                      isDisabled={
                        !isEditStallModeOn ||
                        isSalesloggedInUser ||
                        !modalIsEdit
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-tags">
                      Select Hall/Dome
                    </label>
                    <Select
                      // components={{ DropdownIndicator: null }}
                      options={
                        selectedExhibition?.allowed_hall_or_dome.map(
                          (item) => ({
                            value: item,
                            label: item,
                          }),
                        ) ?? []
                      }
                      name={"hall_or_dome"}
                      id={"hall_or_dome"}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor:
                            formik.touched.hall_or_dome &&
                            Boolean(formik.errors.hall_or_dome)
                              ? "red"
                              : "transparent",
                          height: "100%",
                          minHeight: "40px",
                        }),
                      }}
                      className={"shadow "}
                      onChange={(value) => {
                        console.log(value);

                        formik.setFieldValue("hall_or_dome", value.value, true);
                      }}
                      onBlur={formik.handleBlur}
                      aria-errormessage={
                        formik.touched.hall_or_dome &&
                        formik.errors.hall_or_dome
                      }
                      value={{
                        label: formik.values.hall_or_dome,
                        value: formik.values.hall_or_dome,
                      }}
                      error={
                        formik.touched.hall_or_dome &&
                        Boolean(formik.errors.hall_or_dome)
                      }
                      helperText={
                        formik.touched.hall_or_dome &&
                        formik.errors.hall_or_dome
                      }
                      isDisabled={
                        !modalIsEdit ||
                        !isEditStallModeOn ||
                        isSalesloggedInUser
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {modalIsEdit && (
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
    forceApprove: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Force Approve
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="12">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Any approval comment?
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Approval comment"
                        type="text"
                        name="force_approve_comment"
                        value={formik?.values?.force_approve_comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.force_approve_comment &&
                          formik.errors.force_approve_comment
                        }
                        disabled={
                          !isAdminloggedInUser ||
                          formik?.values?.stall_status !== "payment_pending"
                        }
                      />
                      <FormFeedback>
                        {formik.errors.force_approve_comment}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              {isEditStallModeOn && (
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
    assignToExhibitor: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0 model-card-header">
            <Row className="align-items-center">
              <Col lg="6">
                <h3 className="modal-title" id="modal-title-default">
                  Assign to exhibitor
                </h3>
              </Col>
              <Col lg="6" className="text-right d-flex justify-content-end">
                {/*<InputGroup className="input-group-alternative mr-3 w-75">
                  <Input
                    placeholder="search..."
                    type="text"
                    onChange={(e) => {
                      debouncedFetchBusinessUsers(e.target.value);
                    }}
                  />
                </InputGroup>*/}
                <Button color="primary" onClick={createUser} size="sm">
                  Add Exhibitor
                </Button>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={modalclose}
                >
                  <span aria-hidden={true} className="close-button-icon">
                    ×
                  </span>
                </button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={10}>
                <FormGroup className={"form-inline w-100"}>
                  <label
                    className="form-control-label w-25"
                    htmlFor="input-name"
                  >
                    Book For:
                  </label>
                  <InputGroup className="input-group-alternative w-75">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className={"ni ni-email-83"}></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="User Email *"
                      type="text"
                      name="hold_for"
                      value={searchedEmail}
                      onChange={(e) => {
                        setSearchedEmail(e.target.value);
                      }}

                      // onBlur={formik.handleBlur}
                      // invalid={
                      //   formik.touched.hold_for && formik.errors.hold_for
                      // }
                    />
                    {/*<FormFeedback>{formik.errors.hold_for}</FormFeedback>*/}
                    <InputGroupAddon addonType="append">
                      <Button
                        color={"primary"}
                        className="btn-icon btn-3"
                        type={"button"}
                        onClick={searchUserForAssign}
                      >
                        {" "}
                        <span className="btn-inner--icon">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            {searchedUser && (
              <Row className={"m-3 text-center"}>
                <Col lg={6}>
                  <h5>
                    Name: <h4>{searchedUser.name}</h4>
                  </h5>
                </Col>
                <Col lg={6}>
                  <h5>
                    Email: <h4>{searchedUser.email}</h4>
                  </h5>
                </Col>
                <Col lg={6}>
                  <h5>
                    Phone: <h4>{searchedUser.phone}</h4>
                  </h5>
                </Col>
                <Col lg={6}>
                  <h5>
                    Brand Name: <h4>{searchedUser.brand_name}</h4>
                  </h5>
                </Col>
                <Col lg={6}>
                  <h5>
                    Business Name: <h4>{searchedUser.business_name}</h4>
                  </h5>
                </Col>
                <Col lg={6}>
                  <h5>
                    Business Address: <h4>{searchedUser.business_address}</h4>
                  </h5>
                </Col>
                <Col lg={6}>
                  <h5>
                    Business Phone: <h4>{searchedUser.business_phone}</h4>
                  </h5>
                </Col>
                <Col lg={12}>
                  <Button
                    color={"primary"}
                    onClick={() => {
                      assignUserSubmit(searchedUser);
                    }}
                  >
                    Assign
                  </Button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    ),
    assignToExhibitorOld: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0 model-card-header">
            <Row className="align-items-center">
              <Col lg="6">
                <h3 className="modal-title" id="modal-title-default">
                  Assign to exhibitor
                </h3>
              </Col>
              <Col lg="6" className="text-right d-flex justify-content-end">
                {/*<InputGroup className="input-group-alternative mr-3 w-75">
                  <Input
                    placeholder="search..."
                    type="text"
                    onChange={(e) => {
                      debouncedFetchBusinessUsers(e.target.value);
                    }}
                  />
                </InputGroup>*/}
                <Button color="primary" onClick={createUser} size="sm">
                  Add Exhibitor
                </Button>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={modalclose}
                >
                  <span aria-hidden={true} className="close-button-icon">
                    ×
                  </span>
                </button>
              </Col>
            </Row>
          </CardHeader>
          {unAssignedUsersListData?.results?.length === 0 ? (
            <NoData title="No Users found" />
          ) : (
            <ToolkitProvider
              data={unAssignedUsersListData?.results || []}
              keyField="id"
              columns={[
                {
                  dataField: "action",
                  text: "Action",
                  sort: true,
                  sortCaret: (order, column) => sortCarets(order, column),
                  searchable: false,
                  formatter: (cell, row) => {
                    return (
                      <Button
                        size={"sm"}
                        color={"primary"}
                        onClick={() => {
                          assignUserSubmit(row);
                        }}
                      >
                        Assign
                      </Button>
                    );
                  },
                },
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                  sortCaret: (order, column) => sortCarets(order, column),
                },
                {
                  dataField: "business_name",
                  text: "Business Name",
                  sort: true,
                  sortCaret: (order, column) => sortCarets(order, column),
                  searchable: true,
                },
                {
                  dataField: "phone",
                  text: "Phone",

                  searchable: true,
                  sort: true,
                  sortCaret: (order, column) => sortCarets(order, column),
                },

                {
                  dataField: "email",
                  text: "Email",

                  searchable: true,
                  sort: true,
                  sortCaret: (order, column) => sortCarets(order, column),
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4 px-4 table-responsive">
                  <Container fluid>
                    <Row>
                      <Col xs={12} sm={6}></Col>
                      <Col xs={12} sm={6}>
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1 float-right"
                        >
                          <label>
                            <span>Search:</span>
                            <SearchBar
                              className="form-control-sm ml-2"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <BootstrapTable
                    ref={componentRef1}
                    {...props.baseProps}
                    bootstrap4={true}
                    classes={"mb-3"}
                    pagination={pagination}
                    bordered={true}
                    hover
                    condensed
                    rowStyle={{ textAlign: "center" }}
                    id="react-bs-table"
                  />
                </div>
              )}
            </ToolkitProvider>
          )}
        </Card>
      </div>
    ),
    bulk: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Bulk Upload
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setBulkUploadDocument(null);
                modalclose();
              }}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <div>
              <div className={"row col-12"}>
                <div className="float-left col-6">
                  <div className={" d-inline-flex align-items-end"}>
                    <h2 className={"mr-2"}>Step 1. </h2>
                    <h3>Download the sample file</h3>
                  </div>
                  <div>
                    <h5>Download the sample file and fill the details</h5>
                  </div>
                </div>
                <div className="float-right justify-content-end d-flex align-items-center col-6">
                  <Button
                    color={"primary"}
                    className={" w-50 rounded-pill m-2"}
                    size={"lg"}
                  >
                    {/*TODO Add URL HERE*/}
                    Download
                  </Button>
                </div>
              </div>
            </div>
            <hr className={"hr"} />
            <div>
              <div className={"row col-12"}>
                <div className="float-left col-6">
                  <div className={" d-inline-flex align-items-end"}>
                    <h2 className={"mr-2"}>Step 2. </h2>
                    <h3>Upload Your CSV</h3>
                  </div>
                  <div>
                    <h5>
                      Upload your CSV file , make sure it is in the same format
                      as the sample csv
                    </h5>
                  </div>
                </div>
                <div className="float-right justify-content-end d-flex align-items-center col-6">
                  <Button
                    color={bulkUploadDocument ? "success" : "primary"}
                    className={" w-50 rounded-pill m-2"}
                    size={"lg"}
                    onClick={handleBulkUploadBtn}
                  >
                    {bulkUploadDocument
                      ? `Selected ${bulkUploadDocument.name}`
                      : "Upload"}
                  </Button>
                  <input
                    type={"file"}
                    className={"d-none"}
                    ref={bulkcsvRef}
                    name="doc_file"
                    accept=".csv"
                    onChange={(e) => {
                      setBulkUploadDocument(e.target.files[0]);
                      console.log(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-between">
              <Button
                onClick={uploadDocumnet}
                isLoading={bulkAddStallsLoading}
                className={"rounded-pill"}
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    ),
    merge: (
      <div className={"modal-body p-0"}>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0 model-card-header d-inline-flex justify-content-between">
            <h3 className="modal-title" id="modal-title-default">
              Merge Stalls
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                modalclose();
                setMergeStall1();
                setMergeStall2();
              }}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </CardHeader>
          <CardBody className={"p-5  text-center"}>
            <Row>
              <Col lg={"5"} className={"card p-4 shadow-lg "}>
                <h3>Stall 1</h3>
                <FormGroup>
                  <label>Selected Stall</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="mergestall1"
                    onChange={(value) => {
                      setMergeStall1(value.value);
                    }}
                    value={{ value: mergeStall1, label: mergeStall1?.name }}
                    options={
                      mergeStall2
                        ? mergeStallOptions.filter(
                            (stall) => stall.value?.id !== mergeStall2?.id,
                          )
                        : mergeStallOptions
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <h5>Dimensions : {mergeStall1?.dimensions}</h5>
                  <h5>
                    Price Per {selectedExhibition?.measurement_unit} :{" "}
                    {mergeStall1?.price_per_sq_unit} Rs
                  </h5>
                </FormGroup>
              </Col>
              <Col
                lg={"2"}
                className={"d-flex justify-content-center align-items-center"}
              >
                <h3>+</h3>
              </Col>
              <Col lg={"5"} className={"card p-4 shadow-lg "}>
                <h3>Stall 2</h3>
                <FormGroup>
                  <label>Selected Stall</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="mergeStall2"
                    onChange={(value) => {
                      setMergeStall2(value.value);
                    }}
                    value={{ value: mergeStall2, label: mergeStall2?.name }}
                    options={
                      mergeStall1
                        ? mergeStallOptions.filter(
                            (stall) => stall.value?.id !== mergeStall1?.id,
                          )
                        : mergeStallOptions
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <h5>Dimensions : {mergeStall2?.dimensions}</h5>
                  <h5>
                    Price Per {selectedExhibition?.measurement_unit} :{" "}
                    {mergeStall2?.price_per_sq_unit} Rs
                  </h5>
                </FormGroup>
              </Col>
              <Col lg={"12"} className={"card mt-5 shadow-lg p-3"}>
                <div>
                  <h3>Merged Stall</h3>
                  <div className={"p-3"}>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Stall Name
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Name *"
                              type="text"
                              name="name"
                              value={mergeFormik.values.name}
                              onChange={mergeFormik.handleChange}
                              onBlur={mergeFormik.handleBlur}
                              invalid={
                                mergeFormik.touched.name &&
                                mergeFormik.errors.name
                              }
                            />
                            <FormFeedback>
                              {mergeFormik.errors.name}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-price_per_sq_unit"
                          >
                            Stall Price sq/
                            {selectedExhibition?.measurement_unit === "sqm"
                              ? "m"
                              : "ft"}
                          </label>
                          <InputGroup className="input-group-alternative">
                            {/*//TODO : Add currency symbol and set it to right side*/}
                            <Input
                              placeholder="price sq/ unit *"
                              type="number"
                              name="price_per_sq_unit"
                              value={mergeFormik.values?.price_per_sq_unit}
                              onChange={mergeFormik.handleChange}
                              onBlur={mergeFormik.handleBlur}
                              invalid={
                                mergeFormik.touched?.price_per_sq_unit &&
                                mergeFormik.errors?.price_per_sq_unit
                              }
                            />
                            <FormFeedback>
                              {mergeFormik.errors?.price_per_sq_unit}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3 container-fluid">
                          <Row>
                            <label
                              className="form-control-label"
                              htmlFor="input-dimensions"
                            >
                              Merge Stall Dimensions
                            </label>
                          </Row>
                          {/*<Row>
                            <div className="custom-control custom-radio mb-3 mx-2">
                              <input
                                className="custom-control-input"
                                id="merge_length"
                                name="mergeStallDimensions"
                                type="radio"
                                value={"merge_length"}
                                onChange={(e) => {
                                  console.log("e", e.target.value);
                                  console.log("mergeStall1", mergeStall1);
                                  mergeFormik.setFieldValue(
                                    "length",
                                    mergeStall1?.length + mergeStall2?.length,
                                  );
                                  mergeFormik.setFieldValue(
                                    "width",
                                    mergeStall1?.width,
                                  );
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="merge_length"
                              >
                                Length
                              </label>
                            </div>
                            <div className="custom-control custom-radio mx-2">
                              <input
                                className="custom-control-input"
                                id="merge_width"
                                name="mergeStallDimensions"
                                type="radio"
                                value={"merge_width"}
                                onChange={(e) => {
                                  if (e.target.value === "merge_width") {
                                    mergeFormik.setFieldValue(
                                      "length",
                                      mergeStall1?.length,
                                    );
                                    mergeFormik.setFieldValue(
                                      "width",
                                      mergeStall1?.width + mergeStall2?.width,
                                    );
                                  }
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="merge_width"
                              >
                                Width
                              </label>
                            </div>
                          </Row>*/}
                          <Row className={"w-100 d-inline-flex"}>
                            <Col lg={"5"}>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="length  sq/m*"
                                  type="number"
                                  name="length"
                                  min={0}
                                  value={mergeFormik.values?.length}
                                  onChange={(e) =>
                                    mergeFormik.setFieldValue(
                                      "length",
                                      e.target.value,
                                    )
                                  }
                                  onBlur={mergeFormik.handleBlur}
                                  invalid={
                                    mergeFormik.touched?.length &&
                                    mergeFormik.errors.length
                                  }
                                />
                                <FormFeedback>
                                  {mergeFormik.errors?.length}
                                </FormFeedback>
                              </InputGroup>
                              <span>L</span>
                            </Col>
                            <Col
                              lg={"2"}
                              className={
                                "d-inline-flex p-2 justify-content-center align-items-center"
                              }
                            >
                              X
                            </Col>
                            <Col lg={"5"}>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="width *"
                                  type="number"
                                  name="width"
                                  min={0}
                                  value={mergeFormik.values?.width}
                                  onChange={(e) => {
                                    mergeFormik.setFieldValue(
                                      "width",
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={mergeFormik.handleBlur}
                                  invalid={
                                    mergeFormik.touched.width &&
                                    mergeFormik.errors.width
                                  }
                                />
                                <FormFeedback>
                                  {mergeFormik.errors.width}
                                </FormFeedback>
                              </InputGroup>
                              <span>W</span>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-state"
                          >
                            Stall State
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Stall State *"
                              type="select"
                              name="state"
                              value={mergeFormik.values.state}
                              onChange={mergeFormik.handleChange}
                              onBlur={mergeFormik.handleBlur}
                              invalid={
                                mergeFormik.touched.state &&
                                mergeFormik.errors.state
                              }
                            >
                              <option value="empty" selected>
                                Available
                              </option>
                              <option value="on_hold">On Hold</option>
                              <option value="filled">Booked</option>
                            </Input>
                            <FormFeedback>
                              {mergeFormik.errors.state}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-status"
                          >
                            Stall Type
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Stall Type *"
                              type="select"
                              name="type"
                              value={mergeFormik.values.type}
                              onChange={mergeFormik.handleChange}
                              onBlur={mergeFormik.handleBlur}
                              invalid={
                                mergeFormik.touched.type &&
                                mergeFormik.errors.type
                              }
                            >
                              <option value="bare" selected>
                                Bare
                              </option>
                              <option value="shell">Shell</option>
                            </Input>
                            <FormFeedback>
                              {mergeFormik.errors.stall_status}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>*/}

                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-tags"
                          >
                            Tags
                          </label>
                          <Createable
                            isMulti
                            // components={{ DropdownIndicator: null }}
                            options={
                              selectedExhibition?.allowed_tags.map((item) => ({
                                value: item,
                                label: item,
                              })) ?? []
                            }
                            name={"tags"}
                            id={"tags"}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor:
                                  mergeFormik.touched.tags &&
                                  Boolean(mergeFormik.errors.tags)
                                    ? "red"
                                    : "grey",
                                height: "100%",
                                minHeight: "40px",
                              }),
                            }}
                            onChange={(value) => {
                              console.log(value);
                              const arrValue = value.map((item) => item.value);
                              mergeFormik.setFieldValue("tags", arrValue, true);
                            }}
                            onBlur={mergeFormik.handleBlur}
                            aria-errormessage={
                              mergeFormik.touched.tags &&
                              mergeFormik.errors.tags
                            }
                            value={mergeFormik.values.tags.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            error={
                              mergeFormik.touched.tags &&
                              Boolean(mergeFormik.errors.tags)
                            }
                            helperText={
                              mergeFormik.touched.tags &&
                              mergeFormik.errors.tags
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-tags"
                          >
                            Select Hall/Dome
                          </label>
                          <Createable
                            // components={{ DropdownIndicator: null }}
                            options={
                              selectedExhibition?.allowed_hall_or_dome.map(
                                (item) => ({
                                  value: item,
                                  label: item,
                                }),
                              ) ?? []
                            }
                            name={"hall_or_dome"}
                            id={"hall_or_dome"}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor:
                                  mergeFormik.touched?.hall_or_dome &&
                                  Boolean(mergeFormik.errors?.hall_or_dome)
                                    ? "red"
                                    : "transparent",
                                height: "100%",
                                minHeight: "40px",
                              }),
                            }}
                            className={"shadow "}
                            onChange={(value) => {
                              console.log(value);
                              mergeFormik.setFieldValue(
                                "hall_or_dome",
                                value.value,
                                true,
                              );
                            }}
                            onBlur={mergeFormik.handleBlur}
                            aria-errormessage={
                              mergeFormik.touched?.hall_or_dome &&
                              mergeFormik.errors.hall_or_dome
                            }
                            value={{
                              label: mergeFormik.values.hall_or_dome,
                              value: mergeFormik.values.hall_or_dome,
                            }}
                            error={
                              mergeFormik.touched?.hall_or_dome &&
                              Boolean(mergeFormik.errors.hall_or_dome)
                            }
                            helperText={
                              mergeFormik.touched?.hall_or_dome &&
                              mergeFormik.errors.hall_or_dome
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col
                lg={"12"}
                className={
                  "d-flex mt-5 justify-content-center align-items-center"
                }
              >
                <Button color={"primary"} onClick={mergeFormik.submitForm}>
                  Merge
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    ),
    holdOld: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0 model-card-header d-inline-flex justify-content-between">
            <h3 className="modal-title" id="modal-title-default">
              Hold Stall
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </CardHeader>
          <CardBody className={"p-3  text-center"}>
            <Row>
              <Col lg="12" className={"text-center"}>
                <h3>Are you sure you want to hold this stall?</h3>
              </Col>
              <Col lg="12" className={"d-flex justify-content-center"}>
                <Button
                  color="danger"
                  onClick={() => {
                    handleHoldStall(formik.values.id);
                  }}
                >
                  Yes
                </Button>
                <Button color="primary" onClick={modalclose}>
                  No
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    ),
    hold: (
      <div className={"modal-body p-0"}>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0 model-card-header d-inline-flex justify-content-between">
            <h3 className="modal-title" id="modal-title-default">
              Hold Stall
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </CardHeader>
          <CardBody className={"p-5 text-center"}>
            <Row>
              <Col lg={12} className={""}>
                <FormGroup className={"form-inline w-100"}>
                  <label
                    className="form-control-label w-25"
                    htmlFor="input-name"
                  >
                    Hold For:
                  </label>
                  <InputGroup className="input-group-alternative w-75">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className={"ni ni-email-83"}></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="User Email *"
                      type="text"
                      name="hold_for"
                      value={searchedEmail}
                      onChange={(e) => {
                        setSearchedEmail(e.target.value);
                      }}

                      // onBlur={formik.handleBlur}
                      // invalid={
                      //   formik.touched.hold_for && formik.errors.hold_for
                      // }
                    />
                    {/*<FormFeedback>{formik.errors.hold_for}</FormFeedback>*/}
                    <InputGroupAddon addonType="append">
                      <Button
                        color={"primary"}
                        className="btn-icon btn-3"
                        type={"button"}
                        onClick={searchUserForHold}
                      >
                        {" "}
                        <span className="btn-inner--icon">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col
                lg={12}
                className={"d-flex justify-content-center align-content-around"}
              >
                <h3>Or</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_name"
                    >
                      Name
                    </label>
                    <Input
                      placeholder={"Name"}
                      type="text"
                      name="for_name"
                      value={holdFormik.values.for_name}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_name &&
                        holdFormik.errors.for_name
                      }
                    />
                    <FormFeedback>{holdFormik.errors.for_name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_email"
                    >
                      Email
                    </label>
                    <Input
                      placeholder={"Email"}
                      type="text"
                      name="for_email"
                      value={holdFormik.values.for_email}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_email &&
                        holdFormik.errors.for_email
                      }
                    />
                    <FormFeedback>{holdFormik.errors.for_email}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_phone"
                    >
                      Phone
                    </label>
                    <Input
                      placeholder={"phone"}
                      type="text"
                      name="for_phone"
                      value={holdFormik.values.for_phone}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_phone &&
                        holdFormik.errors.for_phone
                      }
                    />
                    <FormFeedback>{holdFormik.errors.for_phone}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_brand_name"
                    >
                      Brand Name
                    </label>
                    <Input
                      placeholder={"Brand Name"}
                      type="text"
                      name="for_brand_name"
                      value={holdFormik.values.for_brand_name}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_brand_name &&
                        holdFormik.errors.for_brand_name
                      }
                    />
                    <FormFeedback>
                      {holdFormik.errors.for_brand_name}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_business_name"
                    >
                      Business Name
                    </label>
                    <Input
                      placeholder={"Business Name"}
                      type="text"
                      name="for_business_name"
                      value={holdFormik.values.for_business_name}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_business_name &&
                        holdFormik.errors.for_business_name
                      }
                    />
                    <FormFeedback>
                      {holdFormik.errors.for_business_name}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_business_address"
                    >
                      Business Address
                    </label>
                    <Input
                      placeholder={"Business Address"}
                      type="text"
                      name="for_business_address"
                      value={holdFormik.values.for_business_address}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_business_address &&
                        holdFormik.errors.for_business_address
                      }
                    />
                    <FormFeedback>
                      {holdFormik.errors.for_business_address}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-for_business_phone"
                    >
                      Business Phone
                    </label>
                    <Input
                      placeholder={"Business Phone"}
                      type="text"
                      name="for_business_phone"
                      value={holdFormik.values.for_business_phone}
                      onChange={holdFormik.handleChange}
                      onBlur={holdFormik.handleBlur}
                      invalid={
                        holdFormik.touched.for_business_phone &&
                        holdFormik.errors.for_business_phone
                      }
                    />
                    <FormFeedback>
                      {holdFormik.errors.for_business_phone}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className={"d-flex justify-content-center"}>
                  <Button
                    color={"primary"}
                    onClick={holdFormik.submitForm}
                    className={"rounded-pill"}
                  >
                    Hold
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
  };

  const handleHoldStall = (stall) => {
    console.log("hold", stall);
    editStalls({ id: stall, state: "on_hold", on_hold_date: moment() });
    modalclose();
  };

  const editStallModalOpen = (obj) => {
    console.log(obj);
    setModalIsEdit(true);
    modalToogle("add");
    formik.setValues(obj);
  };

  const viewStallModalOpen = (obj) => {
    modalToogle("add");
    console.log(obj);
    setModalIsEdit(false);
    formik.setValues(obj);
  };

  const holdStallModalOpen = (e, obj) => {
    e.stopPropagation();
    modalToogle("hold");
    console.log(obj);
    holdFormik.setFieldValue("stall_id", obj.id);
  };

  let default_unit =
    selectedExhibition?.measurement_unit === "sqm" ? "Sq M" : "Sq Ft";

  const {
    data: resetStallData,
    error: resetStallError,
    loading: resetStallLoading,
    mutate: resetStall,
  } = useResetStall();
  useEffect(() => {
    if (resetStallData) {
      stallsList({ exhibition_id: selectedExhibition.id });
      modalclose();
    }
  }, [resetStallData, resetStallError, resetStallLoading]);
  const resetStallModalOpen = (reset_id) => {
    resetStall({ exhibition_id: selectedExhibition.id, stall_id: reset_id });
  };

  if (stallsListData) {
    stallsListData.results.map((item, index) => {
      let isHolder = item?.holds[0]?.sales_rep_id === user?.id;
      console.log(isHolder, item?.holds[0]?.sales_rep_id, user?.id, "user ");
      if (item?.state === "empty") {
        item.bookBtn = (
          <ButtonGroup>
            <Button
              color="primary"
              onClick={(e) => assignStallToModalOpen(e, item)}
              size="sm"
              disabled={item?.state === "on_hold" || item?.state === "filled"}
            >
              Book now
            </Button>
            <Button
              color="warning"
              onClick={(e) => holdStallModalOpen(e, item)}
              size="sm"
              disabled={item?.state === "on_hold" || item?.state === "filled"}
            >
              Hold now
            </Button>
          </ButtonGroup>
        );
      } else {
        if (item?.state !== "on_hold" && isAdminloggedInUser) {
          item.bookBtn = (
            <ButtonGroup>
              <Button
                color="danger"
                onClick={() => {
                  modalToogle("password_verify");
                  setResetStallId(item.id);
                }}
                size="sm"
              >
                Reset Stall
              </Button>
            </ButtonGroup>
          );
        } else if (item?.state === "on_hold" && isHolder) {
          item.bookBtn = (
            <ButtonGroup>
              <Button
                color="danger"
                onClick={() => {
                  modalToogle("password_verify");
                  setResetStallId(item.id);
                }}
                size="sm"
              >
                Reset Stall
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  unholdStallStallSubmit(item.holds[0]?.id);
                  toast.success("Stall is converted successfully");
                }}
                size="sm"
              >
                Unhold Stall
              </Button>

              <Button
                color="success"
                onClick={() => {
                  setConvertHoldID(item.holds[0]?.id);
                  modalToogle("convert_reset_stall_confirmation");
                }}
                size="sm"
              >
                Convert Stall
              </Button>
            </ButtonGroup>
          );
        } else {
          item.bookBtn = <></>;
        }
      }

      item.tag_pills = item.tags.map((tag) => (
        <Badge
          className="badge-lg text-white mx-1 bg-info"
          style={{ fontSize: "10px" }}
        >
          {tag}
        </Badge>
      ));
      let userTT = "tooltip" + index;
      if (item.user) {
        item.user_dets = (
          <>
            <span id={userTT}>{item.user.name}</span>

            <UncontrolledPopover placement="top" target={userTT}>
              <PopoverBody>
                User: {item?.user?.name}
                <br />
                Brand Name : {item?.user?.brand_name}
                <br />
                Business Name : {item?.user?.business_name}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        );
      }
      if (isAdminloggedInUser) {
        item.viewBtn = (
          <ButtonGroup>
            <Button
              color="success"
              onClick={(e) => viewStallModalOpen(item)}
              size="sm"
            >
              View
            </Button>
            <Button
              color="primary"
              onClick={(e) => editStallModalOpen(item)}
              size="sm"
            >
              Edit
            </Button>
            {item.stall_status === "payment_pending" && (
              <Button
                color="danger"
                onClick={(e) => forceApproveModalOpen(item)}
                size="sm"
              >
                Force approve
              </Button>
            )}
          </ButtonGroup>
        );
      } else {
        item.viewBtn = (
          <ButtonGroup>
            <Button
              color="success"
              onClick={(e) => viewStallModalOpen(item)}
              size="sm"
            >
              View
            </Button>
            {/*<Button
              color="primary"
              onClick={(e) => editStallModalOpen(item)}
              size="sm"
            >
              Edit
            </Button>*/}
          </ButtonGroup>
        );
      }
      item.state_pill = (
        <Badge
          className={`${stallStates_bg[item.state]} badge-lg text-white`}
          style={{ fontSize: "10px" }}
        >
          {stallStates[item.state]}
        </Badge>
      );
      item.status_pill = (
        <Badge
          className={`${stallStatues_color[item.stall_status]}   badge-lg text-white`}
          style={{ fontSize: "10px" }}
        >
          {stallStatues[item.stall_status]}
        </Badge>
      );
      item.price =
        parseInt(item.price_per_sq_unit) *
        parseInt(item.width) *
        parseInt(item.length);
      item.dimensions = `${item.width} x ${item.length} ${default_unit}`;
    });
  }
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const customTextSort = (a, b, order, dataA, dataB) => {
    if (order === "asc") {
      return dataA.localeCompare(dataB);
    }
    return dataB.localeCompare(dataA);
  };

  return (
    <>
      <AdminHeader />
      <Container className={"mt--5"} fluid>
        <Row className={"justify-content-center"}>
          <Col lg={5} className={"m-2"}>
            <Card className={"p-4 shadow shadow-lg text-center"}>
              <h4>Total</h4>
              <h5>{stallsListData && stallsListData.results.length}</h5>
            </Card>
          </Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col lg={3} className={"m-2"}>
            <Card className={"p-4 shadow shadow-lg text-center"}>
              <h4>Available</h4>
              <h5>
                {stallsListData &&
                  stallsListData.results.filter(
                    (stall) => stall.state === "empty",
                  ).length}
              </h5>
            </Card>
          </Col>

          <Col lg={4} className={"m-2"}>
            <Card className={"p-4 shadow shadow-lg text-center"}>
              <h4>On Hold</h4>
              <h5>
                {stallsListData &&
                  stallsListData.results.filter(
                    (stall) => stall.state === "on_hold",
                  ).length}
              </h5>
            </Card>
          </Col>
          <Col lg={3} className={"m-2"}>
            <Card className={"p-4 shadow   shadow-lg text-center"}>
              <h4>Booked</h4>
              <h5>
                {stallsListData &&
                  stallsListData.results.filter(
                    (stall) => stall.state === "filled",
                  ).length}
              </h5>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5" fluid>
        {!selectedExhibition && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <NoData title="For stalls, Please set up an exhibition." />
              </Card>
            </div>
          </Row>
        )}
        {selectedExhibition && (
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Stalls</h3>
                    </Col>

                    {isAdminloggedInUser && (
                      <Col className="text-right" xs="4">
                        <Button
                          color="primary"
                          onClick={mergeStallModalOpen}
                          size="sm"
                          className={"mr-2"}
                        >
                          Merge Stalls
                        </Button>
                        <Button
                          color="primary"
                          onClick={bulkAddStallsModalOpen}
                          size="sm"
                          className={"mr-2"}
                        >
                          Bulk Upload
                        </Button>
                        <Button
                          color="primary"
                          onClick={addStallsModalOpen}
                          size="sm"
                        >
                          Add
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                {stallsListData?.results?.length === 0 ? (
                  <NoData title="No data found" />
                ) : (
                  <ToolkitProvider
                    data={stallsListData?.results || []}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "user_dets",
                        text: "User",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "hall_or_dome",
                        text: "Hall",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        searchable: false,
                      },

                      {
                        dataField: "type",
                        text: "Type",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        searchable: true,
                      },
                      {
                        dataField: "price_per_sq_unit",
                        text: "Price",

                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },

                      {
                        dataField: "dimensions",
                        text: "Dimensions",

                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },

                      {
                        dataField: "tags",
                        text: "Tags",
                        hidden: true,
                        sort: false,
                        searchable: true,
                      },
                      {
                        dataField: "tag_pills",
                        text: "Tags",
                        sort: false,
                        searchable: true,
                      },
                      {
                        dataField: "state_pill",
                        text: "State",
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        sortFunc: (a, b, order, dataField, rowA, rowB) =>
                          customTextSort(
                            a,
                            b,
                            order,
                            rowA.stall_state,
                            rowB.stall_state,
                          ),
                      },

                      {
                        dataField: "status_pill",
                        text: "Status",

                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                        sortFunc: (a, b, order, dataField, rowA, rowB) =>
                          customTextSort(
                            a,
                            b,
                            order,
                            rowA.stall_status,
                            rowB.stall_status,
                          ),
                      },
                      {
                        dataField: "bookBtn",
                        text: "Action",
                        sort: false,

                        searchable: false,
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "viewBtn",
                        text: "View",

                        searchable: false,
                        sort: false,
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          defaultSorted={defaultSorted}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </Col>
          </Row>
        )}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={modal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default Stalls;
