import React, { useRef, useEffect, useState } from "react";
import { Stage, Layer, Rect, Text, Image as KonvaImage } from "react-konva";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import useImage from "use-image";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
} from "reactstrap";
import { toCanvas, toPng } from "html-to-image";
import logo from "../../assets/img/icons/common/logo.svg";
import ReactDOM from "react-dom";
import generatePDF, { Margin, usePDF } from "react-to-pdf";

const ExhibitionTicket = ({ selectedExhibition, item, exhibLogo }) => {
  const [image] = useImage(exhibLogo);
  const [selectedTicket, setSelectedTicket] = useState("");
  const ticketRef = useRef(null);
  const [hypx_logo] = useImage(logo);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    ReactDOM.render(
      <QRCodeSVG
        value={item.uuid}
        size={100}
        level={"H"}
        renderAs={"canvas"}
      />,
      tempDiv,
      () => {
        console.log(tempDiv.firstChild, "qr code url");
        // setQrCodeUrl(tempDiv.firstChild);
        const qr = tempDiv.firstChild;
        toPng(qr).then((dataUrl) => {
          console.log(dataUrl, "data url");
          // setQrCodeUrl(qrImg);
          const qrImg = new Image();
          qrImg.src = dataUrl;
          qrImg.onload = () => {
            setQrCodeUrl(qrImg);
          };
        });
        tempDiv.remove();
      },
    );
  }, [item.uuid]);

  const onButtonClick = () => {
    const dataURL = ticketRef.current.toDataURL();

    const link = document.createElement("a");
    link.download = `${selectedTicket}.png`;
    link.href = dataURL;
    link.click();
    generatePDF(() => document.getElementById("ticketElem" + item.uuid), {
      method: "save",
      filename: item.ticket_name + ".pdf",
      page: {
        // format: "letter",
        unit: "px",
        format: [100, 140],
        orientation: "landscape",
      },
    });
  };
  //add height and loop over
  return (
    <Col xs={12} sm={12} md={11} lg={6}>
      <Card>
        <CardHeader>
          <h4 className={"text-center"}>{item.ticket_name}</h4>
        </CardHeader>
        <CardBody style={{ overflow: "scroll" }} className={"d-flex"}>
          <div>
            <Stage
              width={600}
              height={400}
              ref={ticketRef}
              id={"ticketElem" + item.uuid}
            >
              <Layer>
                {/* Draw border */}
                <Rect
                  x={0}
                  y={0}
                  width={600}
                  height={400}
                  fill="white"
                  stroke="black"
                  strokeWidth={2}
                  cornerRadius={25}
                />

                {/* Add exhibition name and date */}
                <Text
                  x={50}
                  y={30}
                  text={selectedExhibition.name}
                  fontSize={24}
                  fontStyle="bold"
                />

                <Text
                  x={50}
                  y={60}
                  text={`${selectedExhibition.start_date} - ${selectedExhibition.end_date}`}
                  fontSize={20}
                />
                <Text
                  x={50}
                  y={85}
                  text={`${selectedExhibition.start_time} - ${selectedExhibition.end_time}`}
                  fontSize={16}
                />
                {/* Add exhibition logo */}
                {image && (
                  <KonvaImage
                    image={image}
                    x={320}
                    y={30}
                    width={100}
                    height={100}
                  />
                )}

                {/* Add placeholder image */}
                {qrCodeUrl && (
                  <KonvaImage
                    image={qrCodeUrl}
                    x={450}
                    y={30}
                    width={100}
                    height={100}
                  />
                )}
                <Text
                  x={50}
                  y={156}
                  text="Ticket Type:"
                  fontSize={20}
                  fontStyle="bold"

                />
                <Text x={50} y={180} text={item.ticket_name} fontSize={20}
                      width={140}
                      wrap="word"/>

                {/* Add entry limit */}
                <Text
                  x={200}
                  y={156}
                  text="Entry Limit:"
                  fontSize={20}
                  fontStyle="bold"
                />
                <Text
                  x={200}
                  y={180}
                  text={item.constraints.times===0 ? "None":item.constraints.times+" Days"}
                  fontSize={20}
                />

                {/* Add price */}
                <Text
                  x={50}
                  y={230}
                  text="Price:"
                  fontSize={20}
                  fontStyle="bold"
                />
                <Text
                  x={50}
                  y={255}
                  text={`Rs. ${item.price} /-`}
                  fontSize={20}
                />

                {/* Add locations */}
                <Text
                  x={200}
                  y={230}
                  text="Locations:"
                  fontSize={20}
                  fontStyle="bold"
                />
                <Text
                  x={200}
                  y={255}
                  text={item.constraints.location.join(", \n")}
                  fontSize={20}
                  width={150}
                  wrap="word"
                />

                {/* Add exhibition venue address */}
                <Text
                  x={400}
                  y={230}
                  text={"Venue:"}
                  fontSize={20}
                  fontStyle={"bold"}
                />
                <Text
                  x={400}
                  y={255}
                  text={selectedExhibition.venue}
                  fontSize={20}
                />
                <Text
                  x={400}
                  y={280}
                  text={selectedExhibition.address}
                  fontSize={18}
                  width={150}
                  wrap="word"
                />
              </Layer>
            </Stage>
          </div>
        </CardBody>
        <CardFooter className={""}>
          <Button
            color={"primary"}
            className={"w-100"}
            onClick={() => {
              // setOpenCreateVisitorsModal(true);
              setSelectedTicket(item.ticket_name);
              onButtonClick();
            }}
          >
            Download
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default ExhibitionTicket;
