import Darkmode from "components/Darkmode";
import { Link } from "react-router-dom";
// reactstrap components
import { Col, Container, Navbar, Row } from "reactstrap";

const PublicNavbar = (props) => {
  return (
    <Navbar
      className="navbar-top navbar-horizontal navbar-dark w-100"
      expand="md"
    >
      <Container className="px-4">
        <Row className={"w-100"}>
          <img
            alt="..."
            width={100}
            src={require("../../assets/img/icons/common/logo.svg").default}
          />
        </Row>
      </Container>
    </Navbar>
  );
};

export default PublicNavbar;
