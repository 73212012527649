import { baseURL } from '../../../../../config';

export const dashboardRoutes = {
  dashboard: {
    get: {
      query: 'GET_ADMIN_DASHBOARD',
      method: 'GET',
      url: `${baseURL}/api/dashboard`,
    },
  },
};
