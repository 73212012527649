import { baseURL } from "../../../../../config";

export const stallsRoutes = {
  stalls: {
    reset: {
      query: "RESET_STALLS",
      method: "POST",
      url: `${baseURL}/api/stalls/reset/`,
    },
    post: {
      query: "CREATE_STALLS",
      method: "POST",
      url: `${baseURL}/api/create-stalls`,
    },
    put: {
      query: "EDIT_STALLS",
      method: "PATCH",
      url: `${baseURL}/api/stall`,
    },
    get: {
      query: "GET_STALLS",
      method: "GET",
      url: `${baseURL}/api/user/stalls`,
    },
    assignStallTo: {
      query: "ASSIGN_STALLS_TO_USER",
      method: "POST",
      url: `${baseURL}/api/stall_user_mapping`,
    },
    bulk: {
      query: "ADD_BULK_STALL",
      method: "POST",
      url: `${baseURL}/api/stall/csv`,
    },
  },
  document: {
    get: {
      query: "GET_DOCUMENT_OF_STALL",
      method: "GET",
      url: `${baseURL}/api/documents`,
    },
    post: {
      query: "POST_DOCUMENT_OF_STALL",
      method: "POST",
      url: `${baseURL}/api/documents`,
    },
    approve: {
      query: "APPROVE_DOCUMENT",
      method: "PATCH",
      url: `${baseURL}/api/documents/approve/`,
    },
  },
  comment: {
    get: {
      query: "GET_COMMENTS_OF_DOCUMENT",
      method: "GET",
      url: `${baseURL}/api/comments`,
    },
    post: {
      query: "POST_COMMENT_OF_STALL",
      method: "POST",
      url: `${baseURL}/api/comments`,
    },
  },
  participation: {
    get: {
      query: "GET_PARTICIPATION_OF_STALL",
      method: "POST",
      url: `${baseURL}/api/participation_letter`,
    },
  },
  possession: {
    get: {
      query: "GET_possession_OF_STALL",
      method: "POST",
      url: `${baseURL}/api/possession_letter`,
    },
  },
  payment: {
    get: {
      query: "GET_PAYMENT_OF_STALL",
      method: "GET",
      url: `${baseURL}/api/payment`,
    },
    create: {
      query: "POST_PAYMENT_OF_STALL",
      method: "POST",
      url: `${baseURL}/api/payment`,
    },
    update: {
      query: "APPROVE_PAYMENT",
      method: "PATCH",
      url: `${baseURL}/api/payment/`,
    },
    delete: {
      query: "DELETE_PAYMENT",
      method: "DELETE",
      url: `${baseURL}/api/payment/`,
    },
  },
  pendingPaymentStall: {
    get: {
      query: "GET_PENDING_PAYMENT_STALL",
      method: "GET",
      url: `${baseURL}/api/user/stalls`,
    },
  },
  service: {
    create: {
      query: "POST_SERVICE",
      method: "POST",
      url: `${baseURL}/api/stalls/service`,
    },
    payment: {
      query: "POST_PAYMENT_SERVICE",
      method: "POST",
      url: `${baseURL}/api/servicepayment`,
    },
    paymentAction: {
      query: "POST_PAYMENT_SERVICE",
      method: "PATCH",
      url: `${baseURL}/api/servicepayment/`,
    },
  },
  merge: {
    query: "MERGE_STALLS",
    method: "POST",
    url: `${baseURL}/api/stalls/merge`,
  },
  hold: {
    holdStall: {
      query: "HOLD_STALLS",
      method: "POST",
      url: `${baseURL}/api/stalls/hold`,
    },
    getStallHold: {
      query: "GET_STALL_HOLD",
      method: "GET",
      url: `${baseURL}/api/stalls/hold/`,
    },
    releaseStallHold: {
      query: "RELEASE_STALL_HOLD",
      method: "DELETE",
      url: `${baseURL}/api/stalls/hold/`,
    },
    getHoldStalls: {
      query: "GET_HOLD_STALLS",
      method: "GET",
      url: `${baseURL}/api/stalls/hold`,
    },
    convert: {
      query: "CONVERT_STALL",
      method: "POST",
      url: `${baseURL}/api/stalls/hold/convert/`,
    },
  },
};
