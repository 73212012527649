import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  Row,
  Button,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import {
  useGetCommentDocument,
  useCommentDocument,
} from "shared/utility/services/hooks/stalls";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/reducers/authSlice";

export const CommentsSection = ({ document, showAddComment }) => {
  const selectedUser = useSelector(selectUser);
  useEffect(() => {
    if (document) {
      commentListDocument({ document_id: document.id });
      formik.setFieldValue("document_id", document.id);
    }
  }, [document]);

  // comment list of document
  const {
    data: commentListDocumentData,
    isLoading: commentListDocumentLoading,
    isError: commentListDocumentIsError,
    error: commentListDocumentError,
    mutate: commentListDocument,
  } = useGetCommentDocument();

  useEffect(() => {
    try {
      if (commentListDocumentData && !commentListDocumentLoading) {
      }
      if (commentListDocumentIsError) {
        toast.error(commentListDocumentData?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commentListDocumentData,
    commentListDocumentLoading,
    commentListDocumentIsError,
    commentListDocumentError,
  ]);

  // comment documents
  const {
    data: commentDocumentData,
    isLoading: commentDocumentLoading,
    isError: commentDocumentIsError,
    error: commentDocumentError,
    mutate: commentDocument,
  } = useCommentDocument();

  useEffect(() => {
    try {
      if (commentDocumentData && !commentDocumentLoading) {
        toast.success("Comment added successfully");
        console.log("comment added successfully", commentDocumentData);
        formik.setFieldValue("comment", "");
        commentListDocument({ document_id: document.id });
      }
      if (commentDocumentData === null) {
        toast.success("Comment added successfully");
        formik.setFieldValue("comment", "");
        commentListDocument({ document_id: document.id });
      }
      if (commentDocumentIsError) {
        toast.error(commentDocumentData?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commentDocumentData,
    commentDocumentLoading,
    commentDocumentIsError,
    commentDocumentError,
  ]);

  const formik = useFormik({
    initialValues: {
      comment: "",
      document_id: document.id,
      commenter_id: selectedUser?.id,
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      commentDocument(values);
      commentListDocument({
        document_id: document.id,
      });
      formik.resetForm();
    },
  });

  return (
    <CardBody className="px-lg-5 py-lg-5">
      <div>
        <Row>
          <div className="card-body overflow-auto" style={{ height: "250px" }}>
            {commentListDocumentData?.length === 0 ? (
              <p className="font-weight-bold mb-0 text-center">No Comments</p>
            ) : (
              commentListDocumentData?.map((comment) => (
                <div
                  key={comment?.id}
                  className="w-100 d-flex justify-content-between align-items-center mb-3 border-bottom pb-3"
                >
                  <p className="w-75 font-weight-bold mb-0">
                    {comment.comment}
                  </p>
                  <p className="mb-0">
                    {moment(comment?.created_at).fromNow()}
                  </p>
                </div>
              ))
            )}
          </div>
        </Row>
      </div>
      {showAddComment && (
        <Form role="form" className="mt-5" onSubmit={formik.handleSubmit}>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <FormGroup className="w-75 mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Comment *"
                  type="text"
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.username && formik.errors.username}
                />
                <FormFeedback>{formik.errors.username}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <Button className="" color="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      )}
    </CardBody>
  );
};
