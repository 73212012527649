export const baseURL = process.env.REACT_APP_API_URL;
export const categories = [
    "Trade Shows",
    "Science and Technology",
    "Fashion and Apparel",
    "Food and Beverage",
    "Travel and Tourism",
    "Healthcare and Medical",
    "Education and Career",
    "Agriculture and Farming",
    "Automotive",
    "Interior Design and Home Improvement",
    "Environmental and Sustainability",
    "Wedding and Bridal",
    "Music and Entertainment",
    "Sports and Fitness",
    "Real Estate and Property",
    "Jewelry and Gemstones",
    "Gaming and Technology",
    "Crafts and Handmade Goods",
    "Antiques and Collectibles",
    "Pet and Animal Shows",
    "Cultural Festivals",
    "Renewable Energy",
    "Wearable Technology",
    "Aerospace and Aviation",
    "Pharmaceutical and Healthcare",
    "Wine and Spirits"
]
