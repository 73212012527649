// reactstrap components
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import {
  selectRecallExhibition,
  setSelectedExhibition,
  selectExhibition,
  setSelectedLogo,
  selectedLogo,
} from "redux/reducers/authSlice";
import { useExhibitionList } from "shared/utility/services/hooks/exhibition";
import { useLocation, useNavigate } from "react-router-dom";

const ExhibitionNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [query] = useState({ limit: 100, skip: 0 });
  const selectedExhibition = useSelector(selectExhibition);
  const selectedRecallExhibition = useSelector(selectRecallExhibition);
  const selectedLogoExhib = useSelector(selectedLogo);
  const setSelectedLogoExhib = (obj) => dispatch(setSelectedLogo(obj));

  const showCreateExhibition = location?.pathname === "/admin/exhibition";
  console.log(location?.pathname !== "/admin/exhibition", "location");
  const selectExhi = async (obj) => {
    dispatch(setSelectedExhibition(obj));
    try {
      let img;
      if (obj.logo !== null) {
        await fetch(obj.logo, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
          },
        }).then(async (res) => {
          img = await res.blob();
        });
        console.log(img, "img logo");

        let logo = URL.createObjectURL(img);
        setSelectedLogoExhib(logo);
      } else setSelectedLogoExhib("");
    } catch (e) {
      console.log(e);
    }
  };

  // list exhibition
  const {
    data: exhibitionListData,
    isLoading: exhibitionListLoading,
    isError: exhibitionListIsError,
    error: exhibitionListError,
    mutate: exhibitionList,
  } = useExhibitionList();

  useEffect(() => {
    try {
      if (exhibitionListData && !exhibitionListLoading) {
        if (exhibitionListData?.results?.length > 0) {
          selectExhi(exhibitionListData?.results[0]);
        }
      }
      if (exhibitionListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    exhibitionListData,
    exhibitionListLoading,
    exhibitionListIsError,
    exhibitionListError,
  ]);

  useEffect(() => {
    if (!exhibitionListLoading) {
      exhibitionList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedRecallExhibition]);

  const createExhibition = () => {
    navigate("/admin/exhibition", { state: { add: true } });
  };

  return (
    <div>
      {!selectedExhibition ? (
        showCreateExhibition ? (
          <Button onClick={createExhibition} size="md">
            Create Exhibition
          </Button>
        ) : (
          <></>
        )
      ) : (
        <UncontrolledDropdown nav>
          <DropdownToggle className="pr-0" nav>
            <span className="btn-inner--text pr-1">
              {selectedExhibition
                ? selectedExhibition?.name
                : `Select Exhibition`}
            </span>
            <span className="btn-inner--icon">
              <i class="fa-solid fa-angle-down"></i>
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {exhibitionListData?.results?.map?.((obj, key) => (
              <DropdownItem key={key} onClick={() => selectExhi(obj)}>
                {obj.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );
};

export default ExhibitionNavbar;
