// reactstrap components
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
} from "reactstrap";
import AdminHeader from "components/Headers/AdminHeader";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import { selectUser } from "redux/reducers/authSlice";
import NoData from "shared/common/NoData";
import {
  useServicesList,
  useEditServices,
  useCreateServices,
} from "shared/utility/services/hooks/exhibition";

const Services = () => {
  const selectedExhibition = useSelector(selectExhibition);
  const loggedInUser = useSelector(selectUser);
  const isAdminloggedInUser = loggedInUser.role === "admin";
  const isSalesloggedInUser = loggedInUser.role === "sales";
  const isEditStallModeOn =
    loggedInUser.role === "admin" || loggedInUser.role === "sales";

  const [modal, setModal] = useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState("add");
  const [query, setQuery] = useState({
    limit: 10,
    skip: 0,
    exhibition_id: selectedExhibition?.id,
  });

  const modalToogle = (status = "add") => {
    setModal((prev) => !prev);
    setActiveModalStatus(status);
  };

  // list services
  const {
    data: servicesListData,
    isLoading: servicesListLoading,
    isError: servicesListIsError,
    error: servicesListError,
    mutate: servicesList,
  } = useServicesList();

  useEffect(() => {
    try {
      if (servicesListData && !servicesListLoading) {
      }
      if (servicesListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    servicesListData,
    servicesListLoading,
    servicesListIsError,
    servicesListError,
  ]);

  useEffect(() => {
    if (!servicesListLoading && selectedExhibition?.id) {
      servicesList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!servicesListLoading) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  // create services
  const {
    data: createServicesData,
    isLoading: createServicesLoading,
    isError: createServicesIsError,
    error: createServicesError,
    mutate: createServices,
  } = useCreateServices();

  useEffect(() => {
    try {
      if (createServicesData && !createServicesLoading) {
        toast.success("Services created successfully");
        modalToogle();
        servicesList(query);
        formik.handleReset();
        setFile(null);
      }
      if (createServicesIsError) {
        servicesList(query);
        console.log("error", createServicesError);
        toast.error(
          createServicesError?.response?.data?.error ?? "something went wrong",
        );
        formik.handleReset();
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createServicesData, createServicesLoading, createServicesIsError]);

  // edit services
  const {
    data: editServicesData,
    isLoading: editServicesLoading,
    isError: editServicesIsError,
    error: editServicesError,
    mutate: editServices,
  } = useEditServices();

  useEffect(() => {
    try {
      if (editServicesData && !editServicesLoading) {
        toast.success("Services updated successfully");
        servicesList(query);
        modalToogle();
        formik.handleReset();
      }
      if (editServicesIsError) {
        servicesList(query);
        toast.error(
          editServicesError?.response?.data?.detail ?? "Something went wrong",
        );
        formik.handleReset();
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editServicesData, editServicesLoading, editServicesIsError]);

  const [file, setFile] = useState(null);
  const initialValues = {
    name: "",
    description: "",
    price: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      description: Yup.string(),
      price: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (!formik.values.id) {
        console.log(values);
        if (!file) {
          toast.error("Please upload an image");
          return;
        }
        createServices({
          exhibition_id: selectedExhibition?.id,
          service: file,
          data: [values],
        });
      } else {
        console.log(values);
        editServices({
          ...values,
          exhibition_id: selectedExhibition?.id,
          service_id: formik.values.id,
        });
      }
    },
  });

  const modalclose = () => {
    modalToogle();
    formik.setValues(initialValues);
  };

  const addServicesModalOpen = () => {
    modalToogle("add");
  };

  const editServicesModalOpen = (obj) => {
    modalToogle("add");
    console.log(obj);
    try {
      formik.setValues(obj);
      console.log(obj.image);
    } catch (e) {
      console.log(e);
    }
  };

  const clickPrev = () => {
    if (query.skip !== 0) {
      setQuery((prev) => ({ ...prev, skip: prev.skip - prev.limit }));
    }
  };

  const clickNext = () => {
    if (query.skip + query.limit < servicesListData.total_count) {
      setQuery((prev) => ({ ...prev, skip: prev.skip + prev.limit }));
    }
  };

  let modalStatus = {
    add: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              {formik.values.id
                ? isEditStallModeOn
                  ? "Edit Service"
                  : "View Service"
                : "Add Service"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalclose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Service Name
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Name *"
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.name && formik.errors.name}
                        disabled={!isEditStallModeOn || isSalesloggedInUser}
                      />
                      <FormFeedback>{formik.errors.name}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-name"
                      >
                        Service Image
                      </label>
                      <div>
                        {formik.values.image && (
                          <img
                            alt="logo"
                            className={"img-thumbnail m-2"}
                            style={{ width: "100px" }}
                            src={formik.values.image}
                          />
                        )}
                      </div>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Image *"
                          type="file"
                          name="image"
                          onChange={(e) => {
                            setFile(e.currentTarget.files[0]);
                          }}
                          // onBlur={formik.handleBlur}
                          disabled={!isEditStallModeOn || isSalesloggedInUser}
                        />
                        <FormFeedback></FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="input-price_per_sq_m"
                    >
                      Price
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Price *"
                        type="text"
                        name="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.price && formik.errors.price}
                        disabled={!isEditStallModeOn}
                      />
                      <FormFeedback>{formik.errors.price}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Description
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Description *"
                        type="text"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                      <FormFeedback>{formik.errors.name}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {isEditStallModeOn && (
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
  };

  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        {!selectedExhibition && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <NoData title="For services, Please set up an exhibition." />
              </Card>
            </div>
          </Row>
        )}
        {selectedExhibition && (
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Services</h3>
                    </Col>
                    {isAdminloggedInUser && (
                      <Col className="text-right" xs="4">
                        <Button
                          color="primary"
                          onClick={addServicesModalOpen}
                          size="sm"
                        >
                          Add
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                {servicesListData?.length === 0 ? (
                  <NoData title="No data found" />
                ) : (
                  <div>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Price</th>
                          <th scope="col">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {servicesListData?.map?.((obj, index) => (
                          <tr key={index}>
                            <td className="text-capitalize">{obj?.name}</td>
                            <td className="text-capitalize">
                              {obj?.price ?? 0}
                            </td>
                            <td>
                              <Button
                                color="primary"
                                onClick={(e) => editServicesModalOpen(obj)}
                                size="sm"
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        )}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={modal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default Services;
