import { useMutation } from "react-query";
import useAxios from "../useAxios";
import { stallsRoutes } from "./route";
import { objectToQueryString } from "shared/utility/helpers";

const {
  stalls,
  document,
  comment,
  possession,
  participation,
  payment,
  pendingPaymentStall,
  service,
  merge,
  hold,
} = stallsRoutes;

export const useCreateStalls = () => {
  const callApi = useAxios();
  const { url, method } = stalls.post;

  return useMutation((data) => {
    const queryString = objectToQueryString({
      exhibition_id: data?.exhibition_id,
    });
    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data?.stalls,
    }).then((data) => data);
  });
};

export const useEditStalls = () => {
  const callApi = useAxios();
  const { url, method } = stalls.put;

  return useMutation((data) => {
    return callApi({
      method: method,
      url: url + `/${data.id}`,
      data,
    }).then((data) => data);
  });
};

export const useBulkAddStalls = () => {
  const callApi = useAxios();
  const { url, method } = stalls.bulk;

  let headers = {
    "Content-Type": "multipart/form-data",
  };

  return useMutation((data) => {
    return callApi({
      method: method,
      url: url,
      headers,
      data,
    }).then((data) => data);
  });
};

export const useStallsList = () => {
  const callApi = useAxios();
  const { url, method } = stalls.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);

    let newUrl = `${url}?${queryString}`;
    console.log(newUrl, "newUrl getStallsList");
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useAssignStallToUsers = () => {
  const callApi = useAxios();
  const { url, method } = stalls.assignStallTo;

  return useMutation((data) => {
    let newUrl = `${url}?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method: method,
      url: newUrl,
      data,
    }).then((data) => data);
  });
};

export const useDocumentList = () => {
  const callApi = useAxios();
  const { url, method } = document.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);

    let newUrl = `${url}?${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useDocumentUpload = () => {
  const callApi = useAxios();
  const { url, method } = document.post;
  let headers = {
    "Content-Type": "multipart/form-data",
  };

  return useMutation((data) => {
    return callApi({
      method,
      url,
      data,
      headers,
    }).then((data) => data);
  });
};

export const useDocumentDownload = () => {
  const callApi = useAxios();
  const { url, method } = document.get;

  return useMutation((data) => {
    return callApi({
      method,
      url: url + "/" + data,
    }).then((data) => data);
  });
};

export const useDocumentApprove = () => {
  const callApi = useAxios();
  const { url, method } = document.approve;

  return useMutation((data) => {
    const newUrl = `${url}${data.documentId}?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useCommentDocument = () => {
  const callApi = useAxios();
  const { url, method } = comment.post;

  return useMutation((data) => {
    return callApi({
      method,
      url: url,
      data,
    }).then((data) => data);
  });
};

export const useGetCommentDocument = () => {
  const callApi = useAxios();
  const { url, method } = comment.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    const newUrl = `${url}?${queryString}`;

    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useParticipationDownload = () => {
  const callApi = useAxios();
  const { url, method } = participation.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    const newUrl = `${url}?${queryString}`;
    return callApi({
      method,
      url: newUrl,
      data: {},
    }).then((data) => data);
  });
};

export const usePossessionDownload = () => {
  const callApi = useAxios();
  const { url, method } = possession.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    const newUrl = `${url}?${queryString}`;
    return callApi({
      method,
      url: newUrl,
      data: {},
    }).then((data) => data);
  });
};
export const useGetPaymentList = () => {
  const callApi = useAxios();
  const { url, method } = pendingPaymentStall.get;

  return useMutation((data) => {
    let newUrl = `${url}?exhibition_id=${data?.exhibition_id}`;
    const status = data?.paymentStatus;
    status.map((value, index) => {
      if (value === "") {
        newUrl = newUrl;
      } else {
        newUrl = newUrl + `&status=${value}`;
      }
    });

    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};
export const useCreatePayment = () => {
  const callApi = useAxios();
  const { url, method } = payment.create;

  return useMutation((data) => {
    const queryString = objectToQueryString(data.values);
    const newUrl = `${url}?${queryString}`;
    const formData = new FormData();
    console.log(data, "payment data");
    formData.append("payment_proof", data.paymentProof);
    return callApi({
      method,
      url: newUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => data);
  });
};

export const useUpdatePayment = () => {
  const callApi = useAxios();
  const { url, method } = payment.update;

  return useMutation((data) => {
    const newUrl = `${url}${data.id}?status=${data.action}&stall_id=${data.stall_id}`;
    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useUpdateSerivcePayment = () => {
  const callApi = useAxios();
  const { url, method } = service.paymentAction;

  return useMutation((data) => {
    const newUrl = `${url}${data.id}?status=${data.action}&stall_id=${data.stall_id}`;
    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useServiceAddToStall = () => {
  const callApi = useAxios();
  const { url, method } = service.create;

  return useMutation((data) => {
    return callApi({
      method: method,
      url: url,
      data: {
        stall_id: data?.stall_id,
        service_id: data?.service.id,
        quantity: data?.service.qty,
      },
    }).then((data) => data);
  });
};

export const useCreateServicePayment = () => {
  const callApi = useAxios();
  const { url, method } = service.payment;

  return useMutation((data) => {
    const queryString = objectToQueryString(data.values);
    const newUrl = `${url}?${queryString}`;
    const formData = new FormData();
    console.log(data, "payment data");
    formData.append("payment_proof", data.paymentProof);
    return callApi({
      method,
      url: newUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => data);
  });
};

export const useMergeStalls = () => {
  const callApi = useAxios();
  const { url, method } = merge;

  return useMutation((data) => {
    console.log(data, "datamerge");
    const newUrl = `${url}?exhibition_id=${data.exhibition_id}`;
    console.log(newUrl, "newUrl");
    let data_to_send = {
      stall_ids: [data.mergeStall1, data.mergeStall2],
      merged_stall: data.mergeStallData,
    };
    return callApi({
      method,
      url: newUrl,
      data: data_to_send,
    }).then((data) => data);
  });
};

export const useResetStall = () => {
  const callApi = useAxios();
  const { url, method } = stalls.reset;

  return useMutation((data) => {
    const newUrl = `${url}${data.stall_id}?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useHoldStalls = () => {
  const callApi = useAxios();
  const { url, method } = hold.holdStall;
  return useMutation((data) => {
    const newUrl = `${url}?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method,
      url: newUrl,
      data,
    }).then((data) => data);
  });
};
export const useUnHoldStalls = () => {
  const callApi = useAxios();
  const { url, method } = hold.releaseStallHold;
  return useMutation((data) => {
    const newUrl = `${url}${data.hold_id}`;
    return callApi({
      method,
      url: newUrl,
      data,
    }).then((data) => data);
  });
};

export const useConvertStall = () => {
  const callApi = useAxios();
  const { url, method } = hold.convert;

  return useMutation((data) => {
    const newUrl = `${url}${data.hold_id}?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method,
      url: newUrl,
    }).then((data) => data);
  });
};
