import React, { useState } from 'react';
import { Row, Col, Table, Alert } from 'reactstrap';

const ElegantBookedStalls = ({ bookedStalls }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Calculate summary data
    const totalBookedStalls = bookedStalls.length;
    const uniqueHalls = [...new Set(bookedStalls.map(stall => stall.hall_or_dome))];
    const recentStalls = [...bookedStalls]
        .sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date))
        .slice(0, 5);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const filteredStalls = bookedStalls.filter(stall =>
        stall.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        stall.hall_or_dome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        stall.user.brand_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="p-3">
            <h6 className="text-uppercase text-muted mb-3">Booked Stalls</h6>
            <Row className="mb-4 text-center">
                <Col xs="6">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{totalBookedStalls}</span>
                    </div>
                    <span className="text-muted text-sm">Total Booked Stalls</span>
                </Col>
                <Col xs="6">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{uniqueHalls.length}</span>
                    </div>
                    <span className="text-muted text-sm">Halls Booked</span>
                </Col>
            </Row>

            {totalBookedStalls === 0 ? (
                <Alert color="info" className="text-center mb-0">
                    <i className="fas fa-info-circle mr-2"></i>
                    No stalls have been booked yet.
                </Alert>
            ) : (
                <>
                    <h6 className="text-uppercase text-muted mb-3">
                        {searchTerm ? 'Search Results' : 'Recent Booked Stalls'}
                    </h6>
                    <Table responsive borderless size="sm" className="align-items-center mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Name</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Hall</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Booked Date</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">User</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(searchTerm ? filteredStalls : recentStalls).map((stall) => (
                            <tr key={stall.id}>
                                <td>
                                    <span className="text-sm font-weight-bold">{stall.name}</span>
                                </td>
                                <td className="text-sm">{stall.hall_or_dome}</td>
                                <td className="text-sm">{formatDate(stall.booking_date)}</td>
                                <td className="text-sm text-center">{stall.user.brand_name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {searchTerm && filteredStalls.length === 0 && (
                        <Alert color="warning" className="text-center mt-3">
                            No results found for your search.
                        </Alert>
                    )}
                </>
            )}
        </div>
    );
};

export default ElegantBookedStalls;