// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useCreateEXhibition,
  useExhibitionList,
  useEditExhibition,
  useAddImageExhibition,
} from "shared/utility/services/hooks/exhibition";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Table,
  ButtonGroup,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  recallExhibition,
  selectedLogo,
  selectUser,
  setSelectedExhibition,
  setSelectedLogo,
} from "redux/reducers/authSlice";

import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteExhibition } from "shared/utility/services/hooks/exhibition";
import ReactDatetime from "react-datetime";
// react component for creating dynamic tables
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
const Exhibition = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const { state } = useLocation();
  const selectedUser = useSelector(selectUser);

  // list exhibition
  const {
    data: exhibitionListData,
    isLoading: exhibitionListLoading,
    isError: exhibitionListIsError,
    error: exhibitionListError,
    mutate: exhibitionList,
  } = useExhibitionList();

  let exhibitionListNew = [];
  useEffect(() => {
    if (!exhibitionListLoading) {
      exhibitionList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const selectedLogoExhib = useSelector(selectedLogo);
  const setSelectedLogoExhib = (obj) => dispatch(setSelectedLogo(obj));
  const navigate = useNavigate();
  const selectExhi = async (obj) => {
    dispatch(setSelectedExhibition(obj));
    try {
      let img;
      if (obj.logo !== null) {
        await fetch(obj.logo, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
          },
        }).then(async (res) => {
          img = await res.blob();
        });
        console.log(img, "img logo");

        let logo = URL.createObjectURL(img);
        setSelectedLogoExhib(logo);
      } else setSelectedLogoExhib("");
      navigate("/visitor/tickets");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <AdminHeader />
      <Container className="mt--7 w-100" fluid>
        <Row className={"w-100"}>
          <Card className="shadows w-100">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col
                  xs="12"
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <h3 className="mb-0">Registered Exhibitions</h3>
                </Col>
              </Row>
            </CardHeader>

            {exhibitionListData && exhibitionListData?.results?.length === 0 ? (
              <div
                className={
                  "container-fluid d-flex flex-column justify-content-center align-items-center p-8  "
                }
              >
                <span className={"p-3 text-dark font-weight-bold"}>
                  You Have Not Registered For Any Exhibition Yet
                </span>
              </div>
            ) : (
              <CardBody>
                {exhibitionListData?.results?.map((exhibition, index) => (
                  <Col xs={12} md={"6"} lg={"4"}>
                    <Card>
                      <CardBody
                        className={"shadow shadow-lg rounded rounded-lg"}
                      >
                        <Row className={"d-flex justify-content-center"}>
                          <img
                            src={exhibition.logo}
                            height={"100px"}
                            className={"rounded-circle"}
                          />
                        </Row>
                        <Row className={"d-flex justify-content-center"}>
                          <Col lg={12}>
                            <h2 className={"text-center"}>{exhibition.name}</h2>
                          </Col>
                        </Row>
                        <Row className={"d-flex justify-content-center"}>
                          <Col lg={12}>
                            <h4 className={"text-center text-gray"}>
                              {exhibition.start_date} - {exhibition.end_date}
                            </h4>
                          </Col>
                          <Col lg={12}>
                            <h5 className={"text-center"}>
                              {exhibition.venue} <br />
                              {exhibition.address}
                            </h5>
                          </Col>
                          <Col lg={12}>
                            <h5 className={"text-center text-primary"}>
                              Registration Dates
                            </h5>
                            <h4 className={"text-center text-primary"}>
                              {exhibition.visitor_reg_start_date} -{" "}
                              {exhibition.visitor_reg_end_date}
                            </h4>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <Button
                          className={"w-100"}
                          color={"success"}
                          onClick={() => {
                            selectExhi(exhibition);
                          }}
                        >
                          Tickets
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </CardBody>
            )}
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default Exhibition;
