import { useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../config";
import { store } from "redux/store";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
/**
 * Returns a function that can be used to call an API.
 * This function wraps the axios instance.
 */
const Axios = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const callApi = useCallback(async ({ headers, ...rest }) => {
    try {
      const idToken = store.getState();
      const { data } = await axios({
        headers: {
          // 'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          authorization: `Bearer ${idToken?.user?.authToken?.access_token}`,
          ...headers,
        },
        baseURL,
        ...rest,
        validateStatus: (status) => status >= 200 && status <= 299,
      });
      return data;
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        navigate("/");
        dispatch({ type: "USER_LOGOUT" });
        toast.error("Session Expired");
      } else if (err && err.response && err.response.status === 503) {
        navigate("/404");
      }
      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return callApi;
};

export default Axios;
