import { baseURL } from "../../../../../config";

export const usersRoutes = {
  users: {
    post: {
      query: "CREATE_USERS",
      method: "POST",
      url: `${baseURL}/api/bulk-create-user`,
    },
    put: {
      query: "EDIT_USERS",
      method: "PATCH",
      url: `${baseURL}/api/update-user`,
    },
    get: {
      query: "GET_USERS",
      method: "GET",
      url: `${baseURL}/api/exhibition/user`,
    },
    businessUsers: {
      query: "GET_BUSINESS_USERS",
      method: "GET",
      url: `${baseURL}/api/business/user`,
    },
    assignUsers: {
      query: "ASSIGN_USERS",
      method: "POST",
      url: `${baseURL}/api/exhibition_user_mapping`,
    },
    revokeUsers: {
      query: "REVOKE_USERS",
      method: "DELETE",
      url: `${baseURL}/api/exhibition_user_mapping`,
    },
    unassignedUsers: {
      query: "GET_UNASSIGNED_USERS",
      method: "GET",
      url: `${baseURL}/api/exhibition/unassigned`,
    },
  },
};
