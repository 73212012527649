import AdminHeader from "components/Headers/AdminHeader";
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { selectExhibition } from "../../../redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  useScanTicket,
  useVerifyTicket,
} from "../../../shared/utility/services/hooks/visit";

const BouncerQRScanner = () => {
  const [result, setResult] = useState("");
  const [scanning, setScanning] = useState(true); // Add state for scanning
  const selectedExhibition = useSelector(selectExhibition);

  const {
    data: scanTicketData,
    isLoading: scanTicketLoading,
    isError: scanTicketIsError,
    error: scanTicketError,
    mutate: scanTicketRefetch,
    reset: scanTicketReset,
  } = useScanTicket();

  const {
    data: verifyTicketData,
    isLoading: verifyTicketLoading,
    isError: verifyTicketIsError,
    error: verifyTicketError,
    mutate: verifyTicketRefetch,
    reset: verifyTicketReset,
  } = useVerifyTicket();

  function verifyTicket(ticket_id) {
    if (selectedLocation?.value) {
      verifyTicketRefetch({
        ticket_id: ticket_id,
        location: selectedLocation?.value,
        exhibition_id: selectedExhibition?.id,
      });
    } else {
      toast.error("Please select a location");
    }
  }
  function isUUIDv4(uuid) {
    const uuidv4Pattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidv4Pattern.test(uuid);
  }
  useEffect(() => {
    if (result) {
      if (isUUIDv4(result)) {
        toast.success("Success");
        console.log(result);
        scanTicketRefetch({
          ticket_id: result,
          location: selectedLocation?.value,
          exhibition_id: selectedExhibition?.id,
        });
        setScanning(false);
      } else {
        toast.error("Invalid QR Code");
      }
    }
  }, [result]);

  useEffect(() => {
    if (scanTicketData) {
      toast.success("Ticket scanned successfully");
      console.log(scanTicketData, "scanTicketData");
    }
    if (scanTicketIsError) {
      toast.error(scanTicketError?.response?.data?.message);
    }
  }, [scanTicketData, scanTicketIsError]);

  useEffect(() => {
    if (verifyTicketData) {
      toast.success("Ticket Verified successfully");
      console.log(verifyTicketData, "verifyTicketData");
      handleScanAgain();
    }
    if (verifyTicketError) {
      toast.error(verifyTicketError?.data?.message);
    }
  }, [verifyTicketData, verifyTicketError, verifyTicketIsError]);

  const handleScanAgain = () => {
    setResult("");
    setScanning(true);
    scanTicketReset();
    verifyTicketReset();
  };

  const checkCameraPermission = async () => {
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const stream = await navigator.mediaDevices.getUserMedia({});
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  };

  if (!scanning) {
    checkCameraPermission().then((permissionGranted) => {
      if (!permissionGranted) {
        toast.error("Camera permission denied");
        checkCameraPermission().then((permissionGranted) => {
          if (!permissionGranted) {
            toast.error("Camera permission denied , Please Refresh Page", {
              size: "lg",
            });
          }
        });
      }
    });
  }
  const [selectedLocation, setSelectedLocation] = useState(null);
  return (
    <>
      <AdminHeader />
      <Row className={"d-flex justify-content-center mt--5"}>
        <Col lg={"10"} className={"p-4"}>
          <Card className={"card shadow shadow-lg"}>
            <CardHeader className={"text-center"}>
              <h3>Select Location</h3>
            </CardHeader>
            <CardBody className={"p-3"}>
              <Select
                options={selectedExhibition?.allowed_hall_or_dome?.map(
                  (location) => ({
                    value: location,
                    label: location,
                  }),
                )}
                value={selectedLocation}
                onChange={setSelectedLocation}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={10} className={"mt-3 p-4"}>
          {scanning && (
            <Card className={"card shadow shadow-lg"}>
              <CardHeader className={"text-center"}>
                <h3>Scanner</h3>
              </CardHeader>
              <CardBody className={"p-3"}>
                <QrReader
                  className=" w-50 h-25 mx-auto qr-container"
                  onResult={(result, error) => {
                    if (!!result) {
                      setResult(result?.text);
                    }
                  }}
                  constraints={{
                    facingMode: "environment",
                  }}
                />
              </CardBody>
            </Card>
          )}
          {!scanning && scanTicketData && (
            <Card className={"card shadow shadow-lg"}>
              <CardHeader className={"text-center"}>
                <h3>Scanner</h3>
              </CardHeader>
              <CardBody className={"p-3 "}>
                <Row>
                  <Col lg={12}>
                    <div className="qr-result-container">
                      <h3>QR Code scanned: </h3>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="qr-result-container  text-center ">
                      <h5>Name:</h5>
                      <h4>{scanTicketData?.name}</h4>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="qr-result-container text-center">
                      <h5>Business Name :</h5>
                      <h4>{scanTicketData?.business_name}</h4>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="qr-result-container  text-center">
                      <h5>Allowed Halls:</h5>
                      <h4>
                        {scanTicketData?.constraints?.location
                          ? scanTicketData?.constraints?.location.join(", ")
                          : "No Constraints"}
                      </h4>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="qr-result-container  text-center">
                      <h5>Allowed Visits:</h5>
                      <h4>
                        {scanTicketData?.constraints?.times
                          ? scanTicketData?.times_scanned +
                            "/" +
                            scanTicketData?.constraints?.times
                          : "No Constraints"}
                      </h4>
                    </div>
                  </Col>
                  <Row className={"col-lg-12 mt-5"}>
                    <Col lg={6} className={"text-center"}>
                      <Button
                        color="primary"
                        onClick={() => verifyTicket(result)}
                        size="lg"
                      >
                        Process
                      </Button>
                    </Col>
                    <Col lg={6} className={"text-center"}>
                      <Button
                        color="danger"
                        onClick={handleScanAgain}
                        size="lg"
                      >
                        Scan Other QR Code
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default BouncerQRScanner;
