import "./styles.css";

import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "redux/reducers/authSlice";
import { setTheme } from "redux/reducers/authSlice";
import { useCallback, useEffect } from "react";

function Darkmode(props) {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const setDark = useCallback(() => {
    dispatch(setTheme("dark"));
    document.documentElement.setAttribute("data-theme", "dark");
  }, [dispatch]);

  const setLight = () => {
    dispatch(setTheme("light"));
    document.documentElement.setAttribute("data-theme", "light");
  };

  useEffect(() => {
    const storedTheme = theme;
    const prefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    const defaultDark =
      storedTheme === "dark" || (storedTheme === null && prefersDark);
    if (defaultDark) {
      setDark();
    }
  }, [setDark, theme]);

  const toggleTheme = (e) => {
    if (e.target.checked) {
      setDark();
    } else {
      setLight();
    }
  };

  return (
    <div className="toggle-theme-wrapper">
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          defaultChecked={theme === "dark"}
        />
        <div className="slider round">
          <i className="fa-regular fa-sun"></i>
          <i className="fa-regular fa-moon"></i>
        </div>
      </label>
    </div>
  );
}

export default Darkmode;
