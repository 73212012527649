// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Badge,
  InputGroupAddon,
  InputGroupText,
  ButtonGroup,
} from "reactstrap";
import Loading from "shared/common/loading";
import AdminHeader from "components/Headers/AdminHeader";
import {
  useStallsList,
  useCreateStalls,
  useEditStalls,
  useAssignStallToUsers,
  useBulkAddStalls,
  useGetPaymentList,
  useCreatePayment,
  useUpdatePayment,
  useUpdateSerivcePayment,
} from "shared/utility/services/hooks/stalls";
import classNames from "classnames";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import { selectUser } from "redux/reducers/authSlice";
import NoData from "shared/common/NoData";
import moment from "moment";
import {
  stallStates,
  stallStates_bg,
  stallStatues,
  stallStatues_color,
} from "shared/utility/helpers";
import { useNavigate } from "react-router-dom";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { store } from "../../redux/store";

import { sortCarets } from "../../shared/utility/helpers";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show{" "}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm d-inline w-auto mx-1"
          defaultValue={"25"}
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Payments = () => {
  const navigate = useNavigate();
  const selectedExhibition = useSelector(selectExhibition);
  const user = useSelector(selectUser);
  const isAdminloggedInUser = user.role === "admin";
  const isSalesloggedInUser = user.role === "sales";
  const isEditStallModeOn = user.role === "admin" || user.role === "sales";
  const componentRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const [bulkUploadDocument, setBulkUploadDocument] = useState(null);

  const [unAssignedUserQuery, setUnAssignedUserQuery] = useState({
    skip: 0,
    search: "",
    roles: ["exhibitor"],
    current_exhibition: selectedExhibition?.id,
  });
  const [selectedStallPayments, setSelectedStallPayments] = useState();
  const [isActionItems, setIsActionItems] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState([
    "payment_pending",
    "approved",
  ]);
  const [query, setQuery] = useState({
    skip: 0,
    exhibition_id: selectedExhibition?.id,
    paymentStatus: paymentStatus,
  });

  const modalToogle = (status) => {
    setModal((prev) => !prev);
    setActiveModalStatus(status);
  };

  const justChangeModal = (status) => {
    setActiveModalStatus(status);
  };

  useEffect(() => {
    paymentsList({
      exhibition_id: selectedExhibition?.id,
      paymentStatus: paymentStatus,
    });
    if (isActionItems) {
      paymentsListData = actionItems(paymentsListData?.results);
    }
  }, [paymentStatus]);

  // list stalls
  let {
    data: paymentsListData,
    isLoading: paymentsListLoading,
    isError: paymentsListIsError,
    error: paymentsListError,
    mutate: paymentsList,
  } = useGetPaymentList();

  useEffect(() => {
    try {
      if (paymentsListData && !paymentsListLoading) {
      }
      if (paymentsListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentsListData,
    paymentsListLoading,
    paymentsListIsError,
    paymentsListError,
  ]);

  useEffect(() => {
    console.log(selectedExhibition);
  }, [selectedExhibition]);

  const {
    data: createPaymentData,
    isLoading: createPaymentLoading,
    isError: createPaymentIsError,
    error: createPaymentError,
    mutate: createPayment,
  } = useCreatePayment();
  useEffect(() => {
    if (createPaymentData && !createPaymentLoading) {
      toast.success("Payment Added successfully");
      modalClose();
      paymentsList(query);
    }
  }, [createPaymentData]);

  useEffect(() => {
    if (!paymentsListLoading && selectedExhibition?.id) {
      paymentsList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!paymentsListLoading) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  const initialValues = {
    stall_id: "",
    amount_paid: 0,
    payment_type: "",
    amount: 0,
  };
  const [paymentProof, setPaymentProof] = useState(null);
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      stall_id: Yup.string().required("Required"),
      amount_paid: Yup.string().required("Required"),
      payment_type: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // participationDownload(values);
      console.log({ values, paymentProof }, "values");
      createPayment({ values, paymentProof });
      modalClose();
    },
  });

  const modalClose = () => {
    setModal(false);
    setActiveModalStatus("");
    formik.setValues(initialValues);
  };

  const openPaymentModal = (e, item) => {
    e.preventDefault();
    modalToogle("payment");
    formik.setValues({
      ...formik.values,
      stall_id: item.id,
      amount: item.price,
    });
  };

  const openStallPaymentModal = (e, item) => {
    e.preventDefault();
    modalToogle("stallPaymentList");
    setSelectedStallPayments(item.payments);
    formik.setValues({
      ...formik.values,
      stall_id: item.id,
      amount: item.price,
    });
  };
  const openServicePaymentModal = (e, item) => {
    e.preventDefault();
    modalToogle("servicePaymentList");
    setSelectedStallPayments(item.service_payments);
    formik.setValues({
      ...formik.values,
      stall_id: item.id,
      amount: item.price,
    });
  };
  const handleChange = (obj) => {
    setSelectedUser(obj);
  };
  const [paymentActionStatus, setPaymentActionStatus] = useState();
  let modalStatus = {
    payment: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Upload Payment Proof
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center flex-column">
                <div className={"container-fluid"}>
                  <Row>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Total Price
                        </label>
                        {/*<p>{stallData?.price_per_sq_unit}</p>*/}
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa-solid fa-calculator "></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=""
                            type="number"
                            name="price_per_sq_unit"
                            value={formik.values.amount}
                            disabled={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Payment Amount
                        </label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa-solid  fa-calculator"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Payment Amount"
                            type="number"
                            name="amount_paid"
                            value={formik.values.amount_paid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.amount_paid &&
                              formik.errors.amount_paid
                            }
                          />
                          <FormFeedback>
                            {formik.errors.amount_paid}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Payment Type
                        </label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa-solid  fa-text-width"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Mode Of Payment"
                            type="text"
                            name="payment_type"
                            value={formik.values.payment_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.payment_type &&
                              formik.errors.payment_type
                            }
                          />
                          <FormFeedback>
                            {formik.errors.payment_type}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Payment Proof
                        </label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="Upload Payment Proof"
                            type="file"
                            name="qty"
                            onChange={(e) => {
                              setPaymentProof(e.target.files[0]);
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button
                  color="primary"
                  type="submit"
                  disabled={createPaymentLoading}
                  onClick={formik.handleSubmit}
                >
                  Pay now
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
    stallPaymentList: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0 mx--9">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Stall Payment List
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5 w-100">
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center flex-column">
                <div className={"container-lg"}>
                  {selectedStallPayments && (
                    <ToolkitProvider
                      data={selectedStallPayments}
                      keyField="id"
                      columns={[
                        {
                          dataField: "amount_paid",
                          text: "Amount Paid",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                        },
                        {
                          dataField: "link",
                          text: "Payment Proof",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                        },
                        {
                          dataField: "payment_type",
                          text: "Payment Type",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },

                        {
                          dataField: "status",
                          text: "Status",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },
                        {
                          dataField: "action",
                          text: "Action",
                          sort: false,
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },
                      ]}
                      search
                    >
                      {(props) => (
                        <div className="py-4 px-4 table-responsive">
                          <Container fluid>
                            <Row>
                              <Col xs={12} sm={6}></Col>
                              <Col xs={12} sm={6}>
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1 float-right"
                                >
                                  <label>
                                    <span>Search:</span>
                                    <SearchBar
                                      className="form-control-sm ml-2"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                          <BootstrapTable
                            ref={componentRef}
                            {...props.baseProps}
                            bootstrap4={true}
                            classes={"mb-3"}
                            pagination={pagination}
                            bordered={true}
                            hover
                            condensed
                            rowStyle={{ textAlign: "center" }}
                            id="react-bs-table"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
    paymentAction: (
      <div className="modal-body p-0">
        <Card>
          <CardHeader>
            <h3 className="mb-0 text-center">
              {paymentActionStatus?.action === "approved"
                ? "Approve"
                : "Reject"}{" "}
              Payment
            </h3>
          </CardHeader>
          <CardBody className={"text-center"}>
            <h5>
              Are you sure you want to{" "}
              {paymentActionStatus?.action === "approved"
                ? "approve"
                : "reject"}{" "}
              this payment?
            </h5>
            <Button
              color="success"
              type={"button"}
              className={"mr-2"}
              onClick={(e) => {
                paymentAction(paymentActionStatus);
              }}
            >
              {paymentActionStatus?.action === "approved"
                ? "Approve"
                : "Reject"}
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                modalClose();
              }}
            >
              Cancel
            </Button>
          </CardBody>
        </Card>
      </div>
    ),
    servicePaymentList: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0 mx--9">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Service Payment List
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5 w-100">
            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center flex-column">
                <div className={"container-lg"}>
                  {selectedStallPayments && (
                    <ToolkitProvider
                      data={selectedStallPayments}
                      keyField="id"
                      columns={[
                        {
                          dataField: "amount_paid",
                          text: "Amount Paid",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                        },
                        {
                          dataField: "link",
                          text: "Payment Proof",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                        },
                        {
                          dataField: "payment_type",
                          text: "Payment Type",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },

                        {
                          dataField: "status",
                          text: "Status",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },
                        {
                          dataField: "serviceAction",
                          text: "Action",
                          sort: false,
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },
                      ]}
                      search
                    >
                      {(props) => (
                        <div className="py-4 px-4 table-responsive">
                          <Container fluid>
                            <Row>
                              <Col xs={12} sm={6}></Col>
                              <Col xs={12} sm={6}>
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1 float-right"
                                >
                                  <label>
                                    <span>Search:</span>
                                    <SearchBar
                                      className="form-control-sm ml-2"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                          <BootstrapTable
                            ref={componentRef}
                            {...props.baseProps}
                            bootstrap4={true}
                            classes={"mb-3"}
                            pagination={pagination}
                            bordered={true}
                            hover
                            condensed
                            rowStyle={{ textAlign: "center" }}
                            id="react-bs-table"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
    servicePaymentAction: (
      <div className="modal-body p-0">
        <Card>
          <CardHeader>
            <h3 className="mb-0 text-center">
              {paymentActionStatus?.action === "approved"
                ? "Approve"
                : "Reject"}{" "}
              Payment
            </h3>
          </CardHeader>
          <CardBody className={"text-center"}>
            <h5>
              Are you sure you want to{" "}
              {paymentActionStatus?.action === "approved"
                ? "approve"
                : "reject"}{" "}
              this payment?
            </h5>
            <Button
              color="success"
              className={"mr-2"}
              onClick={(e) => {
                servicePaymentAction(paymentActionStatus);
              }}
            >
              {paymentActionStatus?.action === "approved"
                ? "Approve"
                : "Reject"}
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                modalClose();
              }}
            >
              Cancel
            </Button>
          </CardBody>
        </Card>
      </div>
    ),
  };

  const {
    data: updatePaymentData,
    isLoading: updatePaymentLoading,
    isError: updatePaymentIsError,
    error: updatePaymentError,
    mutate: updatePayment,
  } = useUpdatePayment();
  useEffect(() => {
    if (updatePaymentData && !updatePaymentLoading) {
      toast.success("Payment Updated successfully");
      modalClose();
      paymentsList({
        exhibition_id: selectedExhibition?.id,
        paymentStatus: paymentStatus,
      });
    }
  }, [updatePaymentData, updatePaymentLoading]);
  const {
    data: updateServicePaymentData,
    isLoading: updateServicePaymentLoading,
    isError: updateServicePaymentIsError,
    error: updateServicePaymentError,
    mutate: updateServicePayment,
  } = useUpdateSerivcePayment();
  useEffect(() => {
    if (updateServicePaymentData && !updateServicePaymentLoading) {
      toast.success("Service Payment Updated successfully");
      modalClose();
      paymentsList({
        exhibition_id: selectedExhibition?.id,
        paymentStatus: paymentStatus,
      });
    }
  }, [updateServicePaymentData, updateServicePaymentLoading]);

  const paymentAction = (paymentAction) => {
    console.log({ ...paymentAction }, "paymentAction");
    updatePayment({ ...paymentAction });
    paymentsList({
      exhibition_id: selectedExhibition?.id,
      paymentStatus: paymentStatus,
    });
  };
  const servicePaymentAction = (paymentAction) => {
    console.log({ ...paymentAction }, "servicePaymentAction");

    updateServicePayment({ ...paymentAction });
    paymentsList({
      exhibition_id: selectedExhibition?.id,
      paymentStatus: paymentStatus,
    });
    modalClose();
  };

  const actionItems = (stallsData) => {
    const stallsWithPendingPayments = stallsData.filter((stall) => {
      return stall.payments.some((payment) => payment.status === "pending");
    });
    console.log(stallsWithPendingPayments, "stallsWithPendingPayments");
    return stallsWithPendingPayments;
  };

  useEffect(() => {
    if (selectedStallPayments) {
      selectedStallPayments.map((item, index) => {
        item.link = (
          <Button
            color="primary"
            variant="outlined"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              window.open(item.payment_proof, "_blank");
            }}
          >
            View
          </Button>
        );
        item.action = (
          <>
            <Button
              color="success"
              variant="outlined"
              size="sm"
              disabled={
                item.status === "approved" || item.status === "rejected"
              }
              onClick={(e) => {
                e.preventDefault();
                setPaymentActionStatus({
                  id: item.id,
                  action: "approved",
                  stall_id: item.stall_id,
                });
                justChangeModal("paymentAction");
              }}
            >
              Approve
            </Button>
            <Button
              color="danger"
              variant="outlined"
              size="sm"
              disabled={
                item.status === "approved" || item.status === "rejected"
              }
              onClick={(e) => {
                e.preventDefault();
                setPaymentActionStatus({
                  id: item.id,
                  action: "rejected",
                  stall_id: item.stall_id,
                });
                justChangeModal("paymentAction");
              }}
            >
              Reject
            </Button>
          </>
        );
        item.serviceAction = (
          <>
            <Button
              color="success"
              variant="outlined"
              disabled={item.status === "approved"}
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                setPaymentActionStatus({
                  id: item.id,
                  action: "approved",
                  stall_id: item.stall_id,
                });
                justChangeModal("servicePaymentAction");
              }}
            >
              Approve
            </Button>
            <Button
              color="danger"
              variant="outlined"
              size="sm"
              disabled={
                item.status === "approved" || item.status === "rejected"
              }
              onClick={(e) => {
                e.preventDefault();
                setPaymentActionStatus({
                  id: item.id,
                  action: "rejecteded",
                  stall_id: item.stall_id,
                });
                justChangeModal("servicePaymentAction");
              }}
            >
              Reject
            </Button>
          </>
        );
      });
    }
  }, [selectedStallPayments]);

  let default_unit =
    selectedExhibition?.measurement_unit === "sqm" ? "Sq M" : "Sq Ft";

  if (paymentsListData) {
    paymentsListData?.results?.map((item, index) => {
      item.bookBtn = (
        <Button
          color="primary"
          onClick={(e) => openPaymentModal(e, item)}
          size="sm"
        >
          Add Payment
        </Button>
      );
      item.viewBtn = (
        <ButtonGroup>
          <Button
            color="info"
            onClick={(e) => openStallPaymentModal(e, item)}
            size="sm"
          >
            Stall Payments
          </Button>
          <Button
            color="default"
            onClick={(e) => openServicePaymentModal(e, item)}
            size="sm"
          >
            Service Payments
          </Button>
        </ButtonGroup>
      );
      item.status_pill = (
        <Badge
          className={`${stallStatues_color[item.stall_status]}  badge-lg text-white`}
        >
          {stallStatues[item.stall_status]}
        </Badge>
      );
      item.price =
        parseInt(item.price_per_sq_unit) *
        parseInt(item.width) *
        parseInt(item.length);
    });
  }

  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        {!selectedExhibition && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <NoData title="For stalls, Please set up an exhibition." />
              </Card>
            </div>
          </Row>
        )}
        {selectedExhibition && (
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="12" md={6}>
                      <h3 className="mb-0">
                        Stall Payments -{" "}
                        {paymentStatus ===
                          ["payment_pending", "approved", "all_ok"] &&
                          "All"}{" "}
                        {paymentStatus === ["payment_pending", "approved"] &&
                          "Pending"}{" "}
                        {paymentStatus === ["all_ok"] && "Paid"}
                      </h3>
                    </Col>
                    <Col xs={12} md={6}>
                      <ButtonGroup className={"w-100"}>
                        <Button
                          color={"primary"}
                          onClick={() => {
                            setPaymentStatus([
                              "payment_pending",
                              "approved",
                              "all_ok",
                            ]);
                            setIsActionItems(false);
                          }}
                        >
                          All
                        </Button>
                        <Button
                          color={"info"}
                          onClick={() => {
                            setPaymentStatus(["payment_pending", "approved"]);
                            setIsActionItems(true);
                          }}
                        >
                          Action Items
                        </Button>
                        <Button
                          color={"warning"}
                          onClick={() => {
                            setPaymentStatus(["payment_pending", "approved"]);
                            setIsActionItems(false);
                          }}
                        >
                          Pending
                        </Button>
                        <Button
                          color={"success"}
                          onClick={() => {
                            setPaymentStatus(["all_ok"]);
                            setIsActionItems(false);
                          }}
                        >
                          Paid
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                {!paymentsListData?.results ? (
                  <NoData title="No data found" />
                ) : (
                  <ToolkitProvider
                    data={paymentsListData?.results}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Stall Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "price",
                        text: "Price",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "status_pill",
                        text: "Status",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "bookBtn",
                        text: "Payment",
                        sort: false,
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                      {
                        dataField: "viewBtn",
                        text: "View",
                        sort: false,
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </Col>
          </Row>
        )}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={modal}
          onExit={() => {
            modalClose();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default Payments;
