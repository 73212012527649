// reactstrap components
import { useEffect, useRef, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  FormGroup,
  CardBody,
  Button,
  CardHeader,
  Table,
  ButtonGroup,
  Modal,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormFeedback,
} from "reactstrap";
import AdminHeader from "components/Headers/AdminHeader";
import {
  useCreateServicePayment,
  useServiceAddToStall,
  useStallsList,
} from "shared/utility/services/hooks/stalls";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NoData from "shared/common/NoData";
import { useEditStalls } from "shared/utility/services/hooks/stalls";
import { useServicesList } from "shared/utility/services/hooks/exhibition";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { required_documents } from "../../../shared/utility/helpers";
import Createable from "react-select/creatable";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import { sortCarets } from "../../../shared/utility/helpers";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show{" "}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm d-inline w-auto mx-1"
          defaultValue={"25"}
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{" "}
        entries.
      </label>
    </div>
  ),
});
const ServicesExhibitor = () => {
  const selectedExhibition = useSelector(selectExhibition);
  const [stallData, setStallData] = useState(null);
  const [query, setQuery] = useState({
    limit: 1,
    skip: 0,
    exhibition_id: selectedExhibition?.id,
  });
  const [service, setService] = useState({ id: "", qty: 0 });
  const [services, setServices] = useState([]);
  const [isAnyPaymentApproved, setIsAnyPaymentApproved] = useState(false);
  const [isServicePaymentApproved, setIsServicePaymentApproved] =
    useState(false);

  // list stalls
  const {
    data: stallsListData,
    isLoading: stallsListLoading,
    isError: stallsListIsError,
    error: stallsListError,
    mutate: stallsList,
  } = useStallsList();

  const isPaymentApproved = (stallsListData) => {
    return stallsListData.payments.some(
      (payment) => payment.status === "approved",
    );
  };
  const didServicePaymentApproved = (stallsListData) => {
    return stallsListData.service_payments.some(
      (payment) => payment.status === "approved",
    );
  };
  const didAllServicePaymentApproved = (stallsListData) => {
    let totalPaidAmount = stallsListData?.service_payments.reduce(
      (acc, payment) => {
        if (payment.status === "approved") {
          return acc + payment.amount_paid;
        }
        return acc;
      },
      0,
    );
    setTotalApprovedAmount(totalPaidAmount);
    if (totalPaidAmount >= totalServiceAmount) {
      return true;
    } else {
      return false;
    }
  };

  const [servicePaymentList, setServicePaymentList] = useState();
  useEffect(() => {
    console.log(servicePaymentList, "servicePaymentList update");
  }, [servicePaymentList]);
  const [AllServicePaymentDone, setAllServicePaymentDone] = useState(false);
  useEffect(() => {
    try {
      if (stallsListData && !stallsListLoading) {
        if (stallsListData.results.length) {
          setStallData(stallsListData?.results?.[0]);
          if (stallsListData.results[0]?.service_payments?.length > 0) {
            let service_payment_status = didServicePaymentApproved(
              stallsListData.results[0],
            );
            setAllServicePaymentDone(
              didAllServicePaymentApproved(stallsListData?.results[0]),
            );
            console.log("run thaui che a ");
            console.log(service_payment_status, "service_payment_status");
            setIsServicePaymentApproved(service_payment_status);
            setServicePaymentList(stallsListData.results[0]?.service_payments);
          }
          console.log(
            stallsListData.results[0],
            "stallsListData.results[0].payment",
          );
          let approve_status = isPaymentApproved(stallsListData.results[0]);
          setIsAnyPaymentApproved(approve_status);
          console.log("payment", isAnyPaymentApproved);
          setServices(stallsListData.results[0].services);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stallsListData, stallsListLoading, stallsListIsError, stallsListError]);

  // edit stalls
  const {
    data: editStallsData,
    isLoading: editStallsLoading,
    isError: editStallsIsError,
    error: editStallsError,
    mutate: editStalls,
  } = useEditStalls();

  useEffect(() => {
    try {
      if (editStallsData && !editStallsLoading) {
        toast.success("Services updated successfully");
        setService({ id: "", qty: 0 });
        setServices([]);
        stallsList(query);
      }
      if (editStallsIsError) {
        stallsList(query);
        toast.error(
          editStallsError?.response?.data?.detail ?? "Something went wrong",
        );
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStallsData, editStallsLoading, editStallsIsError]);

  const {
    data: servicesListData,
    isLoading: servicesListLoading,
    isError: servicesListIsError,
    error: servicesListError,
    mutate: servicesList,
  } = useServicesList();

  useEffect(() => {
    if (servicesListData && !servicesListLoading) {
      // setServices(servicesListData)
    }
  }, [
    servicesListData,
    servicesListLoading,
    servicesListIsError,
    servicesListError,
  ]);
  let serviceListOptions = [];
  if (servicesListData) {
    serviceListOptions = servicesListData.map((service) => ({
      id: service.id,
      name: service.name,
      price: service.price,
      value: service,
      label: service.name,
    }));
  }
  useEffect(() => {
    if (!stallsListLoading && selectedExhibition?.id) {
      stallsList(query);
      servicesList({ exhibition_id: selectedExhibition?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!stallsListLoading && selectedExhibition?.id) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition?.id]);

  const {
    data: addServiceData,
    isLoading: addServiceLoading,
    isError: addServiceIsError,
    error: addServiceError,
    mutate: addService,
  } = useServiceAddToStall();
  useEffect(() => {
    if (addServiceData) {
      toast.success("Service added successfully");
      stallsList(query);
    }
  }, [addServiceData]);
  const onServiceAdd = () => {
    if (!service.id || service.qty === 0) {
      toast.error("Please select service and quantity");
      return;
    }
    let tempService = services;
    tempService.push({ ...service });
    // editStalls({ services: tempService, id: stallData?.id })
    stallsList(query);
    addService({
      stall_id: stallData?.id,
      service: { id: service.id, qty: service.qty },
    });
    setService({ id: "", qty: 0 });
  };

  const deleteService = (obj) => {
    let tempService = services.filter((item) => item.uuid !== obj.uuid);
    editStalls({ services: tempService, id: stallData?.id });
  };

  const findService = (serviceId) => {
    return servicesListData?.find((service) => service.id === serviceId);
  };
  useEffect(() => {
    console.log("service", service);
  }, [service]);

  const [totalServiceAmount, setTotalServiceAmount] = useState(0);
  const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);
  useEffect(() => {
    let totalPaymentServices = 0;
    if (services) {
      services?.map?.((obj, index) => {
        let quantity = Number(obj.quantity) || 0;
        let price = Number(obj.service?.price) || 0;
        totalPaymentServices += quantity * price;
      });
    }
    console.log(totalPaymentServices, "totalPaymentServices");
    setTotalServiceAmount(totalPaymentServices);
    formik.setFieldValue("amount", totalPaymentServices);
    formik.setFieldValue("stall_id", stallData?.id);
    /* setAllServicePaymentDone(
      didAllServicePaymentApproved(stallsListData?.results[0]),
    );*/
  }, [services]);
  useEffect(() => {
    console.log(totalServiceAmount, "totalServiceAmount");
  }, [totalServiceAmount]);

  const initialValues = {
    stall_id: "",
    amount_paid: 0,
    payment_type: "",
    amount: 0,
  };
  const [paymentProof, setPaymentProof] = useState(null);

  const {
    data: createPaymentData,
    isLoading: createPaymentLoading,
    isError: createPaymentIsError,
    error: createPaymentError,
    mutate: createPayment,
  } = useCreateServicePayment();

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      stall_id: Yup.string().required("Required"),
      amount_paid: Yup.string().required("Required"),
      payment_type: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // participationDownload(values);
      console.log("submit called");
      console.log({ values, paymentProof }, "valuesss");
      stallsList(query);
      createPayment({ values, paymentProof });
      modalClose();
    },
  });
  let modalStatus = {
    payment: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Upload Payment Proof
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Form
              onSubmit={() => {
                formik.handleSubmit();
                console.log("form submit action called");
              }}
            >
              <div className="d-flex align-items-center flex-column">
                <div className={"container-fluid"}>
                  <Row>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Total Price
                        </label>
                        {/*<p>{stallData?.price_per_sq_unit}</p>*/}
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa-solid fa-calculator "></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=""
                            type="number"
                            name="amount"
                            value={formik.values.amount}
                            disabled={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Payment Amount
                        </label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa-solid  fa-calculator"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Payment Amount"
                            type="number"
                            name="amount_paid"
                            value={formik.values.amount_paid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.amount_paid &&
                              formik.errors.amount_paid
                            }
                          />
                          <FormFeedback>
                            {formik.errors.amount_paid}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Payment Type
                        </label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa-solid  fa-text-width"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Mode Of Payment"
                            type="text"
                            name="payment_type"
                            value={formik.values.payment_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.payment_type &&
                              formik.errors.payment_type
                            }
                          />
                          <FormFeedback>
                            {formik.errors.payment_type}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup className="mb-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-status"
                        >
                          Payment Proof
                        </label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="Upload Payment Proof"
                            type="file"
                            name="qty"
                            onChange={(e) => {
                              setPaymentProof(e.target.files[0]);
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button
                  color="primary"
                  type="button"
                  disabled={createPaymentLoading}
                  onClick={() => {
                    formik.handleSubmit();
                    console.log("form submit action called");
                  }}
                >
                  Pay now
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
  };
  const [openCreateExhibitionModal, setOpenCreateExhibitionModal] =
    useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState("payment");
  function modalClose() {
    modalToogle();
    console.log("close");
    // formik.handleReset()
  }
  const modalToogle = (status) => {
    setOpenCreateExhibitionModal((prev) => !prev);
    setActiveModalStatus(status);
  };

  let componentRef = useRef();
  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        <Col>
          {!stallsListData?.results?.[0] ? (
            <Card className="shadow">
              <NoData title="No specific stall has been assigned yet." />
            </Card>
          ) : (
            <>
              <Card className="shadow border-0">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0 text-capitalize">
                        {stallData?.name} Stall - Services
                      </h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      {isAnyPaymentApproved && (
                        <Button
                          color="primary"
                          onClick={() => {
                            modalToogle("payment");
                          }}
                          disabled={totalServiceAmount === totalApprovedAmount}
                        >
                          Pay
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                {isServicePaymentApproved ? (
                  <>
                    <Row className="mx-2">
                      {/* <Col lg="12">
                        {servicePaymentList ? (
                          <div>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Date </th>
                                  <th scope="col">Amount</th>
                                  <th scope={"col"}>Payment Type</th>
                                  <th scope={"col"}>Payment Proof</th>
                                </tr>
                              </thead>
                              <tbody>
                                {servicePaymentList?.map?.((obj, index) => {
                                  return (
                                    <tr key={index}>
                                      TODO add service description using tooltip or aria description
                                      <td>
                                        {new Date(
                                          new Date(obj?.updated_at).getTime() +
                                            (5 * 60 + 30) * 60000,
                                        ).toLocaleString("en-GB")}
                                      </td>
                                      <td>{obj?.amount_paid}</td>
                                      <td>{obj?.payment_type}</td>
                                      <td>
                                        <Button
                                          size={"sm"}
                                          color={"primary"}
                                          href={obj?.payment_proof}
                                        >
                                          Proof
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <hr />*/}
                      <Row className={"my-3 justify-content-center col-lg-12"}>
                        <Col lg={"4"}>
                          <Card
                            className={
                              "shadow-lg h-100 align-items-center bg-lighter"
                            }
                          >
                            <CardBody
                              className={
                                "d-flex flex-column text-center align-items-center text-darker h-100"
                              }
                            >
                              <h4>Total Service Amount</h4>
                              <h4>{totalServiceAmount}</h4>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={"4"}>
                          <Card
                            className={
                              "shadow-lg h-100 align-items-center bg-lighter"
                            }
                          >
                            <CardBody
                              className={
                                "d-flex flex-column text-center align-items-center text-darker h-100"
                              }
                            >
                              <h4>Paid Service Amount</h4>
                              <h4>{totalApprovedAmount}</h4>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Col lg={12} className={"mt-3"}>
                        <h3>
                          Your Payment For Below Listed Services is Approved
                        </h3>
                      </Col>
                      <hr />
                      <Col lg="12">
                        {services.length ? (
                          <div>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Quantity</th>
                                  <th scope={"col"}>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {services?.map?.((obj, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {`${obj?.service?.name} at Rs ${obj?.service?.price}`}
                                      </td>
                                      <td>{obj?.quantity}</td>
                                      <td>
                                        {obj?.quantity * obj?.service?.price}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {isAnyPaymentApproved ? (
                      <>
                        <CardBody className="px-lg-5 py-lg-3">
                          <Row className="mt-3">
                            <Col lg="6" sm={"12"}>
                              <FormGroup className="mb-3">
                                <Createable
                                  options={serviceListOptions}
                                  name={"Service"}
                                  id={"Service"}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,

                                      height: "100%",
                                      minHeight: "50px",
                                    }),
                                  }}
                                  onChange={(value) => {
                                    setService({
                                      ...service,
                                      id: value.id,
                                      name: value.name,
                                      price: value.price,
                                    });
                                  }}
                                  value={{
                                    label: service.name,
                                    value: service,
                                  }}
                                  // className={"h-75"}
                                  label="Service"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6" sm={"12"} className={"d-flex"}>
                              <Input
                                className="mr-3 w-25 h-75"
                                placeholder="Quantity *"
                                type="number"
                                name="quantity"
                                value={service.qty}
                                onChange={(e) => {
                                  setService({
                                    ...service,
                                    qty: Number(e.target.value),
                                  });
                                }}
                              />

                              <Button
                                className="m-2"
                                color="primary"
                                onClick={onServiceAdd}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                          <hr />
                          <Row className={"mx-3 justify-content-center"}>
                            <Col lg={"4"}>
                              <Card
                                className={
                                  "shadow-lg h-100 align-items-center bg-lighter"
                                }
                              >
                                <CardBody
                                  className={
                                    "d-flex flex-column text-center align-items-center text-darker h-100"
                                  }
                                >
                                  <h4>Total Service Amount</h4>
                                  <h4>{totalServiceAmount}</h4>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <hr />
                          <Row className="mx-2">
                            <Col lg="12">
                              {services.length ? (
                                <div>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope={"col"}>Total</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {services?.map?.((obj, index) => {
                                        return (
                                          <tr key={index}>
                                            {/*TODO add service description using tooltip or aria description*/}
                                            <td>
                                              {`${obj?.service?.name} at Rs ${obj?.service?.price}`}
                                            </td>
                                            <td>{obj?.quantity}</td>
                                            <td>
                                              {obj?.quantity *
                                                obj?.service?.price}
                                            </td>
                                            <td>
                                              {obj?.service_payment ? (
                                                "Already Paid"
                                              ) : (
                                                <ButtonGroup>
                                                  <Button
                                                    color="danger"
                                                    onClick={(e) =>
                                                      deleteService(obj)
                                                    }
                                                    size="sm"
                                                  >
                                                    Remove
                                                  </Button>
                                                </ButtonGroup>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </>
                    ) : (
                      <CardBody className={"px-lg-5 py-lg-5 text-center m-4"}>
                        <h3
                          className={
                            "mb-0 text-capitalize mb-4 border border-danger rounded text-dark p-3"
                          }
                        >
                          Please pay For stall or wait for payment approval to
                          add services
                        </h3>
                        <div className={"p-3 m-3"}>
                          <Button
                            color="primary"
                            onClick={() => {
                              // useNavigate()
                            }}
                          >
                            Go To Stall
                          </Button>
                        </div>
                      </CardBody>
                    )}
                  </>
                )}
              </Card>
              {servicePaymentList ? (
                <Card className={"mt-3 rounded"}>
                  <CardHeader>
                    <h3>Payment History</h3>
                  </CardHeader>
                  <CardBody>
                    <ToolkitProvider
                      data={servicePaymentList}
                      keyField="id"
                      columns={[
                        {
                          dataField: "updated_at",
                          text: "Date",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          formatter: (cell) => {
                            return new Date(
                              new Date(cell).getTime() + (5 * 60 + 30) * 60000,
                            ).toLocaleString("en-GB");
                          },
                        },
                        {
                          dataField: "amount_paid",
                          text: "Amount",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                        },
                        { dataField: "status", text: "Status" },
                        {
                          dataField: "payment_type",
                          text: "Payment Type",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                        },

                        {
                          dataField: "payment_proof",
                          text: "Payment Proof",
                          sort: true,
                          sortCaret: (order, column) =>
                            sortCarets(order, column),
                          headerStyle: { textAlign: "center" },
                          formatter: (cell) => {
                            return (
                              <Button size={"sm"} color={"primary"} href={cell}>
                                Proof
                              </Button>
                            );
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                      ]}
                      search
                    >
                      {(props) => (
                        <div className="py-4 px-4 table-responsive">
                          <BootstrapTable
                            ref={componentRef}
                            {...props.baseProps}
                            bootstrap4={true}
                            classes={"mb-3"}
                            pagination={pagination}
                            bordered={true}
                            hover
                            condensed
                            rowStyle={{ textAlign: "center" }}
                            id="react-bs-table"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    {/*<Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Date </th>
                          <th scope="col">Amount</th>
                          <th scope={"col"}>Payment Type</th>
                          <th scope={"col"}>Payment Proof</th>
                        </tr>
                      </thead>
                      <tbody>
                        {servicePaymentList?.map?.((obj, index) => {
                          return (
                            <tr key={index}>
                              TODO add service description using tooltip or aria description
                              <td>
                                {new Date(
                                  new Date(obj?.updated_at).getTime() +
                                    (5 * 60 + 30) * 60000,
                                ).toLocaleString("en-GB")}
                              </td>
                              <td>{obj?.amount_paid}</td>
                              <td>{obj?.payment_type}</td>
                              <td>
                                <Button
                                  size={"sm"}
                                  color={"primary"}
                                  href={obj?.payment_proof}
                                >
                                  Proof
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>*/}
                  </CardBody>
                </Card>
              ) : (
                <></>
              )}
            </>
          )}
        </Col>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={openCreateExhibitionModal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default ServicesExhibitor;
