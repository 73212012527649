// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useCreateEXhibition,
  useExhibitionList,
  useEditExhibition,
  useAddImageExhibition,
} from "shared/utility/services/hooks/exhibition";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Table,
  ButtonGroup,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { recallExhibition, selectUser } from "redux/reducers/authSlice";

import { useLocation } from "react-router-dom";
import { useDeleteExhibition } from "shared/utility/services/hooks/exhibition";
import ReactDatetime from "react-datetime";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
//for creatable select
import {
  DatePicker,
  LocalizationProvider,
  TimeField,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Createable from "react-select/creatable";
import {
  categories_options,
  create_exhibition_popovers,
  exhibition_locations,
  required_documents,
  exhibition_allowed_tags,
  sortCarets,
} from "shared/utility/helpers";
import { useLogin } from "../../shared/utility/services/hooks/signin";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm d-inline w-auto mx-1"
            defaultValue={"25"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Exhibition = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [openCreateExhibitionModal, setOpenCreateExhibitionModal] =
    useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState("add");
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const { state } = useLocation();
  const selectedUser = useSelector(selectUser);

  useEffect(() => {
    if (state?.add) {
      addExhibitionModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.add]);

  const modalToogle = (status) => {
    setOpenCreateExhibitionModal((prev) => !prev);
    setActiveModalStatus(status);
  };
  const openModal = (status) => {
    setActiveModalStatus(status);
  };

  // list exhibition
  const {
    data: exhibitionListData,
    isLoading: exhibitionListLoading,
    isError: exhibitionListIsError,
    error: exhibitionListError,
    mutate: exhibitionList,
  } = useExhibitionList();

  const [exhib_limit_reached, setExhib_limit_reached] = useState(false);
  useEffect(() => {
    if (exhibitionListData) {
      if (exhibitionListData?.total_count >= selectedUser?.exhibition_limit) {
        setExhib_limit_reached(true);
      }
    }
  }, [exhibitionListData]);
  let exhibitionListNew = [];
  useEffect(() => {
    if (!exhibitionListLoading) {
      exhibitionList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  if (exhibitionListData) {
    exhibitionListData.results.map((item) => {
      item.end_time_hr = item.end_time.split(":")[0];
      item.end_time_min = item.end_time.split(":")[1];
      item.start_time_hr = item.start_time.split(":")[0];
      item.start_time_min = item.start_time.split(":")[1];
      item.viewBtn = (
        <ButtonGroup>
          <Button
            color="success"
            onClick={(e) => viewExhibitionModalOpen(item)}
            size="sm"
          >
            View
          </Button>
          <Button
            color="primary"
            onClick={(e) => editExhibitionModalOpen(item)}
            size="sm"
          >
            Edit
          </Button>
          <Button
            color="warning"
            onClick={(e) => editExhibitionModalOpen(item)}
            size="sm"
          >
            Edit Logo
          </Button>
          <Button
            color="info"
            onClick={(e) => additionalInfoModalOpen(item)}
            size="sm"
          >
            Additional Details
          </Button>
        </ButtonGroup>
      );
    });
    exhibitionListNew = exhibitionListData.results;
  }

  // create exhibition
  const {
    data: createExhibitionData,
    isLoading: createExhibitionLoading,
    isError: createExhibitionIsError,
    error: createExhibitionError,
    mutate: createExhibition,
  } = useCreateEXhibition();

  const {
    data: addImageExhibitionData,
    isLoading: addImageExhibitionLoading,
    isError: addImageExhibitionIsError,
    error: addImageExhibitionError,
    mutate: addImageExhibition,
  } = useAddImageExhibition();
  useEffect(() => {
    if (addImageExhibitionData) {
      setExhibitionLogo(null);
      dispatch(recallExhibition());
      modalClose();
    }
  }, [addImageExhibitionData]);
  useEffect(() => {
    try {
      console.log("it", createExhibitionData?.id);
      if (createExhibitionData?.id && !createExhibitionLoading) {
        // modalToogle();
        toast.success("Exhibition created successfully");
        dispatch(recallExhibition());
        console.log("create exhib 200");
        formik.handleReset();
        console.log("createExhibitionData id", createExhibitionData.id);
        setAddImageExhib(createExhibitionData.id);
        setActiveModalStatus("add_image");
      } else if (
        createExhibitionData?.status === 400 &&
        !createExhibitionLoading
      ) {
        console.log(createExhibitionData.body);
        toast.error(createExhibitionData?.response);
      } else if (createExhibitionData?.error && !createExhibitionLoading) {
        console.log(createExhibitionData.error);
        toast.error(createExhibitionData.error);
      }
      if (createExhibitionIsError) {
        console.log("createExhibitionError", createExhibitionError);
        toast.error(createExhibitionError?.response?.data?.error);
      }
      exhibitionList(query);
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExhibitionData, createExhibitionLoading, createExhibitionIsError]);

  // edit exhibition
  const {
    data: editExhibitionData,
    isLoading: editExhibitionLoading,
    isError: editExhibitionIsError,
    error: editExhibitionError,
    mutate: editExhibition,
  } = useEditExhibition();

  useEffect(() => {
    try {
      if (editExhibitionData && !editExhibitionLoading) {
        toast.success("Exhibition updated successfully");

        formik.handleReset();
        modalToogle();
        dispatch(recallExhibition());
        exhibitionList(query);
      }
      if (editExhibitionIsError) {
        toast.error(editExhibitionError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editExhibitionData, editExhibitionLoading, editExhibitionIsError]);

  // delete exhibition
  const {
    data: deleteExhibitionData,
    isLoading: deleteExhibitionLoading,
    isError: deleteExhibitionIsError,
    error: deleteExhibitionError,
    mutate: deleteExhibition,
  } = useDeleteExhibition();

  useEffect(() => {
    try {
      if (deleteExhibitionData && !deleteExhibitionLoading) {
        toast.success("Exhibition deleted successfully");
        exhibitionList(query);
        formik.handleReset();
        dispatch(recallExhibition());
      }
      if (deleteExhibitionIsError) {
        toast.error(deleteExhibitionError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteExhibitionData, deleteExhibitionLoading, deleteExhibitionIsError]);

  const initialValues = {
    name: "",
    venue: "",
    address: "",
    timeout_days: "",
    required_documents: [],
    exhibitor_reg_start_date: "",
    exhibitor_reg_end_date: "",
    visitor_reg_end_date: "",
    visitor_reg_start_date: "",
    start_date: "",
    end_date: "",
    measurement_unit: "sqm",
    category: [],
    allowed_tags: [],
    allowed_services: [],
    allowed_hall_or_dome: [],
    start_time: "",
    end_time: "",
    start_time_hr: "",
    start_time_min: "",
    end_time_hr: "",
    end_time_min: "",
  };
  const tnc_initialValues = {
    tnc: "",
    id: 0,
  };
  // const [exhibitionLogo , setExhibitionLogo] = useState(new File([""], 'TEST.png',{type: 'image/png'}))
  const [exhibitionLogo, setExhibitionLogo] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      venue: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      required_documents: Yup.array()
        .required("Required")
        .test("is-greate-1", function (value) {
          const allowed_tags = this.resolve(Yup.ref("required_documents"));
          if (allowed_tags.length < 1) {
            return this.createError({
              message: "Atleast 1 Document is required",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      timeout_days: Yup.number().required("Required"),
      exhibitor_reg_start_date: Yup.date()
        .required("Required")
        .test("is-past-date", function (value) {
          const start_date = this.resolve(Yup.ref("start_date"));
          if (moment(value).isAfter(start_date)) {
            return this.createError({
              message: "date should be less than start date",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      exhibitor_reg_end_date: Yup.date()
        .required("Required")
        .test("is-future-date", function (value) {
          const exhibitor_reg_start_date = this.resolve(
            Yup.ref("exhibitor_reg_start_date"),
          );

          const start_date = this.resolve(Yup.ref("start_date"));

          if (moment(value).isBefore(exhibitor_reg_start_date)) {
            return this.createError({
              message: "date should be greater than Exhibitor start date",
              path: this.path,
            });
          }
          if (moment(value).isAfter(start_date)) {
            return this.createError({
              message: "date should be less than start date",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      visitor_reg_end_date: Yup.date()
        .required("Required")
        .test("is-future-date", function (value) {
          const visitor_reg_start_date = this.resolve(
            Yup.ref("visitor_reg_start_date"),
          );
          const end_date = this.resolve(Yup.ref("end_date"));

          if (moment(value).isBefore(visitor_reg_start_date)) {
            return this.createError({
              message: "date should be greater than Visitor start date",
              path: this.path,
            });
          }
          if (moment(value).isAfter(end_date)) {
            return this.createError({
              message: "date should be less than start date",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      visitor_reg_start_date: Yup.date()
        .required("Required")
        .test("is-past-date", function (value) {
          const start_date = this.resolve(Yup.ref("start_date"));
          if (moment(value).isAfter(start_date)) {
            return this.createError({
              message: "date should be less than start date",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      start_date: Yup.date().required("Required"),
      end_date: Yup.date()
        .required("Required")
        .test("is-future-date", function (value) {
          const start_date = this.resolve(Yup.ref("start_date"));
          if (moment(value).isBefore(start_date)) {
            return this.createError({
              message: "End date should be greater than start date",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      category: Yup.array()
        .required("Required")
        .test("is-greate-1", function (value) {
          const cat = this.resolve(Yup.ref("category"));
          if (cat.length < 1) {
            return this.createError({
              message: "Atleast 1 Category required",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      allowed_tags: Yup.array()
        .required("Required")
        .test("is-greate-1", function (value) {
          const allowed_tags = this.resolve(Yup.ref("allowed_tags"));
          if (allowed_tags.length < 1) {
            return this.createError({
              message: "Atleast 1 tag required",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      allowed_hall_or_dome: Yup.array()
        .required("Required")
        .test("is-greate-1", function (value) {
          const allowed_hall_or_dome = this.resolve(
            Yup.ref("allowed_hall_or_dome"),
          );
          if (allowed_hall_or_dome.length < 1) {
            return this.createError({
              message: "Atleast 1 Hall/Dome required",
              path: this.path,
            });
          } else {
            return true;
          }
        }),
      start_time_hr: Yup.string().required("Required"),
      start_time_min: Yup.string().required("Required"),
      end_time_hr: Yup.string().required("Required"),
      end_time_min: Yup.string().required("Required"),
      // .min(Yup.ref("category"), "End time should be greater than start time"),
    }),
    onSubmit: async (values) => {
      console.log(values);

      /*
            let formData = new FormData();
            for (let key in values) {
              if (key === 'category' || key === 'allowed_tags' || key === 'allowed_hall_or_dome' || key === 'required_documents') {
                /!*values[key].map((item) => {
                formData.append(key, item)
                })*!/
                formData.append(key, values[key])
              } else {
                formData.append(key, values[key])
              }
            }*/
      /*      if (exhibitionLogo) {
                    logoFormData.append('file', exhibitionLogo)
                  }
                  console.log("formData++++++++++++++")
                  console.log(logoFormData)
            */

      const data_to_send = {
        ...values,
        start_time: `${values.start_time_hr}:${values.start_time_min}`,
        end_time: `${values.end_time_hr}:${values.end_time_min}`,
      };
      if (!data_to_send.id) {
        delete data_to_send.viewBtn;
        delete data_to_send.start_time_hr;
        delete data_to_send.start_time_min;
        delete data_to_send.end_time_hr;
        delete data_to_send.end_time_min;
        if (exhibitionLogo) {
          console.log("this is called - with logo");
          createExhibition({ jsonData: data_to_send, image: exhibitionLogo });
        } else {
          createExhibition({ jsonData: data_to_send });
        }
      } else {
        console.log("edit");
        delete data_to_send.logo;
        delete data_to_send.viewBtn;
        delete data_to_send.start_time_hr;
        delete data_to_send.start_time_min;
        delete data_to_send.end_time_hr;
        delete data_to_send.end_time_min;
        console.log(data_to_send);
        editExhibition(data_to_send);
        if (exhibitionLogo) {
          addImageExhibition({
            exhibition_id: data_to_send.id,
            image: exhibitionLogo,
          });
        }
      }
    },
  });
  const formikTNC = useFormik({
    initialValues: tnc_initialValues,
    values: tnc_initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      tnc: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);

      /*
            let formData = new FormData();
            for (let key in values) {
              if (key === 'category' || key === 'allowed_tags' || key === 'allowed_hall_or_dome' || key === 'required_documents') {
                /!*values[key].map((item) => {
                formData.append(key, item)
                })*!/
                formData.append(key, values[key])
              } else {
                formData.append(key, values[key])
              }
            }*/
      /*      if (exhibitionLogo) {
                    logoFormData.append('file', exhibitionLogo)
                  }
                  console.log("formData++++++++++++++")
                  console.log(logoFormData)
            */

      const data_to_send = {
        id: values.id,
        tnc: values.tnc,
      };
      editExhibition(data_to_send);
    },
  });
  const modalClose = () => {
    setActiveModalStatus("add");
    setOpenCreateExhibitionModal(false);
    setModalIsEdit(false);
    formik.handleReset();
  };
  const [verifyPassword, setVerifyPassword] = useState("");

  const {
    data: loginData,
    isLoading: loginLoading,
    isError: loginIsError,
    error: loginError,
    mutate: login,
  } = useLogin();
  const user = useSelector(selectUser);
  const verifyUser = () => {
    console.log(user.email);
    console.log(verifyPassword);
    const verificationData = new FormData();
    verificationData.append("username", user.email);
    verificationData.append("password", verifyPassword);
    login(verificationData);
  };
  useEffect(() => {
    if (loginError) {
      toast.error("Incorrect Password");
      modalClose();
    }
    if (loginData && !loginLoading) {
      openModal("add");
      setVerifyPassword("");
    }
  }, [loginData, loginLoading, loginIsError]);
  const [addImageExhib, setAddImageExhib] = useState(null);
  const handleAddImageToExhibition = () => {
    addImageExhibition({ exhibition_id: addImageExhib, image: exhibitionLogo });
  };
  let requiredDocumentsArray = formik.values.required_documents.map((item) => ({
    value: item,
    label: item,
  }));

  const formatToTwoDigits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const [modalIsEdit, setModalIsEdit] = useState(false);
  let modalStatus = {
    add_image: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Card className="bg-secondary shadow border-0">
            <div className="modal-header">
              <h3 className="modal-title" id="modal-title-default">
                Add Exhibition Logo
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={modalClose}
              >
                <span aria-hidden={true} className="close-button-icon">
                  ×
                </span>
              </button>
            </div>
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <Col lg={12}>
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-logo">
                      Exhibition Logo
                      <i
                        className={"fa-solid fa-info-circle ml-3"}
                        data-placement="top"
                        id="create-logo"
                      ></i>
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="create-logo"
                      >
                        {create_exhibition_popovers.logo}
                      </UncontrolledTooltip>
                    </label>

                    <div>
                      {exhibitionLogo?.logo && (
                        <img
                          src={exhibitionLogo || ""}
                          alt="logo"
                          className={"img-thumbnail m-2"}
                          style={{ width: "100px" }}
                        />
                      )}
                    </div>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Exhibition Logo *"
                        type="file"
                        name="logo"
                        value={exhibitionLogo?.filename}
                        onChange={(e) => setExhibitionLogo(e.target.files[0])}
                        required={true}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <Button
                    color="success"
                    onClick={handleAddImageToExhibition}
                    size="sm"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </LocalizationProvider>
      </div>
    ),
    add: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Card className="bg-secondary shadow border-0">
            <div className="modal-header">
              <h3 className="modal-title" id="modal-title-default">
                {formik.values.id && !modalIsEdit && "View Exhibition"}
                {formik.values.id && modalIsEdit && "Edit Exhibition"}

                {!formik.values.id && "Create Exhibition"}
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={modalClose}
              >
                <span aria-hidden={true} className="close-button-icon">
                  ×
                </span>
              </button>
            </div>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-name"
                      >
                        Exhibition name
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create_name"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create_name"
                        >
                          {create_exhibition_popovers.name}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-text-width"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Exhibition name *"
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={formik.touched.name && formik.errors.name}
                          disabled={!modalIsEdit}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col lg={6}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-venue"
                      >
                        Venue name
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-venue"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-venue"
                        >
                          {create_exhibition_popovers.venue}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-text-width"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Venue name *"
                          type="text"
                          name="venue"
                          value={formik.values.venue}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={formik.touched.venue && formik.errors.venue}
                          disabled={!modalIsEdit}
                        />
                        <FormFeedback>{formik.errors.venue}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-venue"
                      >
                        Venue Address
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-venue-addr"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-venue-addr"
                        >
                          {create_exhibition_popovers.address}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-text-width"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Venue Address *"
                          type="textarea"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.address && formik.errors.address
                          }
                          disabled={!modalIsEdit}
                        />
                        <FormFeedback>{formik.errors.address}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-venue"
                      >
                        Unit Of Measurement
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-measurement_unit"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-measurement_unit"
                        >
                          {create_exhibition_popovers.measurement_unit}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-text-width"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Unit Of Mesurement"
                          type="select"
                          name="measurement_unit"
                          value={formik.values.measurement_unit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.measurement_unit &&
                            formik.errors.measurement_unit
                          }
                          disabled={!modalIsEdit}
                        >
                          <option value="sqm" selected>
                            Sq Meter
                          </option>
                          <option value="sqft">Sq Feet</option>
                        </Input>
                        <FormFeedback>
                          {formik.errors.measurement_unit}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-timeout_days"
                      >
                        Hold Timeout Days
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-timeout_days"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-timeout_days"
                        >
                          {create_exhibition_popovers.timeout_days}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-text-width"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Hold Timeout Days *"
                          type="number"
                          name="timeout_days"
                          value={formik.values.timeout_days}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={0}
                          invalid={
                            formik.touched.timeout_days &&
                            formik.errors.timeout_days
                          }
                          disabled={!modalIsEdit}
                        />
                        <FormFeedback>
                          {formik.errors.timeout_days}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-start_date"
                      >
                        Exhibition start date
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-start_date"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-start_date"
                        >
                          {create_exhibition_popovers.start_date}
                        </UncontrolledTooltip>
                      </label>

                      <DatePicker
                        name="start_date"
                        value={moment(formik.values.start_date)}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "start_date",
                            moment(value).format("YYYY-MM-DD"),
                            true,
                          )
                        }
                        onBlur={formik.handleBlur}
                        format="DD/MM/YYYY"
                        minDate={moment()}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            size: "small",
                            error:
                              formik.touched.start_date &&
                              Boolean(formik.errors.start_date),
                            helperText:
                              formik.touched.start_date &&
                              formik.errors.start_date,
                          },
                        }}
                        disabled={!modalIsEdit}
                      />

                      <FormFeedback>{formik.errors.start_date}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-end_date"
                      >
                        Exhibition end date{" "}
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-end_date"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-end_date"
                        >
                          {create_exhibition_popovers.end_date}
                        </UncontrolledTooltip>
                      </label>
                      <DatePicker
                        name="end_date"
                        value={moment(formik.values.end_date)}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "end_date",
                            moment(value).format("YYYY-MM-DD"),
                            true,
                          )
                        }
                        onBlur={formik.handleBlur}
                        format="DD/MM/YYYY"
                        disabled={!formik.values.start_date || !modalIsEdit}
                        minDate={moment(formik.values.start_date)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            size: "small",
                            error:
                              formik.touched.end_date &&
                              Boolean(formik.errors.end_date),
                            helperText:
                              formik.touched.end_date && formik.errors.end_date,
                          },
                        }}
                      />
                      <FormFeedback>{formik.errors.end_date}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-start_time"
                      >
                        Exhibition start time
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-start_time"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-start_time"
                        >
                          {create_exhibition_popovers.start_time}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-clock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <select
                          name={"start_time_hr"}
                          value={formik.values.start_time_hr}
                          onChange={formik.handleChange}
                          className={"form-control"}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.start_time_hr &&
                            Boolean(formik.errors.start_time_hr)
                          }
                          disabled={!modalIsEdit}
                        >
                          <option value={""}>Hour</option>
                          {Array.from({ length: 24 }, (_, i) => i).map(
                            (item) => (
                              <option value={formatToTwoDigits(item)}>
                                {formatToTwoDigits(item)}
                              </option>
                            ),
                          )}
                        </select>
                        <select
                          name={"start_time_min"}
                          value={formik.values.start_time_min}
                          onChange={formik.handleChange}
                          className={"form-control"}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.start_time_min &&
                            Boolean(formik.errors.start_time_min)
                          }
                          disabled={
                            !modalIsEdit || formik.values.start_time_hr === ""
                          }
                        >
                          <option value={""}>Min</option>
                          {Array.from({ length: 60 }, (_, i) => i).map(
                            (item) => (
                              <option value={formatToTwoDigits(item)}>
                                {formatToTwoDigits(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </InputGroup>
                      <FormFeedback>
                        {formik.errors.start_time_min}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-end_time"
                      >
                        Exhibition end time
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-end_time"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-end_time"
                        >
                          {create_exhibition_popovers.end_time}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa-solid fa-clock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <select
                          name={"end_time_hr"}
                          value={formik.values.end_time_hr}
                          onChange={formik.handleChange}
                          className={"form-control"}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.end_time_hr &&
                            Boolean(formik.errors.end_time_hr)
                          }
                          disabled={
                            !modalIsEdit || formik.values.start_time_hr === ""
                          }
                        >
                          <option value={""}>Hour</option>
                          {Array.from({ length: 24 }, (_, i) => i).map(
                            (item) => (
                              <option value={formatToTwoDigits(item)}>
                                {formatToTwoDigits(item)}
                              </option>
                            ),
                          )}
                        </select>
                        <select
                          name={"end_time_min"}
                          value={formik.values.end_time_min}
                          onChange={formik.handleChange}
                          className={"form-control"}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.end_time_min &&
                            Boolean(formik.errors.end_time_min)
                          }
                          disabled={
                            !modalIsEdit || formik.values.end_time_hr === ""
                          }
                        >
                          <option value={""}>Min</option>
                          {Array.from({ length: 60 }, (_, i) => i).map(
                            (item) => (
                              <option value={formatToTwoDigits(item)}>
                                {formatToTwoDigits(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </InputGroup>

                      <FormFeedback>{formik.errors.end_time}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-exhibitor_reg_start_date"
                      >
                        Exhibitor registration start date
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-exhibitor_reg_start_date"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-exhibitor_reg_start_date"
                        >
                          {create_exhibition_popovers.exhibitor_reg_start_date}
                        </UncontrolledTooltip>
                      </label>
                      <DatePicker
                        className="datepicker"
                        name="exhibitor_reg_start_date"
                        value={moment(formik.values.exhibitor_reg_start_date)}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "exhibitor_reg_start_date",
                            moment(value).format("YYYY-MM-DD"),
                            true,
                          )
                        }
                        onBlur={formik.handleBlur}
                        format="DD/MM/YYYY"
                        disabled={!formik.values.start_date || !modalIsEdit}
                        minDate={moment()}
                        maxDate={moment(formik.values.start_date)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            size: "small",
                            error:
                              formik.touched.exhibitor_reg_start_date &&
                              Boolean(formik.errors.exhibitor_reg_start_date),
                            helperText:
                              formik.touched.exhibitor_reg_start_date &&
                              formik.errors.exhibitor_reg_start_date,
                          },
                        }}
                      />
                      <FormFeedback>
                        {formik.errors.exhibitor_reg_start_date}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-exhibitor_reg_end_date"
                      >
                        Exhibitor registration end date
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-exhibitor_reg_end_date"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-exhibitor_reg_end_date"
                        >
                          {create_exhibition_popovers.exhibitor_reg_end_date}
                        </UncontrolledTooltip>
                      </label>
                      <DatePicker
                        name="exhibitor_reg_end_date"
                        value={moment(formik.values.exhibitor_reg_end_date)}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "exhibitor_reg_end_date",
                            moment(value).format("YYYY-MM-DD"),
                            true,
                          )
                        }
                        onBlur={formik.handleBlur}
                        format="DD/MM/YYYY"
                        disabled={
                          !formik.values.exhibitor_reg_start_date ||
                          !modalIsEdit
                        }
                        maxDate={moment(formik.values.start_date).subtract(
                          1,
                          "days",
                        )}
                        minDate={moment(formik.values.exhibitor_reg_start_date)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            size: "small",
                            error:
                              formik.touched.exhibitor_reg_end_date &&
                              Boolean(formik.errors.exhibitor_reg_end_date),
                            helperText:
                              formik.touched.exhibitor_reg_end_date &&
                              formik.errors.exhibitor_reg_end_date,
                          },
                        }}
                      />
                      <FormFeedback>
                        {formik.errors.exhibitor_reg_end_date}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-visitor_reg_start_date"
                      >
                        Visitor registration start date{" "}
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-visitor_reg_start_date"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-visitor_reg_start_date"
                        >
                          {create_exhibition_popovers.visitor_reg_start_date}
                        </UncontrolledTooltip>
                      </label>
                      <DatePicker
                        name="visitor_reg_start_date"
                        value={moment(formik.values.visitor_reg_start_date)}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "visitor_reg_start_date",
                            moment(value).format("YYYY-MM-DD"),
                            true,
                          )
                        }
                        onBlur={formik.handleBlur}
                        format="DD/MM/YYYY"
                        disabled={!formik.values.start_date || !modalIsEdit}
                        minDate={moment()}
                        maxDate={moment(formik.values.end_date)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            size: "small",
                            error:
                              formik.touched.visitor_reg_start_date &&
                              Boolean(formik.errors.visitor_reg_start_date),
                            helperText:
                              formik.touched.visitor_reg_start_date &&
                              formik.errors.visitor_reg_start_date,
                          },
                        }}
                      />

                      <FormFeedback>{formik.errors.end_date}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-visitor_reg_end_date"
                      >
                        Visitor registration end date{" "}
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-visitor_reg_end_date"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-visitor_reg_end_date"
                        >
                          {create_exhibition_popovers.visitor_reg_end_date}
                        </UncontrolledTooltip>
                      </label>
                      <DatePicker
                        name="visitor_reg_end_date"
                        value={moment(formik.values.visitor_reg_end_date)}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "visitor_reg_end_date",
                            moment(value).format("YYYY-MM-DD"),
                            true,
                          )
                        }
                        onBlur={formik.handleBlur}
                        format="DD/MM/YYYY"
                        disabled={
                          !formik.values.visitor_reg_start_date || !modalIsEdit
                        }
                        maxDate={moment(formik.values.end_date)}
                        minDate={moment(formik.values.visitor_reg_start_date)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            size: "small",
                            error:
                              formik.touched.visitor_reg_end_date &&
                              Boolean(formik.errors.visitor_reg_end_date),
                            helperText:
                              formik.touched.visitor_reg_end_date &&
                              formik.errors.visitor_reg_end_date,
                          },
                        }}
                      />

                      <FormFeedback>
                        {formik.errors.visitor_reg_end_date}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-Category"
                      >
                        Exhibition Category
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-category"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-category"
                        >
                          {create_exhibition_popovers.category}
                        </UncontrolledTooltip>
                      </label>
                      <Createable
                        isMulti
                        options={categories_options}
                        name={"category"}
                        id={"category"}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,

                            borderColor:
                              formik.touched.category &&
                              Boolean(formik.errors.category)
                                ? "red"
                                : "grey",
                            height: "100%",
                            minHeight: "40px",
                          }),
                        }}
                        onChange={(value) => {
                          console.log(value);
                          const arrValue = value.map((item) => item.value);
                          formik.setFieldValue("category", arrValue, true);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.category.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error:
                              formik.touched.category &&
                              Boolean(formik.errors.category),
                            helperText:
                              formik.touched.category && formik.errors.category,
                            disabled: !modalIsEdit,
                          },
                        }}
                        disabled={!modalIsEdit}
                      />

                      <FormFeedback>{formik.errors.category}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-allowed_tags"
                      >
                        Tags
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-allowed_tags"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-allowed_tags"
                        >
                          {create_exhibition_popovers.allowed_tags}
                        </UncontrolledTooltip>
                      </label>
                      <Createable
                        isMulti
                        options={exhibition_allowed_tags}
                        components={{ DropdownIndicator: null }}
                        isClearable
                        name={"allowed_tags"}
                        id={"allowed_tags"}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              formik.touched.allowed_tags &&
                              Boolean(formik.errors.allowed_tags)
                                ? "red"
                                : "grey",
                            height: "100%",
                            minHeight: "40px",
                          }),
                        }}
                        onChange={(value) => {
                          console.log(value);
                          const arrValue = value.map((item) => item.value);
                          formik.setFieldValue("allowed_tags", arrValue, true);
                        }}
                        onBlur={formik.handleBlur}
                        aria-errormessage={
                          formik.touched.allowed_tags &&
                          formik.errors.allowed_tags
                        }
                        value={formik.values.allowed_tags.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        error={
                          formik.touched.allowed_tags &&
                          Boolean(formik.errors.allowed_tags)
                        }
                        helperText={
                          formik.touched.allowed_tags &&
                          formik.errors.allowed_tags
                        }
                        disabled={!modalIsEdit}
                      />

                      <FormFeedback>{formik.errors.allowed_tags}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-allowed_hall_or_dome"
                      >
                        Hall/Dome
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-allowed_hall_or_dome"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-allowed_hall_or_dome"
                        >
                          {create_exhibition_popovers.allowed_hall_or_dome}
                        </UncontrolledTooltip>
                      </label>
                      <Createable
                        isMulti
                        options={exhibition_locations}
                        name={"allowed_hall_or_dome"}
                        id={"allowed_hall_or_dome"}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              formik.touched.allowed_hall_or_dome &&
                              Boolean(formik.errors.allowed_hall_or_dome)
                                ? "red"
                                : "grey",
                            height: "100%",
                            minHeight: "40px",
                          }),
                        }}
                        onChange={(value) => {
                          console.log(value);
                          const arrValue = value.map((item) => item.value);
                          formik.setFieldValue(
                            "allowed_hall_or_dome",
                            arrValue,
                            true,
                          );
                        }}
                        onBlur={formik.handleBlur}
                        aria-errormessage={
                          formik.touched.allowed_hall_or_dome &&
                          formik.errors.allowed_hall_or_dome
                        }
                        value={formik.values.allowed_hall_or_dome.map(
                          (item) => ({
                            value: item,
                            label: item,
                          }),
                        )}
                        error={
                          formik.touched.allowed_hall_or_dome &&
                          Boolean(formik.errors.allowed_hall_or_dome)
                        }
                        helperText={
                          formik.touched.allowed_hall_or_dome &&
                          formik.errors.allowed_hall_or_dome
                        }
                        disabled={!modalIsEdit}
                      />

                      <FormFeedback>
                        {formik.errors.allowed_hall_or_dome}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-reqired_documents"
                      >
                        Required Documents
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-reqired_documents"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-allowed_hall_or_dome"
                        >
                          {create_exhibition_popovers.allowed_hall_or_dome}
                        </UncontrolledTooltip>
                      </label>
                      <Createable
                        isMulti
                        options={required_documents}
                        name={"req_docs"}
                        id={"req_docs"}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              formik.touched.required_documents &&
                              Boolean(formik.errors.required_documents)
                                ? "red"
                                : "grey",
                            height: "100%",
                            minHeight: "40px",
                          }),
                        }}
                        onChange={(value) => {
                          console.log(value);
                          const arrValue = value.map((item) => item.value);
                          formik.setFieldValue(
                            "required_documents",
                            arrValue,
                            true,
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.required_documents.map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        error={
                          formik.touched.required_documents &&
                          Boolean(formik.errors.required_documents)
                        }
                        helperText={
                          formik.touched.required_documents &&
                          formik.errors.required_documents
                        }
                        disabled={!modalIsEdit}
                      />

                      <FormFeedback>
                        {formik.errors.required_documents}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                {modalIsEdit && (
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                )}
              </Form>
            </CardBody>
          </Card>
        </LocalizationProvider>
      </div>
    ),
    password_verify: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              {formik.values.id ? "Edit Exhibition" : "Create Exhibition"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col lg={12}>
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-name">
                    Enter Your Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-text-width"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password *"
                      type="password"
                      name="password"
                      value={verifyPassword}
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg={12}>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={verifyUser}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    ),
    additional_info: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Card className="bg-secondary shadow border-0">
            <div className="modal-header">
              <h3 className="modal-title" id="modal-title-default">
                Additional Information
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={modalClose}
              >
                <span aria-hidden={true} className="close-button-icon">
                  ×
                </span>
              </button>
            </div>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={formikTNC.handleSubmit}>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mb-3">
                      <label className="form-control-label" htmlFor="input-tnc">
                        Terms And Conditions
                        <i
                          className={"fa-solid fa-info-circle ml-3"}
                          data-placement="top"
                          id="create-tnc"
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="create-tnc"
                        >
                          {create_exhibition_popovers.terms_and_conditions}
                        </UncontrolledTooltip>
                      </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Terms And Conditions*"
                          type="textarea"
                          name="tnc"
                          value={formikTNC.values.tnc}
                          onChange={formikTNC.handleChange}
                          onBlur={formikTNC.handleBlur}
                          invalid={
                            formikTNC.touched.tnc && formikTNC.errors.tnc
                          }
                          disabled={!modalIsEdit}
                          rows={20}
                        />
                        <FormFeedback>{formikTNC.errors.tnc}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </LocalizationProvider>
      </div>
    ),
  };

  const addExhibitionModalOpen = () => {
    modalToogle("add");
    setModalIsEdit(true);
  };

  const editExhibitionModalOpen = (obj) => {
    modalToogle("password_verify");
    console.log(obj);
    setModalIsEdit(true);
    formik.setValues(obj);
  };
  const additionalInfoModalOpen = (obj) => {
    modalToogle("additional_info");
    console.log(obj);
    setModalIsEdit(true);
    formikTNC.setValues(obj);
  };

  const viewExhibitionModalOpen = (obj) => {
    modalToogle("add");
    console.log(obj);
    setModalIsEdit(false);
    formik.setValues(obj);
  };

  let isAdmin = true;
  return (
    <>
      <AdminHeader />
      <Container className="mt--7 w-100" fluid>
        <Row className={"w-100"}>
          <Card className="shadows w-100">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Exhibition</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>

            {exhibitionListLoading || exhibitionListNew?.length === 0 ? (
              <div
                className={
                  "container-fluid d-flex flex-column justify-content-center align-items-center p-8  "
                }
              >
                <span className={"p-3 text-dark font-weight-bold"}>
                  You Have Not Added Any Data
                </span>

                <Button
                  color="primary"
                  onClick={addExhibitionModalOpen}
                  size="lg"
                >
                  Add Exhibition
                </Button>
              </div>
            ) : (
              <ToolkitProvider
                data={exhibitionListNew || []}
                keyField="id"
                columns={[
                  {
                    dataField: "name",
                    text: "Expo Name",
                    sort: true,
                    sortCaret: (order, column) => sortCarets(order, column),
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    sortCaret: (order, column) => sortCarets(order, column),
                  },
                  {
                    dataField: "start_date",
                    text: "Start Date",
                    sort: true,
                    sortCaret: (order, column) => sortCarets(order, column),
                  },
                  {
                    dataField: "end_date",
                    text: "End Date",
                    sort: true,
                    sortCaret: (order, column) => sortCarets(order, column),
                  },
                  /*{
                          dataField: "link",
                          text: "Link",
                          sort: false,
                          headerStyle: { textAlign: "center" },
                          style: {
                            textAlign: "center",
                          },
                          formatter: (cell, row) => {
                            return (
                              <Button
                                color="primary"
                                size={"sm"}
                                onClick={() => {
                                  window.open(
                                    "https://hypx.in/signup?exhib_id=" +
                                      row.visitor_link,
                                    "_blank",
                                  );
                                }}
                              >
                                Registration
                              </Button>
                            );
                          },
                        },*/

                  {
                    dataField: "viewBtn",
                    text: "View",
                    sort: false,
                    headerStyle: { textAlign: "center" },
                    style: {
                      textAlign: "center",
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 px-4 table-responsive w-100 ">
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-left"
                          >
                            <label>
                              <span>Search:</span>
                              <SearchBar
                                className="form-control-sm ml-2"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Button
                            className={"float-right"}
                            color={exhib_limit_reached ? "warning" : "primary"}
                            id="tooltip611234743"
                            onClick={addExhibitionModalOpen}
                            size="sm"
                            disabled={exhib_limit_reached}
                          >
                            Add Exhibition
                          </Button>
                          {exhib_limit_reached && (
                            <UncontrolledTooltip
                              delay={0}
                              placement="top"
                              target="tooltip611234743"
                            >
                              Exhibition Limit Reached
                            </UncontrolledTooltip>
                          )}
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      classes={"mb-3"}
                      pagination={pagination}
                      bordered={true}
                      hover
                      sort
                      condensed
                      rowStyle={{ textAlign: "center" }}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            )}
          </Card>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={openCreateExhibitionModal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default Exhibition;
