import { useMutation } from "react-query";
import useAxios from "../useAxios";
import { visitRoutes } from "./route";
import { objectToQueryString } from "shared/utility/helpers";

const { ticket_template, ticket } = visitRoutes;

export const useListTicketTemplate = () => {
  const callApi = useAxios();
  const { url, method } = ticket_template.list;

  return useMutation((data) => {
    const id = data.id;
    let newUrl = `${url}${id}/ticket_templates`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useCreateTicketTemplate = () => {
  const callApi = useAxios();
  const { url, method } = ticket_template.create;

  return useMutation((data) => {
    const id = data.exhibition_id;
    let newUrl = `${url}?exhibition_id=${id}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data,
    }).then((data) => data);
  });
};

export const useUpdateTicketTemplate = () => {
  const callApi = useAxios();
  const { url, method } = ticket_template.update;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}${queryString}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data,
    }).then((data) => data);
  });
};

export const useDeleteTicketTemplate = () => {
  const callApi = useAxios();
  const { url, method } = ticket_template.delete;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}${data.id}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data,
    }).then((data) => data);
  });
};
export const useListTicket = () => {
  const callApi = useAxios();
  const { url, method } = ticket.list;

  return useMutation((data) => {
    const id = data.id;
    let newUrl = `${url}${id}/tickets`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};
export const useCreateTicket = () => {
  const callApi = useAxios();
  const { url, method } = ticket.create;

  return useMutation((data) => {
    console.log(data, "data");
    const id = data.exhibition_id;
    let newUrl = `${url}?exhibition_id=${id}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data,
    }).then((data) => data);
  });
};
export const useScanTicket = () => {
  const callApi = useAxios();
  const { url, method } = ticket.scan;

  return useMutation((data) => {
    console.log(data, "data");
    const ticket_id = data.ticket_id;

    let newUrl = `${url}${ticket_id}`;
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useVerifyTicket = () => {
  const callApi = useAxios();
  const { url, method } = ticket.verify;

  return useMutation((data) => {
    console.log(data, "data");
    const ticket_id = data.ticket_id;
    const id = data.exhibition_id;
    const location = data.location;

    let newUrl = `${url}${ticket_id}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data,
    }).then((data) => data);
  });
};
