import React from 'react';
import { Row, Col, Table, Alert } from 'reactstrap';


const ElegantAllOKStalls = ({ allOkStalls }) => {
    // Calculate summary data
    const totalAllOkStalls = allOkStalls.length;
    const uniqueHalls = [...new Set(allOkStalls.map(stall => stall.hall_or_dome))];
    const recentStalls = [...allOkStalls].sort((a, b) => new Date(b.booking_date) - new Date(a.booking_date)).slice(0, 5);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="p-3">
            <h6 className="text-uppercase text-muted mb-3">Fully Paid Stalls</h6>
            <Row className="mb-4 text-center">
                <Col xs="6">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{totalAllOkStalls}</span>
                    </div>
                    <span className="text-muted text-sm">Total Paid Stalls</span>
                </Col>
                <Col xs="6">
                    <div className="mb-2">
                        <span className="h2 font-weight-bold mb-0">{uniqueHalls.length}</span>
                    </div>
                    <span className="text-muted text-sm">Halls Occupied</span>
                </Col>
            </Row>

            {totalAllOkStalls === 0 ? (
                <Alert color="info" className="text-center mb-0">
                    <i className="fas fa-info-circle mr-2"></i>
                    No stalls with all payments completed at the moment.
                </Alert>
            ) : (
                <>
                    <h6 className="text-uppercase text-muted mb-3">Recent Paid Stalls</h6>
                    <Table responsive borderless size="sm" className="align-items-center mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7">Name</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Hall</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">Booked Date</th>
                            <th scope="col" className="text-uppercase text-muted text-xxs font-weight-bolder opacity-7 px-2">User</th>
                        </tr>
                        </thead>
                        <tbody>
                        {recentStalls.map((stall) => (
                            <tr key={stall.id}>
                                <td>
                                    <span className="text-sm font-weight-bold">{stall.name}</span>
                                </td>
                                <td className="text-sm">{stall.hall_or_dome}</td>
                                <td className="text-sm">{formatDate(stall.booking_date)}</td>
                                <td className="text-sm text-center">{stall.user.brand_name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </>
            )}
        </div>
    );
};

export default ElegantAllOKStalls;
