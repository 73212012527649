// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Row,
  ListGroupItem,
  Col,
  ListGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAuthToken,
  removeUser,
  removeSelectedExhibition,
} from "redux/reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import ExhibitionNavbar from "components/projectComponents/ExhibitionNavbar/ExhibitionNavbar";
import { selectUser } from "redux/reducers/authSlice";

const AdminNavbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const doLogout = () => {
    dispatch(removeAuthToken());
    dispatch(removeUser());
    dispatch(removeSelectedExhibition());
    navigate("/pb/login");
    localStorage.clear();
    toast.success("Logout successfully");
  };
  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid className="justify-content-end">
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <ExhibitionNavbar />
          <UncontrolledDropdown nav>
            <DropdownToggle className="nav-link" color="" tag="a">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu-xl py-0 overflow-hidden"
              right
            >
              <div className="px-3 py-3">
                <h6 className="text-sm text-muted m-0">
                  You have <strong className="text-info">13</strong>{" "}
                  notifications.
                </h6>
              </div>

              <ListGroup flush>
                <ListGroupItem
                  className="list-group-item-action"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tag="a"
                >
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="..."
                        className="avatar rounded-circle"
                        src={require("assets/img/theme/team-1-800x800.jpg")}
                      />
                    </Col>
                    <div className="col ml--2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4 className="mb-0 text-sm">John Snow</h4>
                        </div>
                        <div className="text-right text-muted">
                          <small>2 hrs ago</small>
                        </div>
                      </div>
                      <p className="text-sm mb-0">
                        Let's meet at Starbucks at 11:30. Wdyt?
                      </p>
                    </div>
                  </Row>
                </ListGroupItem>
                <ListGroupItem
                  className="list-group-item-action"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tag="a"
                >
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="..."
                        className="avatar rounded-circle"
                        src={require("assets/img/theme/team-2-800x800.jpg")}
                      />
                    </Col>
                    <div className="col ml--2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4 className="mb-0 text-sm">John Snow</h4>
                        </div>
                        <div className="text-right text-muted">
                          <small>3 hrs ago</small>
                        </div>
                      </div>
                      <p className="text-sm mb-0">
                        A new issue has been reported for Argon.
                      </p>
                    </div>
                  </Row>
                </ListGroupItem>
                <ListGroupItem
                  className="list-group-item-action"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tag="a"
                >
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="..."
                        className="avatar rounded-circle"
                        src={require("assets/img/theme/team-3-800x800.jpg")}
                      />
                    </Col>
                    <div className="col ml--2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4 className="mb-0 text-sm">John Snow</h4>
                        </div>
                        <div className="text-right text-muted">
                          <small>5 hrs ago</small>
                        </div>
                      </div>
                      <p className="text-sm mb-0">
                        Your posts have been liked a lot.
                      </p>
                    </div>
                  </Row>
                </ListGroupItem>
                <ListGroupItem
                  className="list-group-item-action"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tag="a"
                >
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="..."
                        className="avatar rounded-circle"
                        src={"assets/img/theme/team-4-800x800.jpg"}
                      />
                    </Col>
                    <div className="col ml--2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4 className="mb-0 text-sm">John Snow</h4>
                        </div>
                        <div className="text-right text-muted">
                          <small>2 hrs ago</small>
                        </div>
                      </div>
                      <p className="text-sm mb-0">
                        Let's meet at Starbucks at 11:30. Wdyt?
                      </p>
                    </div>
                  </Row>
                </ListGroupItem>
                <ListGroupItem
                  className="list-group-item-action"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  tag="a"
                >
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        alt="..."
                        className="avatar rounded-circle"
                        src={require("assets/img/theme/team-4-800x800.jpg")}
                      />
                    </Col>
                    <div className="col ml--2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h4 className="mb-0 text-sm">John Snow</h4>
                        </div>
                        <div className="text-right text-muted">
                          <small>3 hrs ago</small>
                        </div>
                      </div>
                      <p className="text-sm mb-0">
                        A new issue has been reported for Argon.
                      </p>
                    </div>
                  </Row>
                </ListGroupItem>
              </ListGroup>

              <DropdownItem
                className="text-center text-info font-weight-bold py-3"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                View all
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  {/* <img
                    alt="..."
                    src={require("../../assets/img/theme/team-4-800x800.jpg")}
                  /> */}
                  <i className="fa-solid fa-user-large"></i>
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {user.name}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow text-black-50  m-0">
                  Welcome! {user.name}
                </h6>
              </DropdownItem>
              <DropdownItem>
                <h5 className="text-overflow text-darker m-0">{user.email}</h5>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem to={`/${user?.role}/user-profile`} tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem onClick={doLogout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
