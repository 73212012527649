// reactstrap components
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useCreateEXhibition,
  useExhibitionList,
  useEditVisitors,
  useAddImageVisitors,
  useExhibitionVisitorLink,
} from "shared/utility/services/hooks/exhibition";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Modal,
  CardBody,
  FormFeedback,
  Table,
  ButtonGroup,
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  recallExhibition,
  selectExhibition,
  selectUser,
} from "redux/reducers/authSlice";
import { useLocation } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useLogin } from "../../shared/utility/services/hooks/signin";

import { sortCarets } from "../../shared/utility/helpers";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm d-inline w-auto mx-1"
            defaultValue={"25"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Visitors = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [openCreateVisitorsModal, setOpenCreateVisitorsModal] = useState(false);
  const [activeModalStatus, setActiveModalStatus] = useState("add");
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const { state } = useLocation();

  const selectedExhibition = useSelector(selectExhibition);
  const {
    data: visitorListData,
    isLoading: visitorListLoading,
    isError: visitorListIsError,
    error: visitorListError,
    mutate: visitorList,
  } = useExhibitionList();

  /*useEffect(() => {
    if (state?.add) {
      addVisitorsModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.add]);

  const modalToogle = (status) => {
    setOpenCreateVisitorsModal((prev) => !prev);
    setActiveModalStatus(status);
  };
  const openModal = (status) => {
    setActiveModalStatus(status);
  };

  // list visitor

  let visitorListNew = [];
  useEffect(() => {
    if (!visitorListLoading) {
      visitorList(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // create visitor
  const {
    data: createVisitorsData,
    isLoading: createVisitorsLoading,
    isError: createVisitorsIsError,
    error: createVisitorsError,
    mutate: createVisitors,
  } = useCreateEXhibition();

  useEffect(() => {
    try {
      if (createVisitorsData?.status === 201 && !createVisitorsLoading) {
        modalToogle();
        toast.success("Visitors created successfully");
        dispatch(recallExhibition());
        console.log("createVisitorsData", createVisitorsData);
      } else if (createVisitorsData?.status === 420 && !createVisitorsLoading) {
        console.log(createVisitorsData.body);
        toast.error("Visitors Limit Reached");
      }
      if (createVisitorsIsError) {
        console.log("createVisitorsError", createVisitorsError);
        toast.error(createVisitorsError?.response?.data?.error);
      }
      visitorList(query);
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createVisitorsData, createVisitorsLoading, createVisitorsIsError]);

  const initialValues = {
    name: "",
  };

  // const [visitorLogo , setVisitorsLogo] = useState(new File([""], 'TEST.png',{type: 'image/png'}))
  const [visitorLogo, setVisitorsLogo] = useState(null);

  const modalClose = () => {
    modalToogle();
    setModalIsEdit(false);
  };
  const [verifyPassword, setVerifyPassword] = useState("");

  const {
    data: loginData,
    isLoading: loginLoading,
    isError: loginIsError,
    error: loginError,
    mutate: login,
  } = useLogin();
  const user = useSelector(selectUser);
  const verifyUser = () => {
    console.log(user.email);
    console.log(verifyPassword);
    const verificationData = new FormData();
    verificationData.append("username", user.email);
    verificationData.append("password", verifyPassword);
    login(verificationData);
  };
  useEffect(() => {
    if (loginError) {
      toast.error("Incorrect Password");
      modalClose();
    }
    if (loginData && !loginLoading) {
      openModal("add");
      setVerifyPassword("");
    }
  }, [loginData, loginLoading, loginIsError]);

  const [modalIsEdit, setModalIsEdit] = useState(false);
  let modalStatus = {
    password_verify: (
      <div className="modal-body p-0 " style={{ overflowY: "auto" }}>
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Create Visitor Link
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true} className="close-button-icon">
                ×
              </span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col lg={12}>
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-name">
                    Enter Your Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-text-width"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password *"
                      type="password"
                      name="password"
                      value={verifyPassword}
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg={12}>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={verifyUser}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    ),
  };

  const addVisitorsModalOpen = () => {
    modalToogle("add");
    setModalIsEdit(true);
  };

  const editVisitorsModalOpen = (obj) => {
    modalToogle("password_verify");
    console.log(obj);
    setModalIsEdit(true);
    // formik.setValues(obj);
  };*/

  const {
    data: getVisitorLinkData,
    isLoading: getVisitorLinkLoading,
    isError: getVisitorLinkIsError,
    error: getVisitorLinkError,
    mutate: getVisitorLink,
  } = useExhibitionVisitorLink();

  const [visitorLinkData, setVisitorLinkData] = useState(null);
  useEffect(() => {
    if (selectedExhibition?.visitor_link) {
      console.log(selectedExhibition.visitor_link, "visitor link data");
      setVisitorLinkData(selectedExhibition.visitor_link);
    }
  }, [selectedExhibition]);

  useEffect(() => {
    if (getVisitorLinkData) {
      console.log(getVisitorLinkData);
      setVisitorLinkData(getVisitorLinkData);
    }
  }, [getVisitorLinkData, getVisitorLinkLoading, getVisitorLinkIsError]);

  function generateVisitorLink() {
    getVisitorLink({ exhibition_id: selectedExhibition.id });
  }

  return (
    <>
      <AdminHeader />
      <Container className="mt--7 w-100" fluid>
        <Row className={"w-100"}>
          <Card className="shadows w-100">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Visitors</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>

            {!visitorLinkData ? (
              <div
                className={
                  "container-fluid d-flex flex-column justify-content-center align-items-center p-8  "
                }
              >
                <span className={"p-3 text-dark font-weight-bold"}>
                  You Have Not Created Visitor Link
                </span>

                <Button color="primary" onClick={generateVisitorLink} size="lg">
                  Add Visitor Link
                </Button>
              </div>
            ) : (
              <>
                <div>
                  <Card className={"m-5"}>
                    <CardHeader>
                      <h3 className="mb-0">Visitor Link</h3>
                    </CardHeader>
                    <CardBody>
                      <Row
                        className={
                          "d-flex align-items-center justify-content-center"
                        }
                      >
                        <Col
                          xs={12}
                          className={
                            "d-flex align-items-center justify-content-center flex-column"
                          }
                        >
                          <h4 className="mb-0">Link</h4>
                          <h3
                            className={
                              "text-success p-3 border-dark rounded border m-2"
                            }
                            onClick={() => {
                              window.open(
                                "http://localhost:5000/" + visitorLinkData,
                                "_blank",
                              );
                            }}
                          >
                            http://localhost:3000/{visitorLinkData}
                          </h3>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <ToolkitProvider
                  data={[]}
                  keyField="id"
                  columns={[
                    {
                      dataField: "name",
                      text: "Expo Name",
                      sort: true,
                      sortCaret: (order, column) => sortCarets(order, column),
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      sortCaret: (order, column) => sortCarets(order, column),
                    },
                    {
                      dataField: "start_date",
                      text: "Start Date",
                      sort: true,
                      sortCaret: (order, column) => sortCarets(order, column),
                    },
                    {
                      dataField: "end_date",
                      text: "End Date",
                      sort: true,
                      sortCaret: (order, column) => sortCarets(order, column),
                    },

                    {
                      dataField: "viewBtn",
                      text: "View",
                      sort: false,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive w-100 ">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                <span>Search:</span>
                                <SearchBar
                                  className="form-control-sm ml-2"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Button
                              className={"float-right"}
                              color="primary"
                              // onClick={addVisitorsModalOpen}
                              size="sm"
                            >
                              Add Visitors
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        classes={"mb-3"}
                        pagination={pagination}
                        bordered={true}
                        hover
                        condensed
                        rowStyle={{ textAlign: "center" }}
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </>
            )}
          </Card>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={openCreateVisitorsModal}
          onExit={() => {
            // modalToogle();
          }}
        >
          {/*{modalStatus[activeModalStatus]}*/}
        </Modal>
      </Container>
    </>
  );
};

export default Visitors;
