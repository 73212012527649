// reactstrap components
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Stepper, Step } from "react-form-stepper";
import {
  Card,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  CardBody,
  Button,
  CardHeader,
  Modal,
  Table,
  Form,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Badge,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import AdminHeader from "components/Headers/AdminHeader";
import {
  useStallsList,
  useDocumentUpload,
  useDocumentDownload,
  useDocumentList,
  useParticipationDownload,
  useEditStalls,
  useCreatePayment,
  usePossessionDownload,
} from "shared/utility/services/hooks/stalls";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import {
  stallStatus,
  getEntriesByFileType,
  downloadFileLocal,
  fileNames,
} from "shared/utility/helpers";
import { toast } from "react-toastify";
import NoData from "shared/common/NoData";
import { CommentsSection } from "components/Comments";
import Loading from "shared/common/loading";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

// react plugin that prints a given react component
import generatePDF, { Resolution } from "react-to-pdf";
import { Store, Ruler, IndianRupee } from "lucide-react";
import { sortCarets } from "../../../shared/utility/helpers";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 25,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show{" "}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm d-inline w-auto mx-1"
          defaultValue={"25"}
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const Stalls = () => {
  const selectedExhibition = useSelector(selectExhibition);
  const [stallData, setStallData] = useState(null);
  const [viewStatus, setViewStatus] = useState(stallStatus[0].no);
  const [modal, setModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [activeModalStatus, setActiveModalStatus] = useState("verfiyDocument");
  const [selectedStall, setSelectedStall] = useState(null);
  const componentRef = useRef();
  const [query, setQuery] = useState({
    limit: 1,
    skip: 0,
    exhibition_id: selectedExhibition?.id,
  });

  const [document, setDocument] = useState(null);
  const [documentType, setDocumentType] = useState(null);

  const [service, setService] = useState({ name: "", qty: 0 });
  const [services, setServices] = useState([]);

  const payNowUpdate = () => {
    toast.success("Payment done successfully");
    setViewStatus(stallStatus[3].no);
  };
  console.log(
    selectedExhibition?.required_documents,
    "selectedExhibition?.required_documents",
  );
  console.log(stallData, "stallData");

  // list stalls
  const {
    data: stallsListData,
    isLoading: stallsListLoading,
    isError: stallsListIsError,
    error: stallsListError,
    mutate: stallsList,
  } = useStallsList();

  useEffect(() => {
    try {
      if (stallsListData && !stallsListLoading) {
        if (stallsListData.results.length) {
          setStallData(stallsListData?.results?.[0]);
          setServices(stallsListData?.results?.[0]?.services ?? []);
          documentsList({ stall_id: stallsListData?.results?.[0]?.id });
          // Todo hard coded status to move to doc_dub
          if (stallsListData?.results?.[0]?.stall_status === "new") {
            setViewStatus(0);
          } else {
            const currentStatusNo = stallStatus.filter(
              (obj) =>
                obj?.title === stallsListData?.results?.[0]?.stall_status,
            )?.[0]?.no;
            setViewStatus(currentStatusNo);
            console.log(currentStatusNo, "currentStatusNo");
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stallsListData, stallsListLoading, stallsListIsError, stallsListError]);

  // edit stalls
  const {
    data: editStallsData,
    isLoading: editStallsLoading,
    isError: editStallsIsError,
    error: editStallsError,
    mutate: editStalls,
  } = useEditStalls();

  useEffect(() => {
    try {
      if (editStallsData && !editStallsLoading) {
        toast.success("Services updated successfully");
        setService({ name: null, qty: 0 });
        setServices([]);
        stallsList(query);
      }
      if (editStallsIsError) {
        stallsList(query);
        toast.error(
          editStallsError?.response?.data?.detail ?? "Something went wrong",
        );
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStallsData, editStallsLoading, editStallsIsError]);

  useEffect(() => {
    if (!stallsListLoading && selectedExhibition?.id) {
      stallsList(query);
      setSelectedStall(stallsListData?.results?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!stallsListLoading && selectedExhibition?.id) {
      setQuery((prev) => ({ ...prev, exhibition_id: selectedExhibition?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition?.id]);

  // list documents
  const {
    data: documentsListData,
    isLoading: documentsListLoading,
    isError: documentsListIsError,
    error: documentsListError,
    mutate: documentsList,
  } = useDocumentList();

  useEffect(() => {
    try {
      if (documentsListData && !documentsListLoading) {
        /*console.log(documentsListData, 'documentsListData')
        if(selectedExhibition?.required_documents?.length){
        selectedExhibition.required_documents.map((doc,index)=>console.log(isDocumentPresent(documentsListData , doc).id,'doc'))
        }*/
      }
      if (documentsListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentsListData,
    documentsListLoading,
    documentsListIsError,
    documentsListError,
  ]);

  // Upload documents
  const {
    data: documentsUploadData,
    isLoading: documentsUploadLoading,
    isError: documentsUploadIsError,
    error: documentsUploadError,
    mutate: documentsUpload,
  } = useDocumentUpload();

  useEffect(() => {
    try {
      if (documentsUploadData && !documentsUploadLoading) {
        toast.success("Document uploaded successfully");
        documentsList({ stall_id: stallsListData?.results?.[0]?.id });
        setDocument(null);
        setDocumentType(null);
        modalClose();
      }
      if (documentsUploadIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentsUploadData,
    documentsUploadLoading,
    documentsUploadIsError,
    documentsUploadError,
  ]);

  // uploaded document download
  const {
    data: documentDownloadData,
    isLoading: documentDownloadLoading,
    isError: documentDownloadIsError,
    error: documentDownloadError,
    mutate: documentDownload,
  } = useDocumentDownload();

  useEffect(() => {
    try {
      if (documentDownloadData && !documentDownloadLoading) {
        const fileName = "example-file";
        downloadFileLocal(documentDownloadData, fileName);
      }
      if (documentDownloadIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentDownloadData,
    documentDownloadLoading,
    documentDownloadIsError,
    documentDownloadError,
  ]);
  const [pDoc, setPDoc] = useState();
  // participation participation download
  const {
    data: participationDownloadData,
    isLoading: participationDownloadLoading,
    isError: participationDownloadIsError,
    error: participationDownloadError,
    mutate: participationDownload,
  } = useParticipationDownload();
  const {
    data: possessionDownloadData,
    isLoading: possessionDownloadLoading,
    isError: possessionDownloadIsError,
    error: possessionDownloadError,
    mutate: possessionDownload,
  } = usePossessionDownload();

  const componentPrintRef = useRef();

  let pdfOptions = {
    method: "save",
    resolution: Resolution.EXTREME,
    page: {
      format: "A4",
      orientation: "portrait",
    },
    canvas: {
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };

  useEffect(() => {
    try {
      if (participationDownloadData && !participationDownloadLoading) {
        const fileName = "participation_document.pdf";
        // console.log(window.document?.getElementById("pdf-div"), "pdf-div");
        // const getTargetElement = () => window.document?.getElementById("pdf-div");
        const PDocBase = atob(participationDownloadData);
        setPDoc(PDocBase);
        console.log(PDocBase, "PDocBase");
        pdfOptions.filename = fileName;
        // const pddiv = <div id={'pdf-down'}>pDoc</div>
        // generatePDF(componentPrintRef, pdfOptions);
        // const win = window.open(<html>${pDoc}</html>, "pdf");
        // const linkSource = `data:application/pdf;base64,${participationDownloadData}`;
        // const downloadLink = window.document.createElement('a');
        // window.document.body.appendChild(downloadLink);
        // downloadLink.href = linkSource;
        // downloadLink.target = '_self';
        // downloadLink.download = fileName;
        // downloadLink.click();
        // var divToPrint=document.getElementById("view_CPD_Declaration");
        const newWin = window.open("");
        newWin.document.write(PDocBase);
        try {
          if (!newWin.document.execCommand("print", false, null)) {
            newWin.print({
              filename: fileName,
              autoPrint: true,
              download: true,
            });
          }
        } catch {
          newWin.print({
            filename: fileName,
            autoPrint: true,
            download: true,
          });
        }

        newWin.close();
      }
      if (participationDownloadIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    participationDownloadData,
    participationDownloadLoading,
    participationDownloadIsError,
  ]);
  useEffect(() => {
    try {
      if (possessionDownloadData && !possessionDownloadLoading) {
        const fileName = "possession_document.pdf";
        const PDocBase = atob(possessionDownloadData);
        console.log(PDocBase, "PDocBase");
        setPDoc(PDocBase);
        pdfOptions.filename = fileName;
        // const pddiv = <div id={'pdf-down'}>pDoc</div>
        // generatePDF(componentPrintRef, pdfOptions);
        // const win = window.open(<html>${pDoc}</html>, "pdf");
        // const linkSource = `data:application/pdf;base64,${participationDownloadData}`;
        // const downloadLink = window.document.createElement('a');
        // window.document.body.appendChild(downloadLink);
        // downloadLink.href = linkSource;
        // downloadLink.target = '_self';
        // downloadLink.download = fileName;
        // downloadLink.click();
        // var divToPrint=document.getElementById("view_CPD_Declaration");
        const newWin = window.open("", "pdf");
        newWin.document.write(PDocBase);
        try {
          if (!newWin.document.execCommand("print", false, null)) {
            newWin.print({
              filename: fileName,
              autoPrint: true,
              download: true,
            });

            console.log("Catecjesaddd");
          }

          console.log("Catecjedasdd");
        } catch {
          console.log("Catecjed");
          newWin.print({
            filename: fileName,
            autoPrint: true,
            download: true,
          });
        }
        newWin.close();
      }
      if (possessionDownloadIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    possessionDownloadData,
    possessionDownloadError,
    possessionDownloadIsError,
    possessionDownloadLoading,
  ]);

  const uploadDocumnet = () => {
    if (documentType) {
      if (!document) {
        toast.error("Document missing");
      } else if (document && document.size > 5 * 1024 * 1024) {
        // 5MB in bytes
        toast.error("File size should be less than 5MB");
      } else {
        const formData = new FormData();
        formData.append("file_type", documentType);
        formData.append("file", document);
        formData.append("stall_id", stallsListData?.results?.[0]?.id);
        formData.append("is_rejected", false);
        documentsUpload(formData);
      }
    }
  };

  const openFileUploadModal = (fileType) => {
    setActiveModalStatus("upload");
    setModal(true);
    setDocumentType(fileType);
  };

  const openPossesionFormModal = () => {
    setActiveModalStatus("possessionForm");
    setModal(true);
  };

  const existingKycDocument = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.kyc_document,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const existingApprovalForm = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.approval_form,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const existingContractForm = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.contract_form,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const existingstallDesignDocument = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.stall_design_document,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const pickDocument = (doc) => {
    setSelectedDocument(doc);
    setActiveModalStatus("comment");
    setModal(true);
  };

  const modalToogle = (status = "comment") => {
    setModal((prev) => !prev);
    setActiveModalStatus(status);
  };

  const modalClose = () => {
    setModal((prev) => !prev);
  };

  const setStepperValue = (value) => {
    // Todo hard coded status to move to doc_dub
    let currentStatusNo;
    console.log(stallData?.stall_status, "stallData?.stall_status");
    if (stallData?.stall_status === "new") {
      currentStatusNo = 0;
      console.log("new 0");
    } else {
      console.log("filter status");
      currentStatusNo = stallStatus.filter(
        (obj) => obj?.title === stallData?.stall_status,
      )?.[0]?.no;
      console.log(currentStatusNo, "currentStatusNo");
    }
    if (value <= currentStatusNo) {
      setViewStatus(value);
    }
  };

  const onserviceAdd = () => {
    if (!service.name || service.qty === 0) {
      toast.error("Please select service and quantity");
      return;
    }
    let tempService = services;
    tempService.push({ uuid: uuidv4(), ...service });
    // tempService.push({ ...service })
    setServices(tempService);
    setService({ name: null, qty: 0 });
  };

  const deleteService = (obj) => {
    let tempService = services.filter((item) => item.uuid !== obj.uuid);
    setServices(tempService);
  };

  const submitServices = () => {
    if (!services.length) {
      toast.error("Please add services");
      return;
    }
    editStalls({ services: services, id: stallData?.id });
  };

  const initialValues = {
    name: "",
    firstName: "",
    lastName: "",
    mobile: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      mobile: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      participationDownload(values);
    },
  });
  const {
    data: createPaymentData,
    isLoading: createPaymentLoading,
    isError: createPaymentIsError,
    error: createPaymentError,
    mutate: createPayment,
  } = useCreatePayment();
  const initialValuesPayment = {
    stall_id: stallData?.id,
    amount_paid: 0,
    payment_type: "",
    amount: 0,
  };
  const [paymentProof, setPaymentProof] = useState(null);
  const formikPayment = useFormik({
    initialValues: initialValuesPayment,
    values: initialValuesPayment,
    enableReinitialize: true,
    validationSchema: Yup.object({
      stall_id: Yup.string().required("Required"),
      amount_paid: Yup.string().required("Required"),
      payment_type: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // participationDownload(values);
      console.log({ values, paymentProof }, "values");
      createPayment({ values, paymentProof });
    },
  });
  useEffect(() => {
    if (createPaymentData && !createPaymentLoading) {
      toast.success("Payment done successfully");
      formikPayment.handleReset();
      setPaymentProof(null);
      stallsList(query);
    }
  }, [createPaymentData]);

  let modalStatus = {
    comment: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Comments
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <CommentsSection document={selectedDocument} showAddComment={false} />
        </Card>
      </div>
    ),
    upload: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Upload Document
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-3">
            <FormGroup className="mb-3">
              <label className="form-control-label" htmlFor="input-name">
                Select file to upload
              </label>
              <InputGroup className="input-group-alternative d-flex flex-nowrap">
                <Input
                  type="file"
                  name="doc_file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    setDocument(e.target.files[0]);
                  }}
                ></Input>
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                color="info"
                title="Download"
                onClick={() => {
                  uploadDocumnet();
                }}
                isLoading={documentsUploadLoading}
              >
                {documentsUploadLoading ? <Loading /> : "Upload"}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    ),
    possessionForm: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Possession
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <CardBody className="px-lg-5 py-lg-3">
            <Form role="form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Business name
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa-solid fa-text-width"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Business name *"
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.name && formik.errors.name}
                      />
                      <FormFeedback>{formik.errors.name}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      First name
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa-solid fa-text-width"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First name *"
                        type="text"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                      <FormFeedback>{formik.errors.firstName}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Last name
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa-solid fa-text-width"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last name *"
                        type="text"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                      <FormFeedback>{formik.errors.lastName}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="input-name">
                      Mobile
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa-solid fa-text-width"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="User phone *"
                        type="number"
                        name="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.touched.mobile && formik.errors.mobile}
                      />
                      <FormFeedback>{formik.errors.mobile}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    ),
  };
  const [stallPayments, setStallPayments] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const isDocumentPresent = (documentsListData, docName) => {
    return documentsListData?.results?.find((doc) => doc.file_type === docName);
  };
  const paymentFormData = new FormData();
  console.log(stallData, "selectedStall");
  const [approvedPayments, setApprovedPayments] = useState([]);
  useEffect(() => {
    if (stallData) {
      stallData.payments.map((stall, index) => {
        stall.link = (
          <a
            className={"link btn btn-sm btn-outline-info"}
            href={stall.payment_proof}
          >
            View
          </a>
        );
        if (stall.status === "approved") {
          stall.status_pill = (
            <Badge color="success" pill>
              {" "}
              Approved
            </Badge>
          );
        } else if (stall.status === "pending") {
          stall.status_pill = (
            <Badge color="info" pill>
              {" "}
              Pending
            </Badge>
          );
        } else if (stall.status === "rejected") {
          stall.status_pill = (
            <Badge color="danger" pill>
              {" "}
              Rejected
            </Badge>
          );
        } else {
          stall.status_pill = (
            <Badge color="info" pill>
              {stall.status}
            </Badge>
          );
        }
      });
    }
    formikPayment.setValues({
      ...formikPayment.values,
      stall_id: stallData?.id,
      amount:
        stallData?.price_per_sq_unit * stallData?.length * stallData?.width,
    });
    console.log("stalls", stallData);
    setStallPayments(stallData?.payments);
    setApprovedPayments(
      stallData?.payments.filter((payment) => payment.status === "approved"),
    );
  }, [stallData]);
  let totalAmount = 0;
  useEffect(() => {
    setTotalPayment(
      approvedPayments?.reduce(
        (total, payment) => total + payment.amount_paid,
        0,
      ),
    );
  }, [approvedPayments]);
  const paymentForm = () => {
    return (
      <Form onSubmit={formikPayment.handleSubmit}>
        <Row>
          <Col lg={4}>
            <Card className={"m-1"}>
              <CardBody className={"text-center"}>
                <h2>Total Payment</h2>
                <h3>{formikPayment.values.amount}</h3>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className={"m-1"}>
              <CardBody className={"text-center"}>
                <h2>Paid</h2>
                <h3>{totalPayment}</h3>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className={"m-1"}>
              <CardBody className={"text-center"}>
                <h2>Pending</h2>
                <h3>
                  {formikPayment.values.amount - totalPayment <= 0
                    ? "0"
                    : formikPayment.values.amount - totalPayment}
                </h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className={"m-1"}>
              <CardBody>
                <h3 className={"mb-1"}>Terms And Conditions </h3>
                <p className={"text-sm font-weight-500 mb-1 text-red"}>
                  Please read the terms and conditions carefully.
                </p>
                <p className={"text-xs text-red mb-2"}>
                  By making any payment you hereby agree to the below mentioned
                  terms and conditions.
                </p>
                {selectedExhibition.tnc.length > 100 ? (
                  <details>
                    <summary>
                      {selectedExhibition.tnc.slice(0, 100)}..... (Click To
                      Expand)
                    </summary>
                    <p>{selectedExhibition.tnc}</p>
                  </details>
                ) : (
                  <p>{selectedExhibition.tnc}</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <hr />
        <div className="d-flex align-items-center flex-column">
          <h3 className="font-weight-bold m-3">Payment pending</h3>
          <div className={"container-fluid"}>
            <Row>
              <Col lg="6">
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-status">
                    Total Price
                  </label>
                  {/*<p>{stallData?.price_per_sq_unit}</p>*/}
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid fa-calculator "></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=""
                      type="number"
                      name="price_per_sq_unit"
                      value={formikPayment.values.amount}
                      disabled={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-status">
                    Payment Amount
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid  fa-calculator"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Payment Amount"
                      type="number"
                      name="amount_paid"
                      value={formikPayment.values.amount_paid}
                      onChange={formikPayment.handleChange}
                      onBlur={formikPayment.handleBlur}
                      invalid={
                        formikPayment.touched.amount_paid &&
                        formikPayment.errors.amount_paid
                      }
                    />
                    <FormFeedback>
                      {formikPayment.errors.amount_paid}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-status">
                    Payment Type
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa-solid  fa-text-width"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Mode Of Payment"
                      type="text"
                      name="payment_type"
                      value={formikPayment.values.payment_type}
                      onChange={formikPayment.handleChange}
                      onBlur={formikPayment.handleBlur}
                      invalid={
                        formikPayment.touched.payment_type &&
                        formikPayment.errors.payment_type
                      }
                    />
                    <FormFeedback>
                      {formikPayment.errors.payment_type}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup className="mb-3">
                  <label className="form-control-label" htmlFor="input-status">
                    Payment Proof
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Upload Payment Proof"
                      type="file"
                      name="qty"
                      onChange={(e) => {
                        paymentFormData.append(
                          "payment_proof",
                          e.target.files[0],
                        );
                        setPaymentProof(e.target.files[0]);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Button
            color="primary"
            type="submit"
            disabled={createPaymentLoading}
            onClick={formikPayment.handleSubmit}
          >
            Pay now
          </Button>
        </div>
      </Form>
    );
  };
  const listOfPayments = () => {
    return (
      <div className="d-flex align-items-center flex-column mt-3">
        {stallPayments && (
          <ToolkitProvider
            data={stallPayments}
            keyField="id"
            columns={[
              {
                dataField: "amount_paid",
                text: "Amount Paid",
                sort: true,
                sortCaret: (order, column) => sortCarets(order, column),
              },
              {
                dataField: "link",
                text: "Payment Proof",
                sort: true,
                sortCaret: (order, column) => sortCarets(order, column),
              },
              {
                dataField: "payment_type",
                text: "Payment Type",
                sort: true,
                sortCaret: (order, column) => sortCarets(order, column),
                headerStyle: { textAlign: "center" },
                style: {
                  textAlign: "center",
                },
              },

              {
                dataField: "status_pill",
                text: "Status",
                sort: true,
                sortCaret: (order, column) => sortCarets(order, column),
                headerStyle: { textAlign: "center" },
                style: {
                  textAlign: "center",
                },
              },
            ]}
            search
          >
            {(props) => (
              <div className="py-4 px-4 table-responsive">
                <Container fluid>
                  <Row>
                    <Col xs={12} sm={6}></Col>
                    <Col xs={12} sm={6}>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <label>
                          <span>Search:</span>
                          <SearchBar
                            className="form-control-sm ml-2"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <BootstrapTable
                  ref={componentRef}
                  {...props.baseProps}
                  bootstrap4={true}
                  classes={"mb-3"}
                  pagination={pagination}
                  bordered={true}
                  hover
                  condensed
                  rowStyle={{ textAlign: "center" }}
                  id="react-bs-table"
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
    );
  };
  const maxStep = stallStatus.find(
    (item) => item.title === stallData?.stall_status,
  )?.no;
  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        <Col>
          {!stallsListData?.results?.[0] && (
            <Card className="shadow">
              <NoData title="No specific stall has been assigned yet." />
            </Card>
          )}
          {stallsListData?.results?.[0] && (
            <>
              <Card className="shadow mb-2">
                <CardHeader className="bg-secondary">
                  <Row className="align-items-center">
                    <Col className={"d-flex"}>
                      <Store className="text-primary mr-2" size={24} />
                      <h2 className="h4 mb-0">
                        Stall Name : {stallData?.name}
                      </h2>
                    </Col>
                    <Col xs="auto">
                      {stallData?.tags.map((tag, index) => (
                        <Badge
                          key={index}
                          color="success"
                          className="mr-2 text-uppercase text-md"
                          pill
                        >
                          {tag}
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="g-3">
                    <Col
                      md={6}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Ruler className="text-muted mr-2" size={20} />
                      <div>
                        <small className="text-muted d-block">Dimensions</small>
                        <strong>
                          {stallData?.length}
                          {selectedExhibition?.measurement_unit === "sqm"
                            ? "m"
                            : "ft"}{" "}
                          X {stallData?.width}{" "}
                          {selectedExhibition?.measurement_unit === "sqm"
                            ? "m"
                            : "ft"}
                        </strong>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex align-items-center  justify-content-center"
                    >
                      <IndianRupee className="text-muted mr-2" size={20} />
                      <div>
                        <small className="text-muted d-block">
                          Price / Sq{" "}
                          {selectedExhibition?.measurement_unit === "sqm"
                            ? "Sq m"
                            : "Sq ft"}
                        </small>
                        <strong>{stallData?.price_per_sq_unit}</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow border-0">
                <CardBody>
                  <Row>
                    <Stepper
                      connectorStateColors={true}
                      connectorStyleConfig={{
                        completedColor: "#1976d2",
                        activeColor: "#1976d2",
                        disabledColor: "#eee",
                      }}
                      styleConfig={{
                        activeBgColor: "#1976d2",
                        completedBgColor: "#5183c2",
                        inactiveBgColor: "#eee",

                        activeTextColor: "#ffffff",
                        circleFontSize: "1rem",
                        completedTextColor: "#f3f3f3",
                        inactiveTextColor: "#444",
                      }}
                      activeStep={viewStatus}
                      className={"w-100"}
                    >
                      {stallStatus.map((label, index) => (
                        <Step
                          key={index}
                          label={label?.message}
                          onClick={() => setStepperValue(label?.no)}
                        />
                      ))}
                    </Stepper>
                  </Row>
                </CardBody>
                {/*{viewStatus === stallStatus[0].no && (
                <CardBody className="px-lg-5 py-lg-3">
                  <Row className="my-4">
                    <Col lg="6">
                      <div className="d-flex align-content-center">
                        <h3 className="m-0">Tags: </h3>
                        {stallData?.tags.map((tag, index) => (
                          <Badge color="info" className="ml-2" pill key={index}>
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="d-flex align-content-center">
                        <h3 className="m-0">Price sq/m: </h3>
                        <p>
                          &nbsp;
                          {stallData?.price_per_sq_unit}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col lg="6">
                      <div className="d-flex align-content-center">
                        <h3 className="m-0">Length: </h3>
                        <p>
                          &nbsp;
                          {stallData?.length}
                        </p>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="d-flex align-content-center">
                        <h3 className="m-0">Width: </h3>
                        <p>
                          &nbsp;
                          {stallData?.width}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row className="justify-content-center my-2">
                    <Button
                      color="primary"
                      onClick={() => setStepperValue(stallStatus[0].no + 1)}
                    >
                      Next
                    </Button>
                  </Row>
                </CardBody>
              )}*/}
                {viewStatus === stallStatus[0].no && (
                  <CardBody className="px-lg-5 py-lg-3">
                    {viewStatus === 1 && (
                      <div className="my-3 d-flex justify-content-center align-item-center">
                        <Badge color="info" pill>
                          Submit your documents and wait for approval
                        </Badge>
                      </div>
                    )}
                    <Row>
                      {selectedExhibition &&
                        selectedExhibition?.required_documents?.map(
                          (doc, index) => (
                            <Col lg="6">
                              <label
                                className="form-control-label"
                                htmlFor="input-status"
                              >
                                {doc}
                              </label>
                              {}
                              <div className="d-flex align-items-center">
                                {isDocumentPresent(documentsListData, doc) ? (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      documentDownload(
                                        isDocumentPresent(
                                          documentsListData,
                                          doc,
                                        )?.id,
                                      );
                                    }}
                                    className="btn btn-link"
                                  >
                                    Click here to download
                                  </button>
                                ) : (
                                  <p className="mb-0 mx-3">
                                    No file uploaded yet
                                  </p>
                                )}
                                {isDocumentPresent(documentsListData, doc)
                                  ?.is_approved ? (
                                  <div className="bg-green text-white shadow mx-1 d-flex align-items-center p-2 rounded">
                                    <i className="fa-solid fa-check pr-2"></i>
                                    <p className="font-weight-bold mb-0">
                                      Verified
                                    </p>
                                  </div>
                                ) : (
                                  <Button
                                    color="info"
                                    title="Download"
                                    onClick={() => {
                                      openFileUploadModal(doc);
                                    }}
                                    outline={
                                      isDocumentPresent(documentsListData, doc)
                                        ?.is_submitted &&
                                      !isDocumentPresent(documentsListData, doc)
                                        ?.is_rejected
                                    }
                                    disabled={
                                      isDocumentPresent(documentsListData, doc)
                                        ?.is_submitted &&
                                      !isDocumentPresent(documentsListData, doc)
                                        ?.is_rejected
                                    }
                                  >
                                    {isDocumentPresent(documentsListData, doc)
                                      ?.is_submitted &&
                                    !isDocumentPresent(documentsListData, doc)
                                      ?.is_rejected ? (
                                      <span>Uploaded</span>
                                    ) : (
                                      <span>Upload</span>
                                    )}
                                  </Button>
                                )}
                                {isDocumentPresent(documentsListData, doc)
                                  ?.comments.length !== 0 && (
                                  <Button
                                    color="primary"
                                    disabled={
                                      isDocumentPresent(documentsListData, doc)
                                        ?.comments.length === 0
                                    }
                                    onClick={() =>
                                      pickDocument(
                                        isDocumentPresent(
                                          documentsListData,
                                          doc,
                                        ),
                                      )
                                    }
                                  >
                                    <Badge
                                      color="danger"
                                      className="badge badge-circle badge-floating border-white text-white"
                                    >
                                      {
                                        isDocumentPresent(
                                          documentsListData,
                                          doc,
                                        )?.comments.length
                                      }
                                    </Badge>
                                    <span>Comment</span>
                                  </Button>
                                )}
                              </div>
                            </Col>
                          ),
                        )}
                    </Row>
                    <Row className="justify-content-center my-2">
                      <Button
                        color="primary"
                        onClick={() => setStepperValue(stallStatus[1].no + 1)}
                        disabled={maxStep <= 0}
                      >
                        Next
                      </Button>
                    </Row>
                  </CardBody>
                )}
                {viewStatus === stallStatus[1].no && (
                  <CardBody className="px-lg-5 py-lg-3 ">
                    {paymentForm()}
                    <hr />
                    {listOfPayments()}
                  </CardBody>
                )}
                {(viewStatus === stallStatus[2].no ||
                  viewStatus === stallStatus[3].no) && (
                  <CardBody className="px-lg-5 py-lg-3">
                    <Row>
                      <Col
                        lg={12}
                        className={
                          "d-flex align-items-center justify-content-center pb-3"
                        }
                      >
                        <h3>Download Documents</h3>
                      </Col>
                      <Col
                        lg="12"
                        className={
                          "d-flex align-items-center justify-content-center"
                        }
                      >
                        <Button
                          size={"lg"}
                          color="primary"
                          onClick={() => {
                            participationDownload({
                              stall_id: stallData?.id,
                              exhibition_id: selectedExhibition?.id,
                            });
                          }}
                        >
                          Download Participation Document
                        </Button>
                        {/*<label
                              className="form-control-label"
                              htmlFor="input-status"
                          >
                            Participation Document
                          </label>
                          <div className="d-flex align-items-center">
                            <button type="button"
                                // onClick={() => { participationDownload(existingKycDocument?.id) }}
                                    onClick={() => {
                                      downloadFileLocal("https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", "Participation Document")
                                    }}
                                    className="btn btn-link">Download
                            </button>
                          </div>*/}
                        <Button
                          size={"lg"}
                          color="primary"
                          onClick={() => {
                            possessionDownload({
                              stall_id: stallData?.id,
                              exhibition_id: selectedExhibition?.id,
                            });
                          }}
                        >
                          Download Possession Document
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                    <>{viewStatus === stallStatus[3].no && paymentForm()}</>

                    {listOfPayments()}
                  </CardBody>
                )}
              </Card>
            </>
          )}
        </Col>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={modal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
      <div
        id="pdf-div"
        className={"d-none d-print-block"}
        ref={componentPrintRef}
      >
        {/*{pDoc}*/}
      </div>
    </>
  );
};

export default Stalls;
