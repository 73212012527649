import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { ArrowUp, ArrowDown, Users, Home } from 'lucide-react';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

const InteractiveStallDashboard = ({ bookedStalls, onHoldStalls, salesmanTableData, totalStalls }) => {
    // Calculate statistics
    const bookingPercentage = (bookedStalls.length / totalStalls) * 100;
    const onHoldPercentage = (onHoldStalls.length / totalStalls) * 100;
    const emptyPercentage = 100 - bookingPercentage - onHoldPercentage ;


    // Prepare data for Doughnut chart
    const doughnutData = {
        labels: ['Booked', 'On Hold', "Empty"],
        datasets: [{
            data: [bookingPercentage, onHoldPercentage, emptyPercentage ],
            backgroundColor: ['#4e73df', '#f6c23e', '#828282'],
            hoverBackgroundColor: ['#2e59d9', '#f4b619',  '#636363'],
        }]
    };



    const doughnutOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed !== undefined) {
                            label += context.parsed.toFixed(2) + '%';
                        }
                        return label;
                    }
                }
            }
        }
    };

    // Prepare data for Bar chart (Top 5 Salesmen)
    const topSalesmen = salesmanTableData
        .sort((a, b) => b.bookedStallsQty - a.bookedStallsQty)
        .slice(0, 5);

    const barData = {
        labels: topSalesmen.map(s => s.name),
        datasets: [{
            label: 'Booked Stalls',
            data: topSalesmen.map(s => s.bookedStallsQty),
            backgroundColor: '#4e73df',
            barThickness: topSalesmen.length === 1 ? 20 : 'flex', // Make the bar thinner when there's only one
        }]
    };
    const maxValue = Math.max(...topSalesmen.map(s => s.bookedStallsQty));

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Stalls Booked'
                },
                ticks: {
                    stepSize: 1,
                    precision: 0,

                },
                min: 0,
                max: Math.max(maxValue, 4)

            },
            x: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: topSalesmen.length > 1 // Only show legend when there's more than one salesman
            }
        },
        layout: {
            padding: {
                left: 50,
                right: 50
            }
        }
    };

    return (
        <div>
            <Row className="h-100">
                <Col md="4" className="d-flex mb-4">
                    <Card className="shadow w-100">
                        <CardBody className="d-flex flex-column">
                            <h4 className="mb-4">Stall Status Distribution (%)</h4>
                            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                                <Doughnut data={doughnutData} options={doughnutOptions}/>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="8" className="d-flex mb-4">
                    <Card className="shadow w-100">
                        <CardBody className="d-flex flex-column">
                            <h4 className="mb-4">Top {topSalesmen.length === 1 ? '' : '5 '}Salesmen Performance</h4>
                            <div className="flex-grow-1">
                                <Bar
                                    data={barData}
                                    options={barOptions}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default InteractiveStallDashboard;