
import Lottie from "lottie-react";
import noData from "../lottiefiles/noData.json";

const NoData = ({ title }) => {
    return (
        <div style={{ height: '550px', width: '550px', margin: '0px auto' }}>
            <Lottie animationData={noData} loop={true} />
            {title && <h2 className="d-flex justify-content-center align-items-center" style={{ width: '250px', margin: '0px auto', textAlign: 'center' }}>{title}</h2>}
        </div>
    );
};

export default NoData;
