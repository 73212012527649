// reactstrap components
import { useEffect, useMemo, useState } from "react";
import { Card, Container, Row, Col, Button, Modal, CardBody } from "reactstrap";
import AdminHeader from "components/Headers/AdminHeader";
import {
  useDocumentList,
  useDocumentDownload,
  useDocumentApprove,
} from "shared/utility/services/hooks/stalls";
import {
  getEntriesByFileType,
  fileNames,
  downloadFileLocal,
} from "shared/utility/helpers";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CommentsSection } from "components/Comments";
import { useSelector } from "react-redux";
import { selectExhibition } from "../../../redux/reducers/authSlice";

const StallView = () => {
  const { stallId } = useParams();

  const [modal, setModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [activeModalStatus, setActiveModalStatus] = useState("comment");

  const selectedExhibition = useSelector(selectExhibition);
  useEffect(() => {
    if (stallId) {
      documentsList({ stall_id: stallId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stallId]);

  const modalToogle = (status = "comment") => {
    setModal((prev) => !prev);
    setActiveModalStatus(status);
  };

  const modalClose = () => {
    setModal((prev) => !prev);
  };

  // document download
  const {
    data: documentDownloadData,
    isLoading: documentDownloadLoading,
    isError: documentDownloadIsError,
    error: documentDownloadError,
    mutate: documentDownload,
  } = useDocumentDownload();

  useEffect(() => {
    try {
      if (documentDownloadData && !documentDownloadLoading) {
        const fileName = "example-file";
        downloadFileLocal(documentDownloadData, fileName);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentDownloadData,
    documentDownloadLoading,
    documentDownloadIsError,
    documentDownloadError,
  ]);

  // list documents
  const {
    data: documentsListData,
    isLoading: documentsListLoading,
    isError: documentsListIsError,
    error: documentsListError,
    mutate: documentsList,
  } = useDocumentList();

  useEffect(() => {
    try {
      if (documentsListData && !documentsListLoading) {
      }
      if (documentsListIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentsListData,
    documentsListLoading,
    documentsListIsError,
    documentsListError,
  ]);

  // approve documents
  const {
    data: approveDocumentData,
    isLoading: approveDocumentLoading,
    isError: approveDocumentIsError,
    error: approveDocumentError,
    mutate: approveDocument,
  } = useDocumentApprove();

  useEffect(() => {
    try {
      if (approveDocumentData && !approveDocumentLoading) {
        documentsList({ stall_id: stallId });
        toast.success("Document approved successfully");
      }
      if (approveDocumentData === null) {
        documentsList({ stall_id: stallId });
        toast.success("Document approved successfully");
      }
      if (approveDocumentIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approveDocumentData,
    approveDocumentLoading,
    approveDocumentIsError,
    approveDocumentError,
  ]);

  const approve = (document) => {
    approveDocument({
      documentId: document.id,
      exhibition_id: selectedExhibition.id,
    });
    documentsList({ stall_id: stallId });
  };

  const existingKycDocument = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.kyc_document,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const existingApprovalForm = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.approval_form,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const existingContractForm = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.contract_form,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const existingstallDesignDocument = useMemo(() => {
    if (documentsListData?.results?.length) {
      return getEntriesByFileType(
        documentsListData?.results,
        fileNames.stall_design_document,
      );
    } else {
      return null;
    }
  }, [documentsListData?.results]);

  const setDocument = (doc) => {
    setSelectedDocument(doc);
    setActiveModalStatus("comment");
    setModal(true);
  };

  let modalStatus = {
    comment: (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Comments
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={modalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <CommentsSection document={selectedDocument} showAddComment={true} />
        </Card>
      </div>
    ),
  };
  const isDocumentPresent = (documentsListData, docName) => {
    return documentsListData?.results?.find((doc) => doc.file_type === docName);
  };
  return (
    <>
      <AdminHeader />
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow border-0">
          <div className="modal-header">
            <h3 className="modal-title" id="modal-title-default">
              Verify Documents
            </h3>
          </div>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              {selectedExhibition &&
                selectedExhibition?.required_documents?.map((doc, index) => (
                  <Col lg="6">
                    <label
                      className="form-control-label"
                      htmlFor="input-status"
                    >
                      {doc}
                    </label>
                    {}
                    <div className="d-flex align-items-center">
                      {isDocumentPresent(documentsListData, doc) ? (
                        <button
                          type="button"
                          onClick={() => {
                            documentDownload(
                              isDocumentPresent(documentsListData, doc)?.id,
                            );
                          }}
                          className="btn btn-link"
                        >
                          Click here to download
                        </button>
                      ) : (
                        <p className="mb-0 mx-3">No file uploaded yet</p>
                      )}
                      {!isDocumentPresent(documentsListData, doc)
                        ?.is_approved && (
                        <Button
                          color="info"
                          onClick={() => {
                            approve(isDocumentPresent(documentsListData, doc));
                          }}
                          disabled={!isDocumentPresent(documentsListData, doc)}
                        >
                          Approve
                        </Button>
                      )}
                      {isDocumentPresent(documentsListData, doc)
                        ?.is_approved && (
                        <div className="bg-green text-white shadow mx-1 d-flex align-items-center p-2 rounded">
                          <i className="fa-solid fa-check pr-2"></i>
                          <p className="font-weight-bold mb-0">Verified</p>
                        </div>
                      )}
                      <Button
                        color="danger"
                        onClick={() =>
                          setDocument(isDocumentPresent(documentsListData, doc))
                        }
                        disabled={
                          !isDocumentPresent(documentsListData, doc) ||
                          isDocumentPresent(documentsListData, doc)?.is_approved
                        }
                      >
                        Reject
                      </Button>
                    </div>
                  </Col>
                ))}
            </Row>
          </CardBody>
        </Card>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={modal}
          onExit={() => {
            modalToogle();
          }}
        >
          {modalStatus[activeModalStatus]}
        </Modal>
      </Container>
    </>
  );
};

export default StallView;
