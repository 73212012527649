// Shared Routes
import Profile from "views/pages/Profile.js";
import MyLogin from "views/pages/Login.js";
import MyDashboard from "views/pages/Dashboard.js";
import Exhibition from "views/pages/Exhibition";
import Stalls from "views/pages/Stalls";
import Users from "views/pages/Users";
import Services from "views/pages/Services";
import Payments from "./views/pages/Payments";
import Notifications from "./views/pages/Notifications";
import VisitorList from "./views/pages/VisitorList";
//List Of Back Office Routes

import QRScanner from "./views/pages/QRScanner";
import BackOfficeStalls from "views/pages/backOffice/Stalls";
import BackOfficeStallView from "views/pages/backOffice/StallView";
import BouncerQRScanner from "views/pages/bouncer/QRScanner";

//List Of Exhibitor Routes
import ServicesExhibitor from "views/pages/exhibitor/Services";
import StallsExhibitor from "./views/pages/exhibitor/Stalls";
import Tickets from "./views/pages/Tickets";

import VisitorExhibition from "./views/pages/visitor/Exhibition";
import VisitorTickets from "./views/pages/visitor/Tickets";
import TicketsPurchased from "./views/pages/visitor/TicketsList";
import Register from "./views/pages/Register";
export const myPublicRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <MyLogin />,
    layout: "/public",
    showAtMenu: true,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: <Register />,
    layout: "/public",
    showAtMenu: true,
  },
];

export const adminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <MyDashboard />,
    layout: "/admin",
    showAtMenu: true,
  },
  {
    path: "/exhibition",
    name: "Exhibitions",
    icon: "fa-solid fa-star text-primary",
    component: <Exhibition />,
    layout: "/admin",
    showAtMenu: true,
  },
  {
    path: "/stalls",
    name: "Stalls",
    icon: "fa-solid fa-shop text-primary",
    component: <Stalls />,
    layout: "/admin",
    showAtMenu: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa-solid fa-users text-primary",
    component: <Users />,
    layout: "/admin",
    showAtMenu: true,
  },
  {
    path: "/services",
    name: "Services",
    icon: "fa-solid fa-wrench text-primary",
    component: <Services />,
    layout: "/admin",
    //TODO change when activating services
    showAtMenu: true,
  },

  {
    path: "/payments",
    name: "Payments",
    icon: "fa-solid fa-coins text-primary",
    component: <Payments />,
    layout: "/admin",
    //TODO change when activating services
    showAtMenu: true,
  },
  //
  // {
  //   path: "/visitors",
  //   name: "Visitors",
  //   icon: "fa-solid fa-users-rectangle text-primary",
  //   component: <VisitorList />,
  //   layout: "/admin",
  //   //TODO change when activating services
  //   showAtMenu: true,
  // },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "fa-solid fa-users-rectangle text-primary",
    component: <Tickets />,
    layout: "/admin",
    //TODO change when activating services
    showAtMenu: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "fa-solid fa-bell text-primary",
    component: <Notifications />,
    layout: "/admin",
    //TODO change when using notifications feature
    showAtMenu: false,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/scanner",
    name: "QR Scanner",
    icon: "fa fa-qrcode text-yellow",
    component: <QRScanner />,
    layout: "/admin",
    showAtMenu: true,
  },
];

export const salesRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <MyDashboard />,
  //   layout: "/sales",
  //   showAtMenu: true,
  // },
  {
    path: "/stalls",
    name: "View Stalls",
    icon: "fa-solid fa-shop text-primary",
    component: <Stalls />,
    layout: "/sales",
    showAtMenu: true,
  },
  {
    path: "/users",
    name: "View Users",
    icon: "fa-solid fa-users text-primary",
    component: <Users />,
    layout: "/sales",
    showAtMenu: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/sales",
  },
];

export const exhibitorRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <MyDashboard />,
  //   layout: "/exhibitor",
  // },
  {
    path: "/stalls",
    name: "My Stall",
    icon: "fa-solid fa-shop text-primary",
    component: <StallsExhibitor />,
    layout: "/exhibitor",
    showAtMenu: true,
  },
  {
    path: "/services",
    name: "Services",
    icon: "fa-solid fa-wrench text-primary",
    component: <ServicesExhibitor />,
    layout: "/exhibitor",
    //TODO change when activating services
    showAtMenu: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/exhibitor",
  },
];

export const backOfficeRoutes = [
  {
    path: "/dashboard",
    name: "View Stalls",
    icon: "fa-solid fa-shop text-primary",
    component: <BackOfficeStalls />,
    layout: "/back-office",
    showAtMenu: true,
  },
  {
    path: "/stall/:stallId",
    name: "View Stall",
    icon: "fa-solid fa-shop text-primary",
    component: <BackOfficeStallView />,
    layout: "/back-office",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/back-office",
  },
];
export const visitorRoutes = [
  {
    path: "/exhibitions",
    name: "Exhibitions",
    icon: "fa-solid fa-shop text-primary",
    component: <VisitorExhibition />,
    layout: "/visitor",
    showAtMenu: true,
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "fa-solid fa-ticket text-primary",
    component: <VisitorTickets />,
    layout: "/visitor",
    //TODO change when activating services
    showAtMenu: true,
  },
  {
    path: "/purchased_tickets",
    name: "Purchased Tickets",
    icon: "fa-solid fa-ticket text-primary",
    component: <TicketsPurchased />,
    layout: "/visitor",
    //TODO change when activating services
    showAtMenu: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/visitor",
  },
];
export const bouncerRoutes = [
  {
    path: "/QRScanner",
    name: "QR Scanner",
    icon: "fa-solid fa-shop text-primary",
    component: <BouncerQRScanner />,
    layout: "/bouncer",
    showAtMenu: true,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/bouncer",
  },
];
