import { useMutation } from "react-query";
import useAxios from "../useAxios";
import { usersRoutes } from "./route";
import { objectToQueryString } from "shared/utility/helpers";

const { users } = usersRoutes;

export const useCreateUsers = () => {
  const callApi = useAxios();
  const { url, method } = users.post;

  return useMutation((data) => {
    const newUrl = url + `?exhibition_id=${data.exhibition_id}`;
    return callApi({
      method: method,
      url: newUrl,
      data: data.data,
    }).then((data) => data);
  });
};

export const useEditUsers = () => {
  const callApi = useAxios();
  const { url, method } = users.put;

  return useMutation((data) => {
    return callApi({
      method: method,
      url: url + `/${data.id}`,
      data,
    }).then((data) => data);
  });
};

export const useUsersList = () => {
  const callApi = useAxios();
  const { url, method } = users.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}?${queryString}`;

    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useBusinessUsersList = () => {
  const callApi = useAxios();
  const { url, method } = users.businessUsers;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}?${queryString}`;

    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};
export const useUnassignedUsersList = () => {
  const callApi = useAxios();
  const { url, method } = users.unassignedUsers;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);
    let newUrl = `${url}?${queryString}`;

    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data);
  });
};

export const useAssignUsers = () => {
  const callApi = useAxios();
  const { url, method } = users.assignUsers;

  return useMutation((data) => {
    const queryString = objectToQueryString({
      exhibition_id: data.exhibition_id,
    });
    let newUrl = `${url}?${queryString}`;

    return callApi({
      method: method,
      url: newUrl,
      data: data.userIds,
    }).then((data) => data);
  });
};

export const useRevokeUsers = () => {
  const callApi = useAxios();
  const { url, method } = users.revokeUsers;

  return useMutation((data) => {
    const queryString = objectToQueryString({
      exhibition_id: data.exhibition_id,
    });
    let newUrl = `${url}?${queryString}`;

    return callApi({
      method: method,
      url: newUrl,
      data: data.userIds,
    }).then((data) => data);
  });
};
