import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useLogin,
  useGetUser,
  useResetPassword,
} from "shared/utility/services/hooks/signin";
import { Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "../../layouts/Admin";
import SalesLayout from "../../layouts/Sales";
import ExhibitorLayout from "../../layouts/Exhibitor";
import BackOfficeLayout from "../../layouts/BackOffice";
import { selectUser } from "../../redux/reducers/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [layout, setLayout] = useState("login");
  const user = useSelector(selectUser);
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (user?.role === "admin") {
      navigate("/admin/dashboard");
    }
    if (user?.role === "sales") {
      navigate("/sales/stalls");
    }
    if (user?.role === "exhibitor") {
      navigate("/exhibitor/stalls");
    }
    if (user?.role === "backoffice") {
      navigate("/back-office/dashboard");
    }
    if (user?.role === "visitor") {
      navigate("/visitor/");
    }
    if (user?.role === "bouncer") {
      navigate("/bouncer/QRScanner");
    }
    if (user === undefined || user === null) {
      console.log("NO USER");
    }
  }, [user]);
  const {
    data: usersMeData,
    isLoading: usersMeLoading,
    isError: usersMeIsError,
    error: usersMeError,
    mutate: usersMe,
  } = useGetUser();

  useEffect(() => {
    try {
      if (usersMeData && !usersMeLoading) {
        toast.success("Login successfully");
        if (usersMeData?.role === "admin") {
          navigate("/admin/dashboard");
        } else if (usersMeData?.role === "sales") {
          navigate("/sales/stalls");
        } else if (usersMeData?.role === "exhibitor") {
          navigate("/exhibitor/stalls");
        } else if (usersMeData?.role === "backoffice") {
          navigate("/back-office/dashboard");
        } else if (usersMeData?.role === "bouncer") {
          navigate("/bouncer/QRScanner");
        }
      }
      if (usersMeIsError) {
        toast.error(usersMeError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [usersMeData, usersMeLoading, usersMeIsError]);

  const {
    data: logInData,
    isLoading: logInLoading,
    isError: logInIsError,
    error: logInError,
    mutate: logIn,
  } = useLogin();

  const toReset = false;

  useEffect(() => {
    try {
      if (logInData && !logInLoading) {
        console.log(logInData, "logInData");
        if (logInData.initial_password) {
          setLayout("reset");
        } else {
          usersMe();
        }
      }
      if (logInIsError) {
        toast.error(logInError?.response?.data?.error);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logInData, logInLoading, logInIsError]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          if (key === "username") {
            formData.append(key, values[key].toLowerCase());
          } else {
            formData.append(key, values[key]);
          }
        }
      });
      setEmail(values.username.toLowerCase());
      logIn(formData);
    },
  });
  const {
    data: resetData,
    isLoading: resetLoading,
    isError: resetIsError,
    error: resetError,
    mutate: resetPassword,
  } = useResetPassword();

  useEffect(() => {
    try {
      if (resetData && !resetLoading) {
        toast.success("Login successfully");
        if (resetData?.role === "admin") {
          navigate("/admin/dashboard");
        } else if (resetData?.role === "sales") {
          navigate("/sales/stalls");
        } else if (resetData?.role === "exhibitor") {
          navigate("/exhibitor/stalls");
        } else if (resetData?.role === "backoffice") {
          navigate("/back-office/dashboard");
        } else if (resetData?.role === "bouncer") {
          navigate("/bouncer/QRScanner");
        }
      }

      if (resetIsError) {
        toast.error(usersMeError?.response?.data?.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetData, resetLoading, resetIsError]);

  const formikReset = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      resetPassword({ email: email, password: values.newPassword });
    },
  });

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          {layout === "login" && (
            <Form role="form" onSubmit={formik.handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email *"
                    type="email"
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.username && formik.errors.username}
                  />
                  <FormFeedback>{formik.errors.username}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password *"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.password && formik.errors.password}
                  />
                  <FormFeedback>{formik.errors.password}</FormFeedback>
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          )}
          {layout === "reset" && (
            <Form role="form" onSubmit={formikReset.handleSubmit}>
              <h5 className="text-center mb-2">You need to reset Password</h5>

              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password *"
                    type="password"
                    name="newPassword"
                    onChange={formikReset.handleChange}
                    onBlur={formikReset.handleBlur}
                    invalid={
                      formikReset.touched.newPassword &&
                      formikReset.errors.newPassword
                    }
                  />
                  <FormFeedback>{formikReset.errors.newPassword}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password *"
                    type="password"
                    name="confirmPassword"
                    onChange={formikReset.handleChange}
                    onBlur={formikReset.handleBlur}
                    invalid={
                      formikReset.touched.confirmPassword &&
                      formikReset.errors.confirmPassword
                    }
                  />
                  <FormFeedback>
                    {formikReset.errors.confirmPassword}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Reset Password
                </Button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
      {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
        </Row> */}
    </Col>
  );
};

export default Login;
